.subsection-list {
  display: flex;
  flex-wrap: wrap;  
  $spacing: 40px;
  &__item {
    display: block;
    border-bottom: solid 1px $color-gray-6;
    padding: 15px 40px 15px 20px;    
    position: relative;
    font-size: $font-size-larger;
    width: 100%;
    margin-right: 0; 
    &:nth-child(even) {
      margin-left: 0px;
      margin-right: 0px;
    }
    .accent-arrow {
      right: 0;
      top: 50%;
      transition: 0.3s width ease-in-out;
    }
    &:hover {
      color: $color-brand-green;
      .accent-arrow {
        width: 42px;
      }
    }
  }
  @include min-width-md {
    &__item {
      width: calc(50% - #{$spacing});
      margin-right: $spacing;
    }
    &:nth-child(even) {
      margin-left: 20px;
      margin-right: 0px;
    }
  }

}