.languageprompt {
  $spacing--lang: $spacing-med;
  position: fixed;
  z-index: 0;
  top: 40%;
  left: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  background-color: $color-gray-5;
  color: $color-brand-light;
  @include transition(top .6s ease-in, opacity .6s ease-in);

  &--animate,
  &--active {
    height: auto;
    width: 100%;
    overflow: visible;
    z-index: $z-overlay;
    @include translate(0, -50%);
  }

  &--animate {
    opacity: 0;
    top: 40%;
  }

  &--active {
    opacity: 1;
    top: 50%;
    @include box-shadow(0 0 6px rgba(0,0,0, 0.5));
  }

  &--hide {
      display: none; 
  }

  &__inner {
    text-align: center;
    padding-top: $spacing--lang;
    padding-bottom: $spacing--lang;
  }

  &__row {
    & + & {
      margin-top: $spacing--lang;
    }
  }
  &__description {
    font-size: $font-size-large;
    font-weight: $weight-light-standard;
    font-family: $font-family-regular;
    letter-spacing: .02em;
  }

  &__button {
    margin: 0 $spacing;
  }
}