.component {
    &--bordered {
        padding-top: $spacing-component;
        border-top: $border-default;
    }

    &--limited {
        @include min-width-md {
            margin-left: $spacing-large;
            margin-right: $spacing-large;
        }
    }

    &-title {
        font-size: $font-size-evenlarger;
        margin-bottom: $spacing-med;
    }

    &--accordion + .component--accordion {
        .accordion__bar {
            &:last-of-type{
                border-bottom: 1px solid $color-brand-forest-green;
            }         
        }
    }
}

.component:not(.component-inner) {
    + .component {
        margin-top: $spacing-component;
    }
}

.component--accordion.component--accordion {
    + .component--accordion {
        margin-top: $spacing-accordion-negative-margin;
    }
}