@mixin icontoggle($displaytype: inline-block, $activeclass: active){
    .icon,
    .title {
        display: $displaytype;
        &.open { display: $displaytype; }
        &.close { display: none; }
    }

    &.#{$activeclass} {
        .icon,
        .title {
            &.open { display: none; }
            &.close { display: $displaytype; }
        }
    }
}
