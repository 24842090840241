@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either top, right, bottom or left.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: 2;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
    border-bottom: $size solid $color;
  } @else if $direction == bottom {
    border-left: $perpendicular-borders;
    border-right: $perpendicular-borders;
    border-top: $size solid $color;
  } @else if $direction == right {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
    border-left: $size solid $color;
  } @else if $direction == left {
    border-bottom: $perpendicular-borders;
    border-top: $perpendicular-borders;
    border-right: $size solid $color;
  }

}



.triangle-up {
  @include triangle(top);
}

.triangle-down {
  @include triangle(bottom);
}

.triangle-right {
  @include triangle(right);
}

.triangle-left {
  @include triangle(left);
}
