.filtertoggles {
  $filterbordersize: 2px;
  margin-bottom: $spacing*3;
  @extend .clearfix;

  &--centered {
    text-align: center;
  }
  
  &-item {
    display: block;
    padding: $spacing 0;
    border-bottom: $filterbordersize solid $color-gray-9;
    color: $color-gray-9;
    font-family: $font-family-regular;
    font-weight: 400;
    text-align: left;
    width: 100%;
    cursor: pointer;

    &:nth-child(odd) {
      &:not(.filtertoggles-item--centered) {
        margin-left: 0;
      }
    }

    &:hover,
    &.active {
      color: $color-brand-primary;
      border-color: $color-brand-green;
    }
  }

  @include min-width-md {
    border-bottom: $border-default;
    margin-bottom: ($spacing-med) + $filterbordersize;

    &-item {
      display: inline-block;
      width: auto;
      padding: $spacing-small 0;
      margin: 0 $spacing-small (-($filterbordersize));
      border-bottom-color: transparent;

      &:nth-child(odd) {
        &:not(.filtertoggles-item--centered) {
          margin-left: $spacing-small;
        }
      }

      &:first-child {
        &:not(.filtertoggles-item--centered) {
          margin-left: 0;
        }
      }

      &:hover,
      &.active {
        color: $color-brand-primary;
        border-color: $color-brand-green;
      }
    }
  }
}
