// http://meyerweb.com/eric/tools/css/reset/
// v2.0 | 20110126
// License: none (public domain)


$use-reset: true !default;

@if $use-reset or $use-normalize==false {

  html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, del, dfn, img, q, s, samp, small, strike, strong, var, b, u, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    border-radius: 0;
  }

  article, aside, details, figcaption, figure, footer, header, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
    &:before, &:after {
      content: '';
      content: none;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button {
    border-radius: 0;
  }
} // if
