@mixin calcMinHeight($percent) {
    @include min-width-md {
        min-height: $screen-md-min * $percent;
    }

    @include min-width-lg {
        min-height: $screen-lg-min * $percent;
    }

    @include min-width-xl {
        min-height: $screen-xl-min * $percent;
    }

    @include min-width-xxl {
        min-height: $screen-xxl-min * $percent;
    }
}
