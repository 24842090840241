.card {
  overflow: hidden;
  position: relative;
  margin: 0;
  border: 1px solid $color-brand-primary;
  text-align: center;

  @include hoverReverse();

  &.dark {
    background-color: $color-brand-dark;
    color: $color-brand-light;
  }

  &--left {
    text-align: left;
  }

  &__link {
    display: block;
    color: inherit;
    height: 100%;

    &--img {
        color: $color-brand-light;
    }
  }

  .accent-decor {
    display: inline-block;
    position: relative;
    margin: 0 auto;
  }

  .accent-download {
    font-size: $font-size-h3;
    line-height: 1;
    color: $black;
    top: 0;
    left: 0;
    @include transition(transform .6s ease, color .3s ease);
  }

  .accent-arrow {
    border-color: $black;
    transition: border-color .3s ease, color .3s ease, width .3s ease;

    .icon {
      color: $black;
    }
  }

  &__link {
    &--img {
      .accent-download,
      .accent-arrow {
        color: $white;
      }

      .accent-arrow {
        border-color: $white;
        .icon {
          color: $white;
        }
      }
    }
  }

  &:hover {
    a {
      color: inherit;
    }

    .accent-arrow-solo {
      width: 20%;
    }

    .accent-download {
      @include translate(0, 7px);
      color: $white;
    }

    .accent-arrow {
      border-color: $white;
      
      .icon {
        color: $white;
      }
    }
  
    .card__image {
      transform: scale(1.15);
    }
  }

  &__list {
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    margin-top: -$spacing;

    .card {
        margin-top: $spacing;
    }

    @include min-width-sm {
      flex-flow: row wrap;
      justify-content: center;
      align-items: stretch;

      .card {
        flex: 1 1 calc(30% - #{$spacing * 3});
        margin-left: $spacing * 1.5;
        margin-right: $spacing * 1.5;

        width: calc(30% - #{$spacing * 3});
        max-width: 40%;
        min-height: 204px;

        &:nth-child(3n+1) {
          margin-left: 0;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:only-child {
            margin-left: 0;
            margin-right: 0;
        }
      }
    }
  }

  &__title {
    font-family: $font-family-semibold;
    font-size: $font-size-evenlarger;
    font-weight: $weight-light-standard;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding:($spacing*2) ($spacing*2);
    background-size:cover;
    background-position:50% 50%;

    &:before {
      content: '';
      flex-grow: 1;
    }
  }

   &__row {
    position: relative;
    @extend .clearfix;
    width: 100%;
    margin: $spacing 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-bg-overlay;
    width: 100%;
    height: 100%;
    @extend .background-full;
    @extend .blackout-image-overlay;
    @include transition(all .6s ease);
  }

}
