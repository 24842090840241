.share-block {
  
  overflow: hidden;
  position: absolute;
  left: auto;
  top: auto;
  z-index: $z-reset;
  text-align: center;

  &-list {
    overflow: hidden;
    max-height: 0;
    @include transition(max-height .6s ease);
  }

  &.active {
    .share-block-list {
      max-height: 400px;
    }
  }

  &-link {
    display: block;
    background-color: $color-gray-D;
    font-size: $font-size-small;
    font-family: $font-family-regular;
    font-weight: normal;
    text-decoration: none;

    padding: .5em 1.5em;
    border-top: 1px solid $color-gray-8;

    &:hover {
      color: darken($color-brand-primary, 20%);
      background-color: darken($color-gray-D, 20%);
      text-decoration: none;
    }

    &:first-child {
      border-top: 0;
    }
  }
}
