.header-link-blocks {
    position: relative;
    z-index: $z-reset;
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    text-align: center;

    @include box-shadow($shadow);

    .scrolling-up & {
        @include box-shadow(none);
    }

    .menu-active & {
        border: 1px solid $color-brand-light;
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    .link-block {
        min-width: 48px;
        height: 48px;
        border-right: 1px solid $color-brand-light;

        &:first-child {
            &:not(.link-block--breadcrumb) {
                .scrolling-up & {
                    border-left: 1px solid $color-brand-light;
                }
            }
        }

        &:last-child {
            border-right: none;
        }

        &--breadcrumb {
            display: none;
            border-left: none;
            width: 0;
            min-width: 0;
            padding: 0;
            overflow: hidden;

            &:hover {
                color: $color-brand-secondary-light;
            }

            .title {
                margin: 0;
                font-size: $font-size-small;
            }
        }

        @include min-width-md {
            width: 62px;
            height: 62px;

            &--breadcrumb {
                flex-flow: row nowrap;
                align-items: center;
                background: transparent;
                padding-left: $spacing * 1.5;
                padding-right: $spacing * 1.5;
                width: auto;

                .scrolling-up & {
                    display: flex;
                }

                .title {
                    font-size: $font-size-larger;
                }
            }
        }
    }
}
