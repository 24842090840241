@use "sass:math";

.tabs {
    position: relative;

    &-list {
        @extend .reset-list;
        overflow: hidden;
        position: absolute;
        z-index: $z-site-shell; //increasing z-index so that tabs display over footer
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        margin: 0 0 0 0;
        max-width: 100%;
        max-height: 0;
        width: 100%;
        top: auto;
        left: 0;
        @include transition(max-height .6s linear);

        &:last-child {
            &:not(:first-child) {
                border-top: 0;
            }
        }

        @include min-width-md {
            margin: 1px 0 0 1px;
            overflow: visible;
            flex-flow: row;
            flex-wrap: nowrap;
            position: static;
            max-height: none;
        }
    }

    &-list-item {
        position: relative;
        flex: 1 1 auto;
        width: 100%;
        margin: 0;
        padding: ($spacing*1.5) $spacing;
        text-align: center;
        border: 1px solid $color-brand-primary-dark;
        background: $color-brand-light;
        @extend .interactive;
        @include transition(background .3s ease, color .3s ease);
        line-height: 1.1;

        &:hover,
        &.active {
            opacity: 1;
            border-color: $color-brand-green;
            background-color: $color-brand-green;
            color: $color-brand-light;
            overflow: visible;
        }

        &:only-of-type {
            display: none;
        }

        @include min-width-md {
            width: 33.333%;
            margin: -2px 0 0 -1px;

            &.active {
                &:before {
                    display: inline-block;
                }
            }
        }
    }

    &-list-bottom {
        .tabs-list-item {
            &.active {
                &:before {
                    border-top-color: transparent;
                    @extend .triangle-up;
                    top: -24px;
                }
            }
        }
    }

    &-list-title {
        font-family: $font-family-semibold;
        font-weight: $weight-bold-standard;
    }

    &-sections {
        position: relative;
        max-height: 0;
    }

    &-section {
        position: relative;
        @include pseudoDisplayHide;

        .area:first-of-type:not(.area--spaced) {
            padding-top: 0;
        }

        .area-block:first-of-type:not(.area-spaced) {
            margin-top: 0;
        }

        .section:first-of-type {
            margin-top: 0;
        }

        .section:last-of-type {
            margin-bottom: 0;
        }

        &.active {
            @include pseudoDisplayShow;
            padding: $spacing-med 0;
        }

        &:only-of-type {
            padding-top: 0;

            &.active {
                padding-top: 0;
            }
        }

        @include min-width-md {
            &.active {
                padding: $spacing-large 0;
            }
        }
    }

    &-close {
        position: absolute;
        display: none;
        padding: 0;
        right: 1px;
        top: math.div($spacing, 2);
        z-index: 2;
        font-size: 2.2rem;
        background-color: rgba(255, 255, 255, .0);
        @include linkColor;

        > .icon-x {
            line-height: normal;
        }

        .tabs.active & {
            @include min-width-md {
                display: inline-block;
            }
        }
    }

    &-toptitle {
        position: relative;
        flex: 1;
        width: 100%;
        margin: 0;
        padding: ($spacing*1.5) $spacing*2;
        text-align: center;
        border: 1px solid $color-brand-primary-dark;
        background: $color-brand-light;
        @extend .interactive;
        @include transition(background .3s ease, color .3s ease);


        &:before {
            content: " ";
            display: block;
            position: absolute;
            right: $spacing*1.25;
            top: 45%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px 8px 0 8px;
            border-color: $color-brand-secondary-dark transparent transparent transparent;

            @include transition(transform .3s ease);
        }

        &--active {
            &:before {
                @include transform(translate(0, -50%) rotate(180deg));
            }
        }
        
        @include min-width-md {
            display: none;
        }
    }

    &.dropdownactive {
        position: relative;

        .tabs-list {
            max-height: 999px;
        }
    }

    &.active {

        .tabs-sections {
            max-height: none;
        }

        .tabs-list-item {
            background-color: $color-brand-light;
            border-color: $color-brand-dark;
            color: $color-brand-dark;

            &.active {
                border-color: $color-brand-green;
                background-color: $color-brand-green;
                color: $color-brand-light;
            }
        }
    }
}
