@use "sass:math";

.teaser {
  color: $color-brand-primary;

  &-title {
    margin-bottom: math.div($spacing, 2);
    font-size: $font-size-evenlarger;
  }
  &-details {
    margin-bottom: math.div($spacing, 2);
    font-size: $font-size-evenlarger;
    font-weight: $weight-light-standard;
  }
  &-item {
    padding-right: $spacing;
    padding-bottom: $spacing;
  }
  .columns-inline>.column.teaser__col {
    width: 100%;
    text-align: center;
  }
  @include min-width-lg {
    .columns-inline>.column.teaser__col {
      width: auto;
      text-align: left;
    }
    &__col--icon {
      padding-right: $spacing;
    }
  }
  @include min-width-lg {
    &-details {
      padding-right: $spacing * 4; 
    }
    .columns-inline>.column.teaser__col.teaser__col--buttons {
      text-align: right;
    }
  }
}
