.category-list {

  &-item {
    color: $color-gray-9;
    font-family: $font-family-regular;
    font-weight: 400;
    // font-weight: $weight-light-standard;

    &:after {
      content: "|";
      margin: 0 .5em 0 .5em;
    }

    &:last-child {
      &:after {
        content: "";
      }
    }
  }
}
