.button-alignment {
    &__left {
        text-align: left;
    }

    &__center {
        text-align: center;
    }

    &__right {
        text-align: right;
        margin-right: 12px;

        @include min-width-md {
            margin-right: 0;
        }
    }
}