﻿@use "sass:math";

.typeahead-list {
    $tylist-spacing-side: $spacing * 3;
    $tylist-spacing-side--mobile: $spacing * 2.5;
    $tylist-spacing-top: math.div($spacing, 2);

    position: absolute;
    background-color: $color-brand-light;
    z-index: 10;
    min-width: 100%;
    display: none;
    left: 0;
    right: 0;
    top: calc(100% - 6px);
    box-shadow: 0 6px 6px rgba(0,0,0, 0.2);
    padding-bottom: math.div($spacing, 2);
    max-height: 500%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    
    .customselect & {
        box-shadow: none;
        border-top: none;
        width: calc(100% - 2px);
    }

    .typeahead-list-inprogress,
    .typeahead-list-empty {
        font-family: $font-family-regular;
        color: $color-gray-A;
        text-align: left;
        padding: 0 $tylist-spacing-side--mobile math.div($spacing, 2);
    }

    .typeahead-list-item {
        cursor: pointer;
        display: block;
        outline: none;
        width: 100%;
        margin-top: math.div(-$spacing, 2);
        margin-bottom: math.div($spacing, 2);
        padding: $tylist-spacing-top $tylist-spacing-side--mobile;
        text-align: left;
        font-family: $font-family-regular;
        color: $color-gray-A;
        font-weight: $weight-normal-standard;

        .customselect & {
            margin-top: 0;
            padding: $tylist-spacing-top $spacing;
        }

        &:hover,
        &:focus {
            color: $color-brand-light;
            font-weight: $weight-normal-standard;
            background-color: $color-brand-secondary;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.active {
        display: block;
        border: .0625rem solid $color-brand-primary;
        border-top: $color-gray-A .1rem solid;
        box-shadow: none;
        transform: translate(-.25%);
        width: 100.5%;
    }

    &--header {
        padding-top: $spacing;

        .typeahead-list-item {
            padding-left: $spacing;
        }
    }

    @include min-width-md {
        .typeahead-list-inprogress,
        .typeahead-list-empty {
            padding: 0 $tylist-spacing-side math.div($spacing, 2);
        }

        .typeahead-list-item {
            margin-top: -$tylist-spacing-top;
            margin-bottom: $tylist-spacing-top;
            padding: $tylist-spacing-top $tylist-spacing-side;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &--header {
            .typeahead-list-item {
                padding-left: $spacing;
            }
        }
    }
}
