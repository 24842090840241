.readmore {
    &__content {
        overflow: hidden;
        @include transition(height .6s ease-out, margin-top .6s ease-out);
    }

    &__inner {
        margin-bottom: $spacing * 2;

        &--active {
            margin-top: 32px;
        }
    }

    &__limit {
        position: relative;
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    &__trigger {
        position: relative;
        padding: 0;
        @extend .interactive;
        @include linkColor($color-brand-primary);
        text-transform: uppercase;
        letter-spacing: .05em;
        outline: none;

        @include icontoggle(inline-block, readmore__trigger--active);
    }

    .btn {
        @include button-variant($btn-default-color, $btn-default-bg, $color-brand-chartreuse, $color-brand-secondary, $color-brand-dark);
        @include button-size($padding-button-base-vertical, $padding-button-base-horizontal, $font-size-button);
    }

    &--authors {
        .readmore__inner--active {
            margin-top: 0;
        }

        .authors-list {
            margin-top: 0;
        }
    }
}
