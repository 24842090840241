.narrative {
  &__text {
    font-size: $font-size-large;
    padding-right: $spacing-large;
  }

  &__button {
    text-align: left;
    padding-top: $spacing-med;
  }

  @include min-width-md {
    &__text {
    }
    &__button {
      padding-top: 0;
      text-align: right;
    }
  }
}