@mixin pseudoDisplayHide() {
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  height: 0;
  z-index: 0;
  padding: 0;
  margin: 0;
}

@mixin pseudoDisplayShow() {
  visibility: visible;
  opacity: 1;
  overflow: visible;
  height: auto;
  width: auto;
  z-index: 1;
}