.location_bar {
    display: block;
    border: 1px solid #003150;
    background-color: #00B140;
    line-height: 1.1em;
    font-size: 1.5rem;
    color: #ffffff;
    text-align: left;
    outline: none;
    width: 100%;
    flex: 1;
    position: relative;
    padding: 16px 32px 0px;
    font-family: Gill Sans W01,Gill Sans,sans-serif;
    font-weight: 100;
    margin-bottom: 16px;

    h3 {
        color: #ffffff;
        font-family: Gill Sans W01,Gill Sans,sans-serif;
        font-weight: 100;
        line-height: 1.1em;
        font-size: 1.5rem;
    }
}
