@charset "UTF-8";
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, del, dfn, img, q, s, samp, small, strike, strong, var, b, u, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  border-radius: 0;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  border-radius: 0;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  visibility: hidden;
  opacity: 0;
}

.clearfix:before, .card__row:before, .filtertoggles:before, .tile-inner .tile-row:before, .hero-flex-item-row:before, .clearfix:after, .card__row:after, .filtertoggles:after, .tile-inner .tile-row:after, .hero-flex-item-row:after {
  content: " ";
  display: table;
}
.clearfix:after, .card__row:after, .filtertoggles:after, .tile-inner .tile-row:after, .hero-flex-item-row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.printonly {
  display: none;
}

.pdfonly {
  display: none;
}

.experienceeditoronly {
  display: none;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

.background-full, .footer-decor, .card__image, .tile-image, .overlay-dark-bg {
  background-size: cover;
  background-position: 10% 15%;
  background-color: transparent;
  background-repeat: no-repeat;
}

.reset-list, .tabs-list, .tile-list {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.reset-link {
  text-decoration: none;
}
.reset-link:hover, .reset-link:visited, .reset-link:active, .reset-link:focus {
  text-decoration: none;
}

.link-block {
  display: block;
}

.vertical-spacing {
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (min-width: 992px) {
  .vertical-spacing-no-mobile {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
.vertical-spacing-top {
  padding-top: 16px;
}
@media (min-width: 992px) {
  .vertical-spacing-top-no-mobile {
    padding-top: 16px;
  }
}
.vertical-spacing-bottom {
  padding-bottom: 16px;
}
@media (min-width: 992px) {
  .vertical-spacing-bottom-no-mobile {
    padding-bottom: 16px;
  }
}
.vertical-spacing-bottom-mobile {
  padding-bottom: 16px;
}
@media (min-width: 1200px) {
  .vertical-spacing-bottom-mobile {
    padding-bottom: 0;
  }
}

#mti_wfs_colophon {
  display: none !important;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 16px;
  line-height: 1.428571429;
  color: #000;
  background-color: #FFF;
  font-feature-settings: "liga" 0;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  text-decoration: none;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
a:focus {
  text-decoration: none;
}
a:hover, a:active, a.active {
  color: #0D7D2E;
  text-decoration: none;
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

a:focus,
input:focus,
button:focus,
select:focus,
textarea:focus,
div.search-field:focus {
  outline: thin solid #929292;
}

.figure {
  margin: 0;
}

.p {
  margin-bottom: 16px;
  margin-top: 0;
}
.p:last-child {
  margin-bottom: 0;
}

.ul,
.ol {
  margin-bottom: 16px;
}
.ul > .li,
.ol > .li {
  margin-bottom: 16px;
}
.ul > .li:last-child,
.ol > .li:last-child {
  margin-bottom: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border: 0;
  border-top: 1px solid gray;
}

[role=button] {
  cursor: pointer;
}

button {
  border: none;
  background: none;
}

.blockquote {
  display: block;
  position: relative;
  padding: 16px 48px;
  margin-bottom: 48px;
  color: #000;
  font-size: 1.125rem;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  line-height: 1.855em;
  text-align: left;
  letter-spacing: 0;
  background-color: #FFF;
  border-bottom: 0;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 32px;
}
.container:before, .container:after {
  content: " ";
  display: table;
}
.container:after {
  clear: both;
}
@media (min-width: 768px) {
  .container {
    width: 784px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 1004px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1204px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 32px;
}
.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}
.container-fluid:after {
  clear: both;
}

.row, form[data-sc-fxb] {
  margin-left: -32px;
  margin-right: -32px;
}
.row:before, form[data-sc-fxb]:before, .row:after, form[data-sc-fxb]:after {
  content: " ";
  display: table;
}
.row:after, form[data-sc-fxb]:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 32px;
  padding-right: 32px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.3333333333%;
}

.col-xs-2 {
  width: 16.6666666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333333333%;
}

.col-xs-5 {
  width: 41.6666666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333333333%;
}

.col-xs-8 {
  width: 66.6666666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333333333%;
}

.col-xs-11 {
  width: 91.6666666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.3333333333%;
}

.col-xs-pull-2 {
  right: 16.6666666667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333333333%;
}

.col-xs-pull-5 {
  right: 41.6666666667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333333333%;
}

.col-xs-pull-8 {
  right: 66.6666666667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333333333%;
}

.col-xs-pull-11 {
  right: 91.6666666667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.3333333333%;
}

.col-xs-push-2 {
  left: 16.6666666667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333333333%;
}

.col-xs-push-5 {
  left: 41.6666666667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333333333%;
}

.col-xs-push-8 {
  left: 66.6666666667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333333333%;
}

.col-xs-push-11 {
  left: 91.6666666667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.3333333333%;
  }
  .col-sm-push-2 {
    left: 16.6666666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.3333333333%;
  }
  .col-sm-push-5 {
    left: 41.6666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.3333333333%;
  }
  .col-sm-push-8 {
    left: 66.6666666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.3333333333%;
  }
  .col-sm-push-11 {
    left: 91.6666666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.3333333333%;
  }
  .col-md-pull-2 {
    right: 16.6666666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.3333333333%;
  }
  .col-md-pull-5 {
    right: 41.6666666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.3333333333%;
  }
  .col-md-pull-8 {
    right: 66.6666666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.3333333333%;
  }
  .col-md-pull-11 {
    right: 91.6666666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.3333333333%;
  }
  .col-md-push-2 {
    left: 16.6666666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.3333333333%;
  }
  .col-md-push-5 {
    left: 41.6666666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.3333333333%;
  }
  .col-md-push-8 {
    left: 66.6666666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.3333333333%;
  }
  .col-md-push-11 {
    left: 91.6666666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.3333333333%;
  }
  .col-lg-push-2 {
    left: 16.6666666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.3333333333%;
  }
  .col-lg-push-5 {
    left: 41.6666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.3333333333%;
  }
  .col-lg-push-8 {
    left: 66.6666666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.3333333333%;
  }
  .col-lg-push-11 {
    left: 91.6666666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}
.page-limit {
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 32px;
}
.page-limit:before, .page-limit:after {
  content: " ";
  display: table;
}
.page-limit:after {
  clear: both;
}
@media (min-width: 768px) {
  .page-limit {
    max-width: 784px;
  }
}
@media (min-width: 992px) {
  .page-limit {
    max-width: 1004px;
  }
}
@media (min-width: 1200px) {
  .page-limit {
    max-width: 1204px;
  }
}
@media (min-width: 1366px) {
  .page-limit {
    max-width: 1370px;
  }
}
@media (min-width: 1920px) {
  .page-limit {
    max-width: 1924px;
  }
}
.page-limit-narrow {
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 32px;
}
.page-limit-narrow:before, .page-limit-narrow:after {
  content: " ";
  display: table;
}
.page-limit-narrow:after {
  clear: both;
}
@media (min-width: 768px) {
  .page-limit-narrow {
    max-width: 784px;
  }
}
@media (min-width: 992px) {
  .page-limit-narrow {
    max-width: 804px;
  }
}
@media (min-width: 1200px) {
  .page-limit-narrow {
    max-width: 964px;
  }
}
@media (min-width: 1366px) {
  .page-limit-narrow {
    max-width: 1096px;
  }
}
@media (min-width: 1920px) {
  .page-limit-narrow {
    max-width: 1540px;
  }
}
.page--menuactive {
  overflow: hidden !important;
}

.inner-limit {
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 992px) {
  .inner-limit {
    max-width: 90%;
  }
}

@media (min-width: 992px) {
  .reduced-inner-limit {
    padding-left: 64px;
    padding-right: 64px;
  }
}

.body-wrapper:before, .body-wrapper:after {
  content: " ";
  display: table;
}
.body-wrapper:after {
  clear: both;
}

.header,
.footer,
.main-content {
  position: relative;
}
.header .inner,
.footer .inner,
.main-content .inner {
  position: relative;
  z-index: 2;
}
.header .inner:before, .header .inner:after,
.footer .inner:before,
.footer .inner:after,
.main-content .inner:before,
.main-content .inner:after {
  content: " ";
  display: table;
}
.header .inner:after,
.footer .inner:after,
.main-content .inner:after {
  clear: both;
}

.header {
  z-index: 98;
}

.footer {
  z-index: 95;
}

.main-content {
  position: static;
  z-index: 96;
}
.main-content .inner {
  position: static;
}

.ga-article-category {
  display: none;
}

.column {
  flex: 0 1 auto;
  width: 100%;
}
.column.column-vertical-centered {
  align-self: center;
}

*[class^=columns-] {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.columns-2--centered {
  justify-content: center;
}

@media (min-width: 992px) {
  .columns-2 > .column {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .columns-2 > .column--wide {
    width: 100%;
  }
}

@media (min-width: 480px) {
  .columns-2-sm-2 > .column {
    width: 50%;
  }
}

.columns-3 > .column {
  flex: 1 1 auto;
}
@media (min-width: 992px) {
  .columns-3 > .column {
    width: 33.33%;
  }
}

.columns-inline {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}
.columns-inline-break {
  flex-wrap: wrap;
}
@media (min-width: 1200px) {
  .columns-inline-break {
    flex-wrap: nowrap;
  }
}
.columns-inline > .column {
  width: auto;
  flex: 1 1 auto;
}
.columns-inline > .column.column-static {
  flex: 0 0 auto;
}
.columns-inline > .column.column-5 {
  width: 5%;
}
.columns-inline > .column.column-10 {
  width: 10%;
}
.columns-inline > .column.column-50 {
  width: 50%;
}

.columns-footer {
  flex-flow: column nowrap;
}
.columns-footer > .column {
  flex: 0 1 auto;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.columns-footer > .column:last-child {
  width: 100%;
}
.columns-footer .columns-all {
  flex-wrap: wrap;
}
@media (min-width: 480px) {
  .columns-footer {
    flex-flow: row wrap;
  }
  .columns-footer > .column {
    flex: 0 1 auto;
    width: 50%;
  }
  .columns-footer > .column:last-child {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .columns-footer {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .columns-footer > .column {
    width: 15%;
    flex: 0 1 auto;
    margin-left: 0;
    margin-right: 2%;
  }
  .columns-footer > .column:last-child {
    width: auto;
    min-width: 65%;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .columns-2-30-70 > .column {
    width: 30%;
  }
  .columns-2-30-70 > .column:last-child {
    width: 70%;
  }
}

@media (min-width: 992px) {
  .columns-2-70-30 > .column {
    width: 35%;
  }
  .columns-2-70-30 > .column:first-child {
    width: 65%;
  }
}

@media (min-width: 768px) {
  .columns-all {
    flex-wrap: nowrap;
  }
}
.columns-all > .column {
  flex: 1 50%;
  width: 50%;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .columns-all > .column {
    flex: 1 100%;
    width: 100%;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("/assets/Main/site/fonts/icomoon/fonts/icomoon.eot");
  src: url("/assets/Main/site/fonts/icomoon/fonts/icomoon.eot?#iefix") format("embedded-opentype"), url("/assets/Main/site/fonts/icomoon/fonts/icomoon.woff") format("woff"), url("/assets/Main/site/fonts/icomoon/fonts/icomoon.ttf") format("truetype");
  font-weight: "normal";
  font-style: "normal";
}
[class^=icon-]:before, [class^=icon-]:after,
[class*=" icon-"]:before,
[class*=" icon-"]:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-icon {
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 0.75em;
}

.icon-icon-see-all:before {
  content: "\e916";
}

.icon-clipboard:before {
  content: "\e915";
}

.icon-mail:before {
  content: "\e913";
}

.icon-calendar:before {
  content: "\e90c";
}

.icon-date:before {
  content: "\e90c";
}

.icon-schedule:before {
  content: "\e90c";
}

.icon-clock-thin:before {
  content: "\e912";
  color: #979797;
}

.icon-scroll:before {
  content: "\e90b";
}

.icon-group:before {
  content: "\e906";
}

.icon-users:before {
  content: "\e906";
}

.icon-arrow-up:before {
  content: "\e900";
}

.icon-arrow-right:before {
  content: "\e901";
}

.icon-arrow-left:before {
  content: "\e902";
}

.icon-arrow-down:before {
  content: "\e903";
}

.icon-external-link:before {
  content: "\e914";
}

.icon-download:before {
  content: "\e911";
}

.icon-minus:before {
  content: "\e90f";
}

.icon-clock:before {
  content: "\e90d";
}

.icon-plus:before {
  content: "\e904";
}

.icon-x:before {
  content: "\e910";
}

.icon-search:before {
  content: "\e905";
}

.icon-feed:before {
  content: "\e90e";
}

.icon-linkedin:before {
  content: "\e907";
}

.icon-youtube:before {
  content: "\e908";
}

.icon-twitter:before {
  content: "\e909";
}

.icon-facebook:before {
  content: "\e90a";
}

.icon-spinner11:before {
  content: "\e984";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-x1:before {
  content: "\e909";
}

.page-background {
  background-image: url(/-/media/images/background/sitebgtop.png), url(/-/media/images/background/sitebgbottom.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 0, top 1200px right;
}
.page-background--push-down {
  background-position: 0 64px, top 1200px right;
}

.bg-color-primary {
  background-color: #000;
}

.bg-article {
  background-color: #D1CCBD;
}
.bg-article:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

.bg-gradient {
  background: linear-gradient(217.9deg, #00B140 0%, #000000 98.92%);
}
.bg-gradient .hero-flex-content,
.bg-gradient .hero-flex-content--short {
  position: relative;
  z-index: 1;
}
.bg-gradient:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  background: url("/assets/Main/site/images/hero-swoosh.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay-dark-bg {
  position: absolute;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay-dark-bg:before {
  position: absolute;
  display: block;
  width: 100%;
  height: 75%;
  content: " ";
  left: 0;
  bottom: 0;
  opacity: 1;
  z-index: 0;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, black 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0, black 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, black 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00000000", endColorstr="#FF000000", GradientType=0);
}

.blackout-image-overlay:before, .card__image:before, .tile-image:before, .link-column-image:before, .blackout-image-overlay-light:before, .hero .hero-image:not(.nooverlay):before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  content: " ";
  background-color: #000;
  opacity: 0.5;
}

.blackout-image-overlay-light:before, .hero .hero-image:not(.nooverlay):before {
  opacity: 0.3;
}

.body-overlay {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 0;
  width: 0;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.body-overlay.active {
  z-index: 96;
  height: 100%;
  width: 100%;
  opacity: 1;
}

.triangle-up, .tabs-list-bottom .tabs-list-item.active:before {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-bottom: 1em solid currentcolor;
}

.triangle-down {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: 1em solid currentcolor;
}

.triangle-right {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-bottom: 1em solid transparent;
  border-top: 1em solid transparent;
  border-left: 1em solid currentcolor;
}

.triangle-left {
  width: 0;
  height: 0;
  content: "";
  z-index: 2;
  border-bottom: 1em solid transparent;
  border-top: 1em solid transparent;
  border-right: 1em solid currentcolor;
}

.link-reset, .hero .navlist-text {
  text-decoration: none;
}

.interactive, .readmore__trigger, .accordion__bar:not(.accordion__bar--no-hover), .accordion__close, .tabs-toptitle, .tabs-list-item, .link-block, a, button {
  cursor: pointer;
}
.interactive:focus, .readmore__trigger:focus, .accordion__bar:focus:not(.accordion__bar--no-hover), .accordion__close:focus, .tabs-toptitle:focus, .tabs-list-item:focus, .link-block:focus, a:focus, button:focus {
  outline: thin solid #929292;
}

.focus-style:focus {
  outline: thin solid #929292;
}

.title-style, .search__share, .stacktiles__title, .accordion__icon .title, .tile-inner .tile-row__title, .link-column-title, .hero .title-text, .hero .navlist-text-title, .header-menu-nav .navlist-item, .header-menu-nav.external .nav-item, .navlist-title, .link-block, .btn,
.header-style,
.search__resultstitle .title,
.stacklist-item-title,
.accordion__title,
.callout__body-col .tile .date,
.callout__body-col .tile .title,
.richtext h1,
.richtext h2,
.richtext h3,
.richtext h4,
.richtext h5,
.richtext h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 400;
  line-height: 1.1;
}

.title-style, .search__share, .stacktiles__title, .accordion__icon .title, .tile-inner .tile-row__title, .link-column-title, .hero .title-text, .hero .navlist-text-title, .header-menu-nav .navlist-item, .header-menu-nav.external .nav-item, .navlist-title, .link-block, .btn {
  text-transform: uppercase;
}

.subtitle-style, .section-title .subtitle, .hero .location-text, .hero .subtitle-text, .hero .navlist-text-subtitle {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-style: italic;
}

.h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
  font-weight: normal;
  line-height: 1;
}

.h1,
.h2,
.h3 {
  margin-top: 0;
  margin-bottom: 14.6666666667px;
}
.h1 .small,
.h2 .small,
.h3 .small {
  font-size: 65%;
}

.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 14.6666666667px;
}
.h4 .small,
.h5 .small,
.h6 .small {
  font-size: 75%;
}

.h1 {
  font-size: 2.8125rem;
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.25rem;
}

.h4 {
  font-size: 1.125rem;
}

.h5 {
  font-size: 1rem;
}

.h6 {
  font-size: 16px;
}

.lead {
  margin-bottom: 14.6666666667px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 24px;
  }
}

.small {
  font-size: 0.75rem;
}

.mark {
  padding: 0.2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center, .hero-nav {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.serif {
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
}

.sans-serif {
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
}

.richtext {
  color: #000;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  line-height: 1.875rem;
  font-size: 1.125rem;
}
.richtext h1, .richtext h2, .richtext h3, .richtext h4, .richtext h5, .richtext h6 {
  line-height: 1.1;
  font-weight: 300;
  color: #000;
  margin-bottom: 16px;
}
.richtext h1 {
  font-size: 2.8125rem;
}
.richtext h2 {
  font-size: 2rem;
}
.richtext h3 {
  font-size: 1.25rem;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 600;
}
.richtext h4 {
  font-size: 1.125rem;
  color: #000;
}
.richtext h5 {
  font-size: 1rem;
  font-weight: 700;
}
.richtext h6 {
  font-size: 16px;
}
.richtext p,
.richtext ul,
.richtext ol,
.richtext blockquote,
.richtext figure {
  margin-bottom: 32px;
}
.richtext p:last-child,
.richtext ul:last-child,
.richtext ol:last-child,
.richtext blockquote:last-child,
.richtext figure:last-child {
  margin-bottom: 0;
}
.richtext ul {
  list-style: disc;
  margin-left: 2em;
}
.richtext ol {
  margin-left: 2em;
}
.richtext small {
  font-size: 0.75rem;
}
.richtext mark {
  padding: 0.2em;
}
.richtext li {
  padding-left: 0.5em;
  margin: 0.5em 0;
}
.richtext strong, .richtext b {
  font-weight: 700;
}
.richtext em, .richtext i {
  font-style: italic;
}
.richtext u {
  text-decoration: underline;
}
.richtext a {
  color: #0067a0;
  text-decoration: none;
  -webkit-transition: color 0.35s ease, background 0.35s ease, border-color 0.35s ease, opacity 0.35s ease;
  -o-transition: color 0.35s ease, background 0.35s ease, border-color 0.35s ease, opacity 0.35s ease;
  transition: color 0.35s ease, background 0.35s ease, border-color 0.35s ease, opacity 0.35s ease;
}
.richtext a:focus {
  text-decoration: none;
}
.richtext a:hover, .richtext a:active, .richtext a.active {
  color: #0098ed;
  text-decoration: none;
}
.richtext a:hover, .richtext a:visited, .richtext a:active, .richtext a:focus {
  text-decoration: none;
}
.richtext a.link--blue {
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.richtext a.link--blue:focus {
  text-decoration: none;
}
.richtext a.link--blue:hover, .richtext a.link--blue:active, .richtext a.link--blue.active {
  color: #004163;
  text-decoration: none;
}
.richtext a.link--orange {
  color: #C7442E;
  text-decoration: none;
  -webkit-transition: color 0.35s ease, background 0.35s ease, border-color 0.35s ease, opacity 0.35s ease;
  -o-transition: color 0.35s ease, background 0.35s ease, border-color 0.35s ease, opacity 0.35s ease;
  transition: color 0.35s ease, background 0.35s ease, border-color 0.35s ease, opacity 0.35s ease;
}
.richtext a.link--orange:focus {
  text-decoration: none;
}
.richtext a.link--orange:hover, .richtext a.link--orange:active, .richtext a.link--orange.active {
  color: #C37C00;
  text-decoration: none;
}
.richtext a.btn {
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  padding: 0.8em 4em;
  font-size: 16px;
  line-height: 1.4;
  border-radius: 0;
}
.richtext a.btn, .richtext a.btn:visited {
  background-color: transparent;
  border-color: #000;
}
.richtext a.btn:focus {
  text-decoration: none;
}
.richtext a.btn:hover, .richtext a.btn:active, .richtext a.btn.active {
  color: transparent;
  text-decoration: none;
}
.richtext a.btn, .richtext a.btn:visited, .richtext a.btn:focus, .richtext a.btn.focus {
  background-color: transparent;
  border-color: #000;
}
.richtext a.btn:hover, .richtext a.btn:active, .richtext a.btn.active {
  background-color: false;
  border-color: false;
}
.richtext a.btn:hover .accent-arrow, .richtext a.btn:active .accent-arrow, .richtext a.btn.active .accent-arrow {
  border-color: false;
}
.richtext a.btn.disabled, .richtext a.btn[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.richtext a.btn.disabled:hover, .richtext a.btn.disabled:focus, .richtext a.btn.disabled.focus, .richtext a.btn[disabled]:hover, .richtext a.btn[disabled]:focus, .richtext a.btn[disabled].focus, fieldset[disabled] .richtext a.btn:hover, fieldset[disabled] .richtext a.btn:focus, fieldset[disabled] .richtext a.btn.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.richtext a.btn .badge {
  color: transparent;
  background-color: #000;
}
.richtext blockquote {
  display: block;
  position: relative;
  padding: 16px 48px;
  margin-bottom: 48px;
  color: #000;
  font-size: 1.125rem;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  line-height: 1.855em;
  text-align: left;
  letter-spacing: 0;
  background-color: #FFF;
  border-bottom: 0;
}
.richtext .blockquote {
  border-top: 0.0625rem solid #929292;
  font-size: 1.5rem;
  letter-spacing: 0.03em;
  line-height: 2.25em;
  text-align: center;
  margin-bottom: 48px;
  padding: 32px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.richtext .blockquote > .blockquote-text {
  padding: 0 24px;
}
.richtext .blockquote > .attribution-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.richtext .blockquote > .attribution-container::before, .richtext .blockquote > .attribution-container::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: #929292;
}
.richtext .blockquote > .attribution-container .attribution {
  padding: 8px;
  background-color: #FFF;
  font-style: normal;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  color: #929292;
  text-align: center;
  text-shadow: none;
  font-weight: 300;
  max-width: 50%;
}
.richtext .no-attribution {
  border-bottom: 0.0625rem solid #929292;
}
.richtext q {
  quotes: '"' '"' "'" "'";
}
.richtext q::before {
  content: open-quote;
}
.richtext q::after {
  content: close-quote;
}
.richtext figure {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.richtext figure img {
  margin-left: auto;
  margin-right: auto;
  display: inherit;
  width: 100%;
}
.richtext dl {
  display: block;
  width: 100%;
}
.richtext dl > div {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 16px;
}
.richtext dl dt {
  flex: 0 0 auto;
  white-space: normal;
  width: 60%;
  padding: 0;
  margin-right: 5%;
}
.richtext dl dd {
  flex: 1 1 auto;
  padding: 0;
  white-space: normal;
}
.richtext iframe,
.richtext img {
  max-width: 100%;
}
.richtext img {
  height: auto;
}

.btn {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: normal;
  text-align: center;
  letter-spacing: 1px;
  box-sizing: border-box;
  max-width: calc(100vw - 64px);
  overflow: visible;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  padding: 0.8em 2em;
  font-size: 16px;
  line-height: 1.4;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn, .btn:visited {
  background-color: transparent;
  border-color: #97D700;
}
.btn:focus {
  text-decoration: none;
}
.btn:hover, .btn:active, .btn.active {
  color: #000;
  text-decoration: none;
}
.btn, .btn:visited, .btn:focus, .btn.focus {
  background-color: transparent;
  border-color: #97D700;
}
.btn:hover, .btn:active, .btn.active {
  background-color: #00B140;
  border-color: #00B140;
}
.btn:hover .accent-arrow, .btn:active .accent-arrow, .btn.active .accent-arrow {
  border-color: #000;
}
.btn.disabled, .btn[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn.disabled:hover, .btn.disabled:focus, .btn.disabled.focus, .btn[disabled]:hover, .btn[disabled]:focus, .btn[disabled].focus, fieldset[disabled] .btn:hover, fieldset[disabled] .btn:focus, fieldset[disabled] .btn.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn .badge {
  color: transparent;
  background-color: #000;
}
@media (min-width: 480px) {
  .btn {
    padding: 0.8em 4em;
    font-size: 16px;
    line-height: 1.4;
    border-radius: 0;
  }
}
.btn:hover, .btn:focus, .btn.focus {
  text-decoration: none;
}
.btn:active, .btn.active {
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: disabled;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (min-width: 992px) {
  .btn {
    white-space: nowrap;
  }
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none;
}

a.btn--no-link {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-primary, .btn-primary:visited {
  background-color: #000;
  border-color: black;
}
.btn-primary:focus {
  text-decoration: none;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.active {
  color: #000;
  text-decoration: none;
}
.btn-primary, .btn-primary:visited, .btn-primary:focus, .btn-primary.focus {
  background-color: #000;
  border-color: black;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.active {
  background-color: #fff;
  border-color: #fff;
}
.btn-primary:hover .accent-arrow, .btn-primary:active .accent-arrow, .btn-primary.active .accent-arrow {
  border-color: false;
}
.btn-primary.disabled, .btn-primary[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-primary .badge {
  color: #000;
  background-color: #fff;
}

.btn-secondary {
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-secondary, .btn-secondary:visited {
  background-color: #FFF;
  border-color: #00B140;
}
.btn-secondary:focus {
  text-decoration: none;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary.active {
  color: #FFF;
  text-decoration: none;
}
.btn-secondary, .btn-secondary:visited, .btn-secondary:focus, .btn-secondary.focus {
  background-color: #FFF;
  border-color: #00B140;
}
.btn-secondary:hover, .btn-secondary:active, .btn-secondary.active {
  background-color: #000;
  border-color: #000;
}
.btn-secondary:hover .accent-arrow, .btn-secondary:active .accent-arrow, .btn-secondary.active .accent-arrow {
  border-color: false;
}
.btn-secondary.disabled, .btn-secondary[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled].focus, fieldset[disabled] .btn-secondary:hover, fieldset[disabled] .btn-secondary:focus, fieldset[disabled] .btn-secondary.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-secondary .badge {
  color: #FFF;
  background-color: #000;
}

.btn-secondary--dark {
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-secondary--dark, .btn-secondary--dark:visited {
  background-color: #545454;
  border-color: #00B140;
}
.btn-secondary--dark:focus {
  text-decoration: none;
}
.btn-secondary--dark:hover, .btn-secondary--dark:active, .btn-secondary--dark.active {
  color: #545454;
  text-decoration: none;
}
.btn-secondary--dark, .btn-secondary--dark:visited, .btn-secondary--dark:focus, .btn-secondary--dark.focus {
  background-color: #545454;
  border-color: #00B140;
}
.btn-secondary--dark:hover, .btn-secondary--dark:active, .btn-secondary--dark.active {
  background-color: #FFF;
  border-color: #FFF;
}
.btn-secondary--dark:hover .accent-arrow, .btn-secondary--dark:active .accent-arrow, .btn-secondary--dark.active .accent-arrow {
  border-color: false;
}
.btn-secondary--dark.disabled, .btn-secondary--dark[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-secondary--dark.disabled:hover, .btn-secondary--dark.disabled:focus, .btn-secondary--dark.disabled.focus, .btn-secondary--dark[disabled]:hover, .btn-secondary--dark[disabled]:focus, .btn-secondary--dark[disabled].focus, fieldset[disabled] .btn-secondary--dark:hover, fieldset[disabled] .btn-secondary--dark:focus, fieldset[disabled] .btn-secondary--dark.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-secondary--dark .badge {
  color: #545454;
  background-color: #FFF;
}

.btn-light {
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-light, .btn-light:visited {
  background-color: rgba(0, 0, 0, 0);
  border-color: #FFF;
}
.btn-light:focus {
  text-decoration: none;
}
.btn-light:hover, .btn-light:active, .btn-light.active {
  color: #000;
  text-decoration: none;
}
.btn-light, .btn-light:visited, .btn-light:focus, .btn-light.focus {
  background-color: rgba(0, 0, 0, 0);
  border-color: #FFF;
}
.btn-light:hover, .btn-light:active, .btn-light.active {
  background-color: #FFF;
  border-color: #FFF;
}
.btn-light:hover .accent-arrow, .btn-light:active .accent-arrow, .btn-light.active .accent-arrow {
  border-color: #000;
}
.btn-light.disabled, .btn-light[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-light.disabled:hover, .btn-light.disabled:focus, .btn-light.disabled.focus, .btn-light[disabled]:hover, .btn-light[disabled]:focus, .btn-light[disabled].focus, fieldset[disabled] .btn-light:hover, fieldset[disabled] .btn-light:focus, fieldset[disabled] .btn-light.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-light .badge {
  color: rgba(0, 0, 0, 0);
  background-color: #FFF;
}

.btn-dark {
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-dark, .btn-dark:visited {
  background-color: #000;
  border-color: #000;
}
.btn-dark:focus {
  text-decoration: none;
}
.btn-dark:hover, .btn-dark:active, .btn-dark.active {
  color: #000;
  text-decoration: none;
}
.btn-dark, .btn-dark:visited, .btn-dark:focus, .btn-dark.focus {
  background-color: #000;
  border-color: #000;
}
.btn-dark:hover, .btn-dark:active, .btn-dark.active {
  background-color: #00B140;
  border-color: #00B140;
}
.btn-dark:hover .accent-arrow, .btn-dark:active .accent-arrow, .btn-dark.active .accent-arrow {
  border-color: #000;
}
.btn-dark.disabled, .btn-dark[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-dark.disabled:hover, .btn-dark.disabled:focus, .btn-dark.disabled.focus, .btn-dark[disabled]:hover, .btn-dark[disabled]:focus, .btn-dark[disabled].focus, fieldset[disabled] .btn-dark:hover, fieldset[disabled] .btn-dark:focus, fieldset[disabled] .btn-dark.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-dark .badge {
  color: #000;
  background-color: #FFF;
}

.btn-success {
  color: #fff;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-success, .btn-success:visited {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:focus {
  text-decoration: none;
}
.btn-success:hover, .btn-success:active, .btn-success.active {
  color: #5cb85c;
  text-decoration: none;
}
.btn-success, .btn-success:visited, .btn-success:focus, .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:hover, .btn-success:active, .btn-success.active {
  background-color: false;
  border-color: false;
}
.btn-success:hover .accent-arrow, .btn-success:active .accent-arrow, .btn-success.active .accent-arrow {
  border-color: false;
}
.btn-success.disabled, .btn-success[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-warning, .btn-warning:visited {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:focus {
  text-decoration: none;
}
.btn-warning:hover, .btn-warning:active, .btn-warning.active {
  color: #f0ad4e;
  text-decoration: none;
}
.btn-warning, .btn-warning:visited, .btn-warning:focus, .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:hover, .btn-warning:active, .btn-warning.active {
  background-color: false;
  border-color: false;
}
.btn-warning:hover .accent-arrow, .btn-warning:active .accent-arrow, .btn-warning.active .accent-arrow {
  border-color: false;
}
.btn-warning.disabled, .btn-warning[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.btn-danger, .btn-danger:visited {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:focus {
  text-decoration: none;
}
.btn-danger:hover, .btn-danger:active, .btn-danger.active {
  color: #d9534f;
  text-decoration: none;
}
.btn-danger, .btn-danger:visited, .btn-danger:focus, .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:hover, .btn-danger:active, .btn-danger.active {
  background-color: false;
  border-color: false;
}
.btn-danger:hover .accent-arrow, .btn-danger:active .accent-arrow, .btn-danger.active .accent-arrow {
  border-color: false;
}
.btn-danger.disabled, .btn-danger[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-lg {
  padding: 0.8em 4em;
  font-size: 1.25rem;
  line-height: 1.4;
  border-radius: 0;
}

.btn-sm {
  padding: 0.8em 4em;
  font-size: 0.875rem;
  line-height: 1.4;
  border-radius: 0;
}

.btn-xs {
  padding: 0.8em 4em;
  font-size: 0.6875rem;
  line-height: 1.4;
  border-radius: 0;
}

.btn-link {
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

.btn-block {
  display: block;
  width: 100%;
  max-width: 100%;
}

.btn-thick {
  border-width: 2px;
}

.btn-lower {
  text-transform: none;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.btn > .accent-arrow {
  position: absolute;
  right: 32px;
  top: 50%;
  border-color: #000;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.btn > .accent-arrow .icon {
  color: inherit;
  font-size: 28px;
  line-height: 15px;
}
@media (min-width: 480px) {
  .btn > .accent-arrow {
    right: 64px;
  }
}
.btn > .icon-mail {
  font-size: 1.75rem;
  position: relative;
  top: 0.19em;
  height: 43px;
  margin-right: 5px;
}
.btn > .icon-clipboard {
  font-size: 1.75rem;
  position: relative;
  top: 0.1em;
}

.btn-arrow {
  padding-right: 72px;
}
@media (min-width: 480px) {
  .btn-arrow {
    padding-right: 112px;
  }
}

.btn-spaced {
  letter-spacing: 4px;
}

.btn-mail,
.btn-clipboard {
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .btn-mail,
.btn-clipboard {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 480px) {
  .btn-mail,
.btn-clipboard {
    font-size: 0.8125rem;
    padding: 20px 0;
  }
}

a.rt {
  color: #000;
}

@media (min-width: 767.98px) {
  .btn-column .btn-margin {
    margin-left: 1rem;
  }
}
@media (max-width: 767.98px) {
  .btn-column {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }
  .btn-column .btn-margin {
    margin-top: 1rem;
  }
}

.custominput {
  position: relative;
  min-height: 48px;
  background: #FFF;
  border: 1px solid #000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.custominput:before {
  width: 30px;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  background: #FFF;
  content: "";
  pointer-events: none;
}
.custominput:hover:before {
  background: #FFF;
}
.custominput input {
  border: none;
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  height: 100%;
  line-height: normal;
  width: 100%;
  outline: none;
  padding-right: 35px;
  padding-left: 15px;
  border: none;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.custominput input:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.custominput input::-ms-expand {
  display: none;
}
.custominput input:focus {
  outline: thin solid #929292;
}

.customselect {
  position: relative;
  min-height: 48px;
  background: #FFF;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.customselect option {
  font-family: inherit;
}
.customselect[disabled] {
  opacity: 0.5;
}
.customselect:after {
  content: "";
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  pointer-events: none;
}
.customselect:before {
  width: 30px;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  background: #FFF;
  content: "";
  pointer-events: none;
}
.customselect:hover:before {
  background: #FFF;
}
.customselect select {
  box-shadow: none;
  border-radius: 0;
  background: transparent;
  height: 100%;
  line-height: normal;
  width: 100%;
  outline: none;
  padding-right: 35px;
  padding-left: 15px;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.customselect select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
.customselect select::-ms-expand { /* for IE 11 */
  display: none;
}
.customselect select::-ms-expand {
  display: none;
}
.customselect select:focus {
  outline: thin solid #929292;
}

@media all and (min-width: 0\0 ) and (min-resolution: 0.001dpcm) {
  .select select {
    padding-right: 0;
  }
  .select:after, .select:before {
    display: none;
  }
}
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 22px;
  font-size: 24px;
  line-height: inherit;
  border: 0;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 4px;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: normal;
  color: #000;
}
label[disabled], fieldset[disabled] label {
  opacity: 0.5;
}

input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type=radio],
input[type=checkbox] {
  margin: 4px 0 0;
  margin-top: 1px \9 ;
  line-height: normal;
}

input[type=file] {
  display: block;
}

input[type=range] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

output {
  display: block;
  font-size: 16px;
  line-height: 1.428571429;
}

.form-control {
  display: block;
  width: 100%;
  min-height: 48px;
  padding: 0.8em 0.8em;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 16px;
  line-height: normal;
  color: #000;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #000;
  border-radius: 0;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #000;
}
.form-control::-webkit-input-placeholder {
  color: #000;
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #F8F8F8;
  opacity: 0.5;
}
.form-control[disabled], fieldset[disabled] .form-control {
  cursor: cursor-disabled;
}

textarea.form-control {
  height: auto;
}

input[type=search] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
    line-height: 48px;
  }
  input[type=date].input-sm, .input-group-sm input[type=date],
input[type=time].input-sm,
.input-group-sm input[type=time],
input[type=datetime-local].input-sm,
.input-group-sm input[type=datetime-local],
input[type=month].input-sm,
.input-group-sm input[type=month] {
    line-height: 38px;
  }
  input[type=date].input-lg, .input-group-lg input[type=date],
input[type=time].input-lg,
.input-group-lg input[type=time],
input[type=datetime-local].input-lg,
.input-group-lg input[type=datetime-local],
input[type=month].input-lg,
.input-group-lg input[type=month] {
    line-height: 58px;
  }
}
.form-group {
  margin-bottom: 16px;
}
.form-group.mobileshowhide__desktop {
  display: none;
}
@media (min-width: 1200px) {
  .form-group.mobileshowhide__desktop {
    display: block;
  }
}
.form-group.mobileshowhide__mobile {
  display: block;
}
@media (min-width: 1200px) {
  .form-group.mobileshowhide__mobile {
    display: none;
  }
}
.form-group[disabled], fieldset[disabled] .form-group {
  cursor: cursor-disabled;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio label,
.checkbox label {
  min-height: 22px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type=radio],
.radio-inline input[type=radio],
.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9 ;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type=radio][disabled], input[type=radio].disabled, fieldset[disabled] input[type=radio],
input[type=checkbox][disabled],
input[type=checkbox].disabled,
fieldset[disabled] input[type=checkbox] {
  cursor: cursor-disabled;
}

.radio-inline.disabled, fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
  cursor: cursor-disabled;
}

.radio.disabled label, fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
  cursor: cursor-disabled;
}

.form-control-static {
  padding-top: 1.8em;
  padding-bottom: 1.8em;
  margin-bottom: 0;
  min-height: 38px;
}
.form-control-static.input-lg, .form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}

.input-sm {
  height: 38px;
  padding: 8px 8px;
  font-size: 0.875rem;
  line-height: normal;
  border-radius: 0;
}

select.input-sm {
  height: 38px;
  line-height: 38px;
}

textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 38px;
  padding: 8px 8px;
  font-size: 0.875rem;
  line-height: normal;
}
.form-group-sm select.form-control {
  height: 38px;
  line-height: normal;
}
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 38px;
  padding: 9px 8px;
  font-size: 0.875rem;
  line-height: normal;
}

.input-lg {
  height: 58px;
  padding: 32px 32px;
  font-size: 1.125rem;
  line-height: 1.6;
  border-radius: 0;
}

select.input-lg {
  height: 58px;
  line-height: 58px;
}

textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 58px;
  padding: 32px 32px;
  font-size: 1.125rem;
  line-height: normal;
}
.form-group-lg select.form-control {
  height: 58px;
  line-height: normal;
}
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 58px;
  padding: 33px 32px;
  font-size: 1.125rem;
  line-height: 1.6;
}

.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 60px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 58px;
  height: 58px;
  line-height: 58px;
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 38px;
  height: 38px;
  line-height: 38px;
}

.has-feedback label ~ .form-control-feedback {
  top: 27px;
}
.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
.form-inline .input-group .input-group-btn,
.form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
.form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
.form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type=radio],
.form-inline .checkbox input[type=checkbox] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 1.8em;
}
.form-horizontal .form-group {
  margin-left: -32px;
  margin-right: -32px;
}
.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}
.form-horizontal .form-group:after {
  clear: both;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 1.8em;
  }
}
.form-horizontal .has-feedback .form-control-feedback {
  right: 32px;
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 33px;
    font-size: 1.125rem;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 9px;
    font-size: 0.875rem;
  }
}

form[data-sc-fxb] {
  padding: 1.5rem;
}
@media (min-width: 768px) {
  form[data-sc-fxb] {
    padding: 0.5rem;
  }
}
form[data-sc-fxb] .required-field .control-label:before {
  color: #d00;
  content: "*";
  font-weight: bolder;
}
form[data-sc-fxb] small {
  display: block;
  font-size: 0.625rem;
  margin-left: 2rem;
  font-weight: 100;
  color: #545454;
  margin-bottom: 1rem;
}
form[data-sc-fxb] .form-submit-border {
  clear: both;
  margin: 2rem 1rem;
}
form[data-sc-fxb] .form-introduction, form[data-sc-fxb] .form-footer {
  clear: both;
}
form[data-sc-fxb] .form-introduction::after, form[data-sc-fxb] .form-introduction::before, form[data-sc-fxb] .form-footer::after, form[data-sc-fxb] .form-footer::before {
  clear: both;
  content: " ";
  display: block;
}
form[data-sc-fxb] .form-introduction, form[data-sc-fxb] .form-footer {
  padding: 0 1rem 2rem;
}
form[data-sc-fxb] .form-footer {
  margin-top: 1rem;
}
form[data-sc-fxb] .form-submit-border .btn {
  font-size: 0.875rem;
  padding: 0.875rem 3rem;
}
form[data-sc-fxb] .field-validation-error,
form[data-sc-fxb] .list-group-item-danger {
  color: #d9534f;
}
form[data-sc-fxb] .field-validation-error {
  position: absolute;
}
form[data-sc-fxb] .validation-summary-errors {
  color: #d9534f;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
form[data-sc-fxb] .has-error {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  form[data-sc-fxb] .has-error {
    float: left;
    width: 100%;
  }
}
form[data-sc-fxb] .form-group {
  position: relative;
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px;
  clear: none;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  form[data-sc-fxb] .form-group {
    float: left;
    width: 50%;
  }
}
form[data-sc-fxb] .form-group.scfRichText {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 1.375rem;
}
@media (min-width: 768px) {
  form[data-sc-fxb] .form-group.scfRichText {
    float: left;
    width: 100%;
  }
}
form[data-sc-fxb] .form-group.scfRichText textarea {
  background-color: #F8F8F8;
  border: 1px solid #848484;
  min-height: 200px;
}
form[data-sc-fxb] .form-group.scfRichText .control-label {
  -webkit-transform: translate(0);
  -ms-transform: translate(0);
  -o-transform: translate(0);
  transform: translate(0);
  margin-bottom: 1rem;
}
form[data-sc-fxb] .form-introduction,
form[data-sc-fxb] .form-footer,
form[data-sc-fxb] .control-label {
  color: #545454;
}
form[data-sc-fxb] .form-introduction,
form[data-sc-fxb] .form-footer {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 768px) {
  form[data-sc-fxb] .form-introduction,
form[data-sc-fxb] .form-footer {
    float: left;
    width: 100%;
  }
}
form[data-sc-fxb] .control-label {
  text-transform: uppercase;
}
form[data-sc-fxb] .form-control {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid hsla(0deg, 0%, 57%, 0.2);
  box-shadow: none;
  padding: 1rem 0.5rem 1rem;
  background-color: #F8F8F8;
  border: 1px solid #848484;
}
@media (min-width: 768px) {
  form[data-sc-fxb] {
    padding: 1.5rem;
  }
}

.dategraphic {
  display: inline-block;
  border: 2px solid #929292;
  color: #929292;
  border-radius: 6px;
  width: 56px;
  font-size: 0.6875rem;
  text-transform: uppercase;
  line-height: 0.9rem;
}
.dategraphic__header {
  display: block;
  border-bottom: 2px solid #929292;
  padding: 2px 8px;
  overflow: hidden;
}
.dategraphic__header:before, .dategraphic__header:after {
  content: "";
  display: inline-block;
  border: 2px solid #929292;
  border-radius: 100%;
  width: 6px;
  height: 6px;
}
.dategraphic__header:before {
  float: left;
}
.dategraphic__header:after {
  float: right;
}
.dategraphic__details {
  display: block;
  padding: 4px;
}

.icon {
  display: inline-block;
}
.icon.icon-menu {
  min-height: 0;
}
.icon.icon-menu, .icon.icon-menu:before, .icon.icon-menu:after {
  display: block;
  position: absolute;
  left: 0;
  width: 20px;
  height: 2px;
  min-height: 0;
  margin: 0 auto;
  content: "";
  background-color: inherit;
  -webkit-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
.icon.icon-menu {
  position: relative;
  margin: 9px auto;
  background-color: #000;
}
.icon.icon-menu:before {
  top: -6px;
}
.icon.icon-menu:after {
  top: 6px;
}
@media (min-width: 992px) {
  .icon.icon-menu, .icon.icon-menu:before, .icon.icon-menu:after {
    display: block;
    position: absolute;
    left: 0;
    width: 27px;
    height: 3px;
    min-height: 0;
    margin: 0 auto;
    content: "";
    background-color: inherit;
    -webkit-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
  }
  .icon.icon-menu {
    position: relative;
    margin: 14px auto;
    background-color: #000;
  }
  .icon.icon-menu:before {
    top: -9px;
  }
  .icon.icon-menu:after {
    top: 9px;
  }
}
.icon.icon-xclose, .icon.icon-xclose:before, .icon.icon-xclose:after {
  display: block;
  position: absolute;
  left: 0;
  width: 20px;
  height: 2px;
  min-height: 0;
  margin: 0 auto;
  content: "";
  background-color: inherit;
}
.icon.icon-xclose {
  position: relative;
  background-color: transparent;
  width: 0;
  margin: 9px 0;
  top: -1px;
  left: 0;
  background-color: #000;
}
.icon.icon-xclose:before, .icon.icon-xclose:after {
  left: 14px;
  -webkit-transform-origin: left;
  -moz-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
}
.icon.icon-xclose:before {
  top: -7px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.icon.icon-xclose:after {
  top: 8px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.icon.icon-circle {
  border: 1px solid currentColor;
  border-radius: 100%;
  padding: 0.25em;
  width: 1.8em;
  height: 1.8em;
  text-align: center;
  vertical-align: middle;
}

.accent-arrow {
  display: block;
  position: absolute;
  width: 32px;
  border-bottom: 2px solid #FFF;
  -webkit-transition: border-color 0.5s ease;
  -o-transition: border-color 0.5s ease;
  transition: border-color 0.5s ease;
}
.accent-arrow .icon {
  position: absolute;
  top: -13px;
  left: calc(100% - 0.55em);
  color: #FFF;
  font-size: 28px;
  line-height: 15px;
  -webkit-transition: opacity 0.05s linear;
  -o-transition: opacity 0.05s linear;
  transition: opacity 0.05s linear;
}

.insight-byline {
  border-bottom: 1px solid #215732;
}
.insight-byline .byline-label {
  font-weight: bold;
}
.insight-byline .byline-link {
  color: #0D7D2E;
}
.insight-byline .byline-link:hover {
  color: #00B140;
}

.link-block {
  display: inline-block;
  padding: 8px;
  vertical-align: bottom;
  text-transform: uppercase;
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  border-left: 1px solid #272727;
}
.link-block, .link-block:visited {
  background-color: #000;
  border-color: #000;
}
.link-block:focus {
  text-decoration: none;
}
.link-block:hover, .link-block:active, .link-block.active {
  color: #FFF;
  text-decoration: none;
}
.link-block, .link-block:visited, .link-block:focus, .link-block.focus {
  background-color: #000;
  border-color: #000;
}
.link-block:hover, .link-block:active, .link-block.active {
  background-color: #000;
  border-color: #000;
}
.link-block:hover .accent-arrow, .link-block:active .accent-arrow, .link-block.active .accent-arrow {
  border-color: #FFF;
}
.link-block.disabled, .link-block[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.link-block.disabled:hover, .link-block.disabled:focus, .link-block.disabled.focus, .link-block[disabled]:hover, .link-block[disabled]:focus, .link-block[disabled].focus, fieldset[disabled] .link-block:hover, fieldset[disabled] .link-block:focus, fieldset[disabled] .link-block.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.link-block .badge {
  color: #000;
  background-color: #FFF;
}
.link-block:hover {
  background-color: #333333;
}
.link-block > .icon {
  font-size: 0.9rem;
  line-height: 1.4rem;
  min-height: 0.15rem;
  text-align: center;
}
@media (min-width: 992px) {
  .link-block > .icon {
    font-size: 1.75rem;
    line-height: 1.96rem;
    min-height: 0.25rem;
  }
}
.link-block > .icon.icon-menu {
  margin-bottom: 12px;
  background-color: #FFF;
}
@media (min-width: 992px) {
  .link-block > .icon.icon-menu {
    margin-bottom: 18px;
  }
}
.link-block > .icon.icon-x {
  font-size: 1.4rem;
  line-height: 1rem;
  margin-top: 0;
}
@media (min-width: 992px) {
  .link-block > .icon.icon-x {
    font-size: 1.75rem;
    line-height: 1.875rem;
    margin-top: 0;
  }
}
.link-block > .icon-scroll {
  font-size: 2.25rem;
  margin-bottom: 0.15em;
}
.link-block .title {
  margin-top: 0.25em;
  font-size: 0.5rem;
  letter-spacing: 0.15em;
}
@media (min-width: 992px) {
  .link-block .title {
    font-size: 0.5625rem;
  }
}
.link-block .icon,
.link-block .title {
  color: #FFF;
}
.link-block .icon,
.link-block .title {
  display: block;
}
.link-block .icon.open,
.link-block .title.open {
  display: block;
}
.link-block .icon.close,
.link-block .title.close {
  display: none;
}
.link-block.active .icon.open,
.link-block.active .title.open {
  display: none;
}
.link-block.active .icon.close,
.link-block.active .title.close {
  display: block;
}
.link-block-menu {
  position: relative;
  margin: 0;
}
.link-block-menu .icon {
  min-height: 0;
}
.link-block-menu.active .title {
  margin-top: 0.25rem;
}
.link-block-scroll {
  line-height: 0.8;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.hero-section .link-block-scroll {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}
@media (min-width: 992px) {
  .hero-section .link-block-scroll {
    display: block;
  }
}
.link-block-more {
  min-width: 240px;
  padding: 16px;
  border: none;
}
.link-block-more .title {
  margin: 0;
  padding-right: 72px;
  display: inline-block;
  font-size: 0.875rem;
}
@media screen and (max-width: 768px) {
  .link-block-more .title {
    font-size: 0.75rem;
  }
}
.link-block-more .accent-arrow {
  position: absolute;
  right: 32px;
  top: 50%;
  border-color: #FFF;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.link-block-more .accent-arrow .icon {
  font-size: 28px;
  line-height: 15px;
}
.link-block-more .icon-icon-see-all {
  display: inline-block;
  font-size: 0.875rem;
  margin: 0 16px;
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .link-block-more .icon-icon-see-all {
    font-size: 0.75rem;
  }
}
.link-block-more .icon-icon-see-all + .title {
  padding-right: 0;
}
.hero-section .link-block-more {
  position: relative;
  margin-top: 32px;
}
@media screen and (max-width: 768px) {
  .hero-section .link-block-more {
    margin-top: 0;
  }
}
.hero-section .link-block-more--fixed {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  text-align: right;
}
@media screen and (max-width: 768px) {
  .hero-section .link-block-more--fixed {
    width: 49.3% !important;
    min-width: 0;
    font-size: 0.75rem;
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .hero-section .link-block-more--fixed {
    right: -2.25rem;
  }
}
.hero-section .link-block-more--fixed.left {
  position: absolute;
  bottom: 0;
  left: 0;
  right: inherit;
  z-index: 3;
  text-align: left;
}
@media screen and (max-width: 768px) {
  .hero-section .link-block-more--fixed.left {
    position: relative;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    margin-top: 0;
  }
}

.link-skip {
  position: absolute;
  height: 0;
  overflow: hidden;
  z-index: 0;
}

.navlist.inline .navlist-item {
  display: inline-block;
  margin-left: 0.25em;
  margin-bottom: 0;
}
.navlist.inline .navlist-item:first-child {
  margin-left: auto;
}
.navlist__button {
  padding: 0;
  color: #FFF;
}
.navlist__button.active, .navlist__button:hover {
  color: #959798;
  font-weight: 600;
}
.navlist-fillspace .navlist .navlist-item, .navlist.navlist-fillspace .navlist-item {
  display: block;
  width: 100%;
  vertical-align: text-top;
}
.navlist-fillspace .navlist .navlist-item:nth-child(odd), .navlist.navlist-fillspace .navlist-item:nth-child(odd) {
  margin-left: auto;
}
@media (min-width: 992px) {
  .navlist-fillspace .navlist .navlist-item, .navlist.navlist-fillspace .navlist-item {
    display: block;
    width: auto;
    margin-left: auto;
  }
}

.navlist-title {
  margin-bottom: 32px;
  font-weight: 400;
}
.social-legal .navlist-title {
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 16px;
}

.navlist-text {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
}
.navlist-text:hover {
  color: #0D7D2E;
}
.navlist-text:hover .navlist-text-title {
  color: #00B140;
}

.brand {
  width: 98px;
}
@media (min-width: 992px) {
  .brand {
    width: auto;
  }
}
.brand .brand-title {
  position: absolute;
  left: -100%;
  top: -100%;
  visibility: hidden;
  height: 0;
  width: 0;
  overflow: hidden;
}
.brand .brand-logo {
  display: block;
  transform: translate3d(0, 0, 0);
}
.scrolling-up .brand .brand-logo, .brand-secondary .brand .brand-logo {
  display: none;
}
.brand .brand-logo-secondary, .brand-secondary .brand .brand-logo {
  display: none;
}
.scrolling-up .brand .brand-logo-secondary, .scrolling-up .brand-secondary .brand .brand-logo {
  display: none;
}
.brand.brand-secondary .brand-logo {
  display: none;
}
.scrolling-up .brand.brand-secondary .brand-logo {
  display: block;
}
.brand.brand-secondary .brand-logo-secondary {
  display: block;
}
.scrolling-up .brand.brand-secondary .brand-logo-secondary {
  display: none;
}
.scrolling-up .brand {
  width: 100px;
  margin-top: 1.6px;
}
@media (min-width: 992px) {
  .scrolling-up .brand {
    margin-top: -8px;
  }
}
.scrolling-up .brand .brand-logo {
  display: block;
}
.scrolling-up .brand .brand-logo-secondary {
  display: none;
}

.header-link-blocks {
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  text-align: center;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.scrolling-up .header-link-blocks {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.menu-active .header-link-blocks {
  border: 1px solid #FFF;
}
.header-link-blocks a {
  text-decoration: none;
}
.header-link-blocks a:hover {
  text-decoration: none;
}
.header-link-blocks .link-block {
  min-width: 48px;
  height: 48px;
  border-right: 1px solid #FFF;
}
.scrolling-up .header-link-blocks .link-block:first-child:not(.link-block--breadcrumb) {
  border-left: 1px solid #FFF;
}
.header-link-blocks .link-block:last-child {
  border-right: none;
}
.header-link-blocks .link-block--breadcrumb {
  display: none;
  border-left: none;
  width: 0;
  min-width: 0;
  padding: 0;
  overflow: hidden;
}
.header-link-blocks .link-block--breadcrumb:hover {
  color: #00fe5c;
}
.header-link-blocks .link-block--breadcrumb .title {
  margin: 0;
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .header-link-blocks .link-block {
    width: 62px;
    height: 62px;
  }
  .header-link-blocks .link-block--breadcrumb {
    flex-flow: row nowrap;
    align-items: center;
    background: transparent;
    padding-left: 24px;
    padding-right: 24px;
    width: auto;
  }
  .scrolling-up .header-link-blocks .link-block--breadcrumb {
    display: flex;
  }
  .header-link-blocks .link-block--breadcrumb .title {
    font-size: 1.25rem;
  }
}

.header-menu {
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  transform: translate(100%, 0);
  padding: 20px 32px 48px;
  -webkit-transition: transform 0.4s ease;
  -o-transition: transform 0.4s ease;
  transition: transform 0.4s ease;
}
@media (min-width: 768px) {
  .header-menu {
    padding: 48px 64px 48px;
  }
}
.header-menu.active {
  width: 100%;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  z-index: 0;
}
@media (min-width: 768px) {
  .header-menu.active {
    width: 60%;
  }
}
@media (min-width: 992px) {
  .header-menu.active {
    width: 50%;
    width: 50vw;
  }
}

.header-menu-nav.external {
  margin-bottom: 64px;
}
@media (max-width: 480px) {
  .header-menu-nav.external {
    max-width: calc(90% - (48px * 3));
  }
}
@media (min-width: 768px) {
  .header-menu-nav.external {
    margin-bottom: 96px;
    font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .header-menu-nav.external {
    margin-bottom: 128px;
    font-size: 16px;
  }
}
.header-menu-nav.external:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  content: "\e914";
  margin-left: 0.25rem;
  color: #FFF;
}
.header-menu-nav.external .nav-item {
  letter-spacing: 0.3em;
  font-weight: 300;
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.header-menu-nav.external .nav-item:focus {
  text-decoration: none;
}
.header-menu-nav.external .nav-item:hover, .header-menu-nav.external .nav-item:active, .header-menu-nav.external .nav-item.active {
  color: #FFF;
  text-decoration: none;
}
.header-menu-nav .navlist-item {
  letter-spacing: 0.3em;
  font-size: 1.125rem;
  margin-top: 48px;
}
@media (min-width: 768px) {
  .header-menu-nav .navlist-item {
    font-size: 1.125rem;
    margin-top: 48px;
  }
}
@media (min-width: 992px) {
  .header-menu-nav .navlist-item {
    font-size: 1.875rem;
    margin-top: 48px;
  }
}
.header-menu-nav .navlist-text {
  position: relative;
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.header-menu-nav .navlist-text:focus {
  text-decoration: none;
}
.header-menu-nav .navlist-text:hover, .header-menu-nav .navlist-text:active, .header-menu-nav .navlist-text.active {
  color: #FFF;
  text-decoration: none;
}
.header-menu-nav .navlist-text:after {
  position: absolute;
  top: 100%;
  margin-top: 0.25em;
  content: " ";
  display: block;
  width: 100%;
  height: 3px;
  background-color: transparent;
  -webkit-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}
.header-menu-nav .navlist-text:hover:after {
  background-color: #00B140;
}
.header-menu-nav.secondary .navlist-text-title {
  font-weight: 300;
}

.callout--footer {
  position: relative;
  top: -96px;
  margin-bottom: -48px;
}
.callout__column {
  flex: 1 1 100%;
  height: auto;
}
.callout__column:first-child {
  margin: 0 auto;
  z-index: 1;
}
@media (min-width: 992px) {
  .callout__column:first-child {
    flex: 1 1 auto;
    width: 55%;
    margin: 0 auto;
    margin-right: -5%;
    align-self: flex-start;
  }
}
.callout__column:last-child {
  margin-top: 0;
  z-index: 2;
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) {
  .callout__column:last-child {
    flex: 1 1 auto;
    width: 55%;
    margin-left: -5%;
    margin-top: 192px;
    align-self: flex-end;
  }
}
@media (min-width: 992px) {
  .callout__column--narrow-offset:first-child {
    width: 60%;
    flex: 1 1 60%;
    margin-right: -10%;
  }
}
@media (min-width: 992px) {
  .callout__column--narrow-offset:last-child {
    width: 50%;
    flex: 1 1 50%;
    margin-left: 0;
  }
}
.callout__image-col {
  height: auto;
  min-height: 180px;
  overflow: hidden;
}
.callout__image-col .tile {
  margin: 0 -16px -32px;
  padding: 0;
  background-size: cover;
  background-position: 10% 15%;
  background-color: transparent;
  background-repeat: no-repeat;
  height: 100%;
}
@media (min-width: 992px) {
  .callout__image-col .tile {
    margin: 0;
    padding: 0;
  }
}
.callout__body-col .tile {
  min-height: 0;
  padding: 16px;
}
.callout__body-col .tile .title {
  font-size: 1.25rem;
  width: 100%;
}
.callout__body-col .tile .date {
  margin-top: 16px;
  font-weight: 300;
  font-size: 0.75rem;
  color: #848484;
}
.callout__body-col .tile .details {
  color: #9B9B93;
  line-height: 1.6;
  margin: 8px 0 0;
  font-size: 0.75rem;
  width: 100%;
}
.callout__body-col .tile .buttons {
  padding: 24px 0 0;
}
@media (min-width: 768px) {
  .callout__body-col .tile {
    padding: 56px;
  }
}
@media (min-width: 992px) {
  .callout__body-col .tile {
    padding: 56px;
  }
  .callout__body-col .tile .title {
    font-size: 2.8125rem;
    width: 100%;
  }
  .callout__body-col .tile .date {
    font-size: 1.375rem;
  }
  .callout__body-col .tile .details {
    margin-top: 2rem;
    font-size: 1.125rem;
    width: 100%;
  }
  .callout__body-col .tile .buttons {
    margin-top: 0.5rem;
  }
}
@media (min-width: 992px) {
  .callout {
    flex: 1 1 50%;
  }
  .callout--footer {
    top: -96px;
    margin-bottom: 96px;
  }
}

.search--top {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding: 0;
}
.search--top .form-control {
  width: 100%;
  display: block;
  background-color: white;
  color: #a1a1a1;
  border: 1px solid #a1a1a1;
  line-height: 2em;
  padding: 0.25em 2.25rem 0.25em 0.5em;
  font-size: 1.125rem;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  height: 4rem;
}
.search--top .form-control--smallerhalf {
  padding-right: 0.5em;
}
.search--top .form-control--half, .search--top .form-control--smallerhalf {
  width: 40%;
  display: inline-block;
  float: left;
}
.search--top .form-control::-ms-clear {
  display: none;
}
@media (min-width: 992px) {
  .search--top .form-control {
    font-size: 1.25rem;
  }
  .search--top .form-control--half {
    width: 50%;
  }
  .search--top .form-control--smallerhalf {
    width: 45%;
  }
}
@media (min-width: 1200px) {
  .search--top .form-control {
    font-size: 1.5rem;
  }
}
.search--top .form-control::-moz-placeholder {
  color: #a1a1a1;
  opacity: 1;
}
.search--top .form-control:-ms-input-placeholder {
  color: #a1a1a1;
}
.search--top .form-control::-webkit-input-placeholder {
  color: #a1a1a1;
}
@media (min-width: 992px) {
  .search--top {
    padding: 64px 32px;
  }
}
.search .icon-search {
  color: #00B140;
  font-size: 1.5rem;
  -webkit-transition: font-size 0.3s ease;
  -o-transition: font-size 0.3s ease;
  transition: font-size 0.3s ease;
}
.search-field {
  position: relative;
}
.search-field .submit-button {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 16px;
  z-index: 2;
}
.search-field .submit-button--side {
  right: 0;
  width: 20%;
  line-height: 2em;
  min-height: 3rem;
  padding: 0.25em 0.5em;
}
.search-field .submit-button--border {
  border: 0.0625rem solid #d8d8d8;
}
.search-field .submit-button--border:hover {
  background-color: #00B140;
}
.search-field .submit-button--border:hover .icon-search {
  font-size: inherit;
  color: #FFF;
}
.search-field .submit-button:hover .icon-search {
  font-size: 2.125rem;
}
@media (min-width: 992px) {
  .search-field .submit-button {
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    right: 32px;
  }
  .search-field .submit-button--top {
    right: 0;
  }
  .search-field .submit-button--side {
    min-height: 4rem;
    width: 10%;
  }
}
.search-block .accordion__bar {
  border: 1px solid #215732;
  background-color: #215732;
  color: #FFF;
}
.search-block .accordion__bar:hover:not(.accordion__bar--no-hover), .search-block .accordion__bar--active {
  border-color: #00B140;
  background-color: #00B140;
  color: #000;
}
@media (min-width: 992px) {
  .search-block--name-filters {
    background-color: #FFF;
  }
}

.search-options {
  margin: 0 auto;
}
.search-options__fields {
  margin-left: -16px;
  margin-right: -16px;
}
.search-options__fields:before, .search-options__fields:after {
  content: " ";
  display: table;
}
.search-options__fields:after {
  clear: both;
}
.search-options--wide .search-options__field {
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1200px) {
  .search-options--wide .search-options__field {
    float: left;
    width: 33.3333333333%;
  }
}
.search-options__fields--extra {
  overflow: hidden;
  max-height: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-transition: max-height 0.15s ease-out;
  -o-transition: max-height 0.15s ease-out;
  transition: max-height 0.15s ease-out;
}
.search-options__fields--extra.active {
  max-height: 400px;
  padding-bottom: 32px;
  overflow: visible;
}
.search-options__field {
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 1200px) {
  .search-options__field {
    float: left;
    width: 50%;
  }
}

.search-section-row {
  margin: 16px 0;
}
.search-section-row:first-child {
  margin-top: 0;
}
@media (min-width: 992px) {
  .search-section-row {
    margin: 32px 0;
  }
}

.advanced-button .icon,
.advanced-button .title {
  display: inline-block;
}
.advanced-button .icon.open,
.advanced-button .title.open {
  display: inline-block;
}
.advanced-button .icon.close,
.advanced-button .title.close {
  display: none;
}
.advanced-button.active .icon.open,
.advanced-button.active .title.open {
  display: none;
}
.advanced-button.active .icon.close,
.advanced-button.active .title.close {
  display: inline-block;
}

.jumbo-input {
  padding: 16px 24px;
  background-color: #FFF;
}
.jumbo-input--half {
  display: inline-block;
  width: 100%;
}
@media (min-width: 992px) {
  .jumbo-input {
    padding: 32px;
  }
  .jumbo-input--half {
    width: 50%;
    margin: 0;
    box-shadow: none;
  }
  .jumbo-input--right {
    float: right;
  }
}
.jumbo-input > .form-control {
  font-size: 1.125rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  color: #000;
  padding-left: 8px;
  padding-right: 48px;
}
.jumbo-input > .form-control::-ms-clear {
  display: none;
}
@media (min-width: 992px) {
  .jumbo-input > .form-control {
    font-size: 1.5rem;
  }
}
.jumbo-input > .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.jumbo-input > .form-control::-moz-placeholder { /* Firefox 19+ */
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.jumbo-input > .form-control:-ms-input-placeholder { /* IE 10+ */
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.jumbo-input > .form-control:-moz-placeholder { /* Firefox 18- */
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.jumbo-input > .form-control:hover::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.7);
}
.jumbo-input > .form-control:hover::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.7);
}
.jumbo-input > .form-control:hover:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0, 0, 0, 0.7);
}
.jumbo-input > .form-control:hover:-moz-placeholder { /* Firefox 18- */
  color: rgba(0, 0, 0, 0.7);
}
.jumbo-input > .form-control:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.1);
}
.jumbo-input > .form-control:focus::-moz-placeholder { /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.1);
}
.jumbo-input > .form-control:focus:-ms-input-placeholder { /* IE 10+ */
  color: rgba(0, 0, 0, 0.1);
}
.jumbo-input > .form-control:focus:-moz-placeholder { /* Firefox 18- */
  color: rgba(0, 0, 0, 0.1);
}

.hero {
  position: relative;
}
.hero-section {
  position: relative;
  margin-top: 48px;
}
@media (min-width: 992px) {
  .hero-section {
    margin-top: 36px;
  }
}
.hero-section.full-height {
  overflow: hidden;
}
@media (min-width: 992px) {
  .hero-section.full-height .hero-columns {
    padding-bottom: 0;
    min-height: calc(100vh - 32px);
  }
  .hero-section.full-height .hero-columns__column--secondary {
    margin-bottom: 16px;
  }
  .hero-section.full-height .hero-image {
    max-height: 100%;
  }
  .hero-section.full-height .hero-image img {
    height: 100%;
    width: auto;
    max-width: none;
    flex: 1 0 auto;
  }
  .hero-section.full-height .hero-flex-content {
    justify-content: center;
    width: 100%;
  }
}
.hero-container-fluid {
  margin-bottom: 48px;
}
.hero-container-fluid--no-margin-bottom {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .hero-container-fluid {
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
    padding-left: 36px;
    padding-right: 36px;
  }
  .hero-container-fluid:before, .hero-container-fluid:after {
    content: " ";
    display: table;
  }
  .hero-container-fluid:after {
    clear: both;
  }
}
.hero .bg-hero-image {
  z-index: -1;
  height: 100%;
  min-width: 100%;
  background-size: cover;
  position: absolute;
}
.hero .hero-image {
  position: relative;
  overflow: hidden;
  min-height: 100%;
  height: 100%;
  margin-left: 16px;
  margin-right: 16px;
  background-size: cover;
  background-position: 10% 15%;
  background-color: transparent;
  background-repeat: no-repeat;
}
.hero .hero-image:before {
  display: block;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
}
@media (min-width: 480px) {
  .hero .hero-image--professional {
    background-position: 20% 15%;
  }
}
@media (min-width: 768px) {
  .hero .hero-image--professional {
    background-position: 50% 15%;
  }
}
@media (min-width: 992px) {
  .hero .hero-image {
    margin: 0;
    max-height: none;
  }
}
.hero--homepage {
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .hero--homepage {
    height: calc(100vh - 32px);
    max-height: 640px;
    padding-bottom: 0;
  }
}
.hero--homepage .hero-image {
  margin-left: 0;
  margin-bottom: 2rem;
  position: absolute;
  width: 100%;
  background-position-x: left;
  overflow: visible;
}
.hero--homepage .hero-image:not(.nooverlay):before {
  opacity: 0.5;
}
@media (min-width: 992px) {
  .hero--homepage .hero-image {
    height: calc(100% - 2rem);
    max-height: 640px;
  }
}
.hero--homepage .hero-flex-content {
  height: calc(100vh - 32px);
  max-height: 640px;
  text-align: center;
  padding-top: 3.5rem;
}
@media (min-width: 992px) {
  .hero--homepage .hero-flex-content {
    padding: 8rem 0;
  }
}
.hero--homepage .hero-flex-content--short {
  padding-bottom: 0;
}
.hero--homepage .hero-flex-item {
  position: relative;
  color: #FFF;
  text-align: center;
  max-width: 52.5rem;
  margin: 0 auto;
  z-index: 10;
}
.hero--homepage .hero-flex-item--wide {
  max-width: 62.5rem;
}
.hero--homepage .hero-title {
  text-align: unset;
  padding: 0;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 3rem;
  font-weight: 100;
}
@media (min-width: 992px) {
  .hero--homepage .hero-title {
    font-size: 6.875rem;
  }
}
.hero--homepage .hero-subtitle {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1.375rem;
  font-weight: 600;
}
@media (min-width: 992px) {
  .hero--homepage .hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }
}
.hero .navlist {
  padding-top: 2rem;
  margin: 0;
}
.hero .navlist-item {
  margin: 0;
  margin-top: 2rem;
}
.hero .navlist-item:first-child {
  margin: 0;
}
.hero .navlist-text {
  position: relative;
  text-align: center;
}
@media (min-width: 992px) {
  .hero .navlist-text {
    text-align: left;
  }
  .hero .navlist-text .navlist-text-subtitle {
    opacity: 0;
  }
  .hero .navlist-text:hover .navlist-text-subtitle {
    opacity: 1;
    -webkit-transform: translate(0, -75%);
    -ms-transform: translate(0, -75%);
    -o-transform: translate(0, -75%);
    transform: translate(0, -75%);
  }
}
.hero .navlist-text-title {
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  font-size: 1.125rem;
  text-align: center;
  letter-spacing: 0.4em;
  padding-left: 0.4em;
}
.hero .navlist-text-title:focus {
  text-decoration: none;
}
.hero .navlist-text-title:hover, .hero .navlist-text-title:active, .hero .navlist-text-title.active {
  color: #0D7D2E;
  text-decoration: none;
}
@media (min-width: 992px) {
  .hero .navlist-text-title {
    font-size: 1.5rem;
    text-align: left;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .hero .navlist-text-title {
    font-size: 2rem;
  }
}
.hero .navlist-text-subtitle {
  position: relative;
  display: block;
  color: #FFF;
  padding-top: 40px;
  margin-top: 24px;
  font-size: 0.875rem;
  -webkit-transition: opacity 0.6s ease, transform 0.6s ease;
  -o-transition: opacity 0.6s ease, transform 0.6s ease;
  transition: opacity 0.6s ease, transform 0.6s ease;
  text-align: center;
  letter-spacing: 0.15em;
  padding-left: 0.15em;
}
.hero .navlist-text-subtitle:before {
  position: absolute;
  top: 7px;
  right: 50%;
  display: inline-block;
  content: " ";
  height: 2px;
  width: 64px;
  background-color: #00B140;
  -webkit-transform: translate(50%, 0);
  -ms-transform: translate(50%, 0);
  -o-transform: translate(50%, 0);
  transform: translate(50%, 0);
}
@media (min-width: 992px) {
  .hero .navlist-text-subtitle {
    white-space: nowrap;
    position: absolute;
    right: 100%;
    top: 50%;
    -webkit-transform: translate(5%, -75%);
    -ms-transform: translate(5%, -75%);
    -o-transform: translate(5%, -75%);
    transform: translate(5%, -75%);
    padding-right: 96px;
    padding-top: 0;
    margin-top: 0;
    font-size: 1.375rem;
    text-align: left;
    padding-left: 0;
  }
  .hero .navlist-text-subtitle:before {
    position: absolute;
    right: -2.5%;
    top: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
}
.hero-nav {
  align-self: flex-start;
  padding: 0 32px 24px;
}
@media (min-width: 992px) {
  .hero-nav {
    padding: 0 48px;
    text-align: left;
  }
}
.hero-flex-content {
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
  padding: 40px 0;
}
.hero-flex-content--short {
  padding-bottom: 0;
}
.hero-flex-content--tall {
  padding-bottom: 80px;
}
.hero-flex-content--no-img {
  margin-right: auto;
  margin-left: auto;
  padding-left: 32px;
  padding-right: 32px;
}
.hero-flex-content--no-img:before, .hero-flex-content--no-img:after {
  content: " ";
  display: table;
}
.hero-flex-content--no-img:after {
  clear: both;
}
@media (min-width: 768px) {
  .hero-flex-content--no-img {
    max-width: 784px;
  }
}
@media (min-width: 992px) {
  .hero-flex-content--no-img {
    max-width: 804px;
  }
}
@media (min-width: 1200px) {
  .hero-flex-content--no-img {
    max-width: 964px;
  }
}
@media (min-width: 1366px) {
  .hero-flex-content--no-img {
    max-width: 1096px;
  }
}
@media (min-width: 1920px) {
  .hero-flex-content--no-img {
    max-width: 1540px;
  }
}
.hero-flex-content--no-img .hero-flex-item {
  margin: 0 0;
}
@media (min-width: 768px) {
  .hero-flex-content--no-img {
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 32px;
    padding-right: 32px;
  }
  .hero-flex-content--no-img:before, .hero-flex-content--no-img:after {
    content: " ";
    display: table;
  }
  .hero-flex-content--no-img:after {
    clear: both;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .hero-flex-content--no-img {
    max-width: 784px;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .hero-flex-content--no-img {
    max-width: 740px;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .hero-flex-content--no-img {
    max-width: 900px;
  }
}
@media (min-width: 768px) and (min-width: 1366px) {
  .hero-flex-content--no-img {
    max-width: 1032px;
  }
}
@media (min-width: 768px) and (min-width: 1920px) {
  .hero-flex-content--no-img {
    max-width: 1476px;
  }
}
@media (min-width: 992px) {
  .hero-flex-content {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    padding: 64px 0;
    height: 100%;
  }
  .hero-flex-content--no-img {
    padding-top: 80px;
  }
  .hero-flex-content--short {
    padding-bottom: 0;
  }
  .hero-flex-content--center {
    justify-content: center;
    align-items: center;
  }
}
.hero-flex-content--pushdown {
  justify-content: flex-end;
}
.hero-flex-item-row {
  padding: 16px 0 24px 0;
  border-top: 1px solid #97D700;
}
.hero-flex-item-row:first-child, .hero-flex-item-row.flex-item-first {
  border-top: 0;
  padding-top: 64px;
}
.hero-flex-item-row.flex-item-last {
  padding-bottom: 16px;
}
.hero-flex-item-row--right {
  text-align: right;
}
.hero-flex-item-row--no-border {
  border-top: none;
}
@media (min-width: 375px) {
  .hero-flex-item-row {
    margin: 0 16px;
  }
}
@media (min-width: 480px) {
  .hero-flex-item-row {
    margin: 0 48px;
  }
}
.hero-title {
  color: #FFF;
  padding: 16px;
  width: 100%;
  text-align: center;
}
.hero-title--center {
  text-align: center;
}
@media (min-width: 992px) {
  .hero-title {
    text-align: left;
    padding: 0 48px;
    padding-top: 69px;
  }
  .hero-title--center {
    text-align: center;
  }
}
.hero-title-pro {
  color: #FFF;
}
.hero-date {
  color: #FFF;
  font-size: 16px;
  margin-bottom: 0.8rem;
}
.hero .title-text {
  margin-bottom: 16px;
  font-size: 1.125rem;
  letter-spacing: 0;
  text-transform: none;
}
.hero .title-text-pro {
  margin-bottom: 8px;
  font-size: 1.75rem;
  letter-spacing: normal;
  text-transform: none;
}
.hero .title-text-service {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .hero .title-text {
    margin-bottom: 32px;
  }
  .hero .title-text-pro {
    margin-bottom: 8px;
    font-size: 2.5rem;
  }
  .hero .title-text-service {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .hero .title-text {
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .hero .title-text {
    font-size: 2.5rem;
  }
  .hero .title-text-pro {
    font-size: 2.5rem;
  }
}
.hero .subtitle-text {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.08em;
}
.hero .subtitle-text-pro {
  font-weight: 300;
  font-size: 1.125rem;
  font-style: normal;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .hero .subtitle-text {
    font-size: 1.25rem;
    margin-right: 0.25em;
    margin-bottom: 1rem;
  }
}
.hero .location-text {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.08em;
}
@media (min-width: 992px) {
  .hero .location-text {
    font-size: 1.25rem;
    margin-right: 0.25em;
    margin-bottom: 1rem;
  }
}
.hero-lower-title .title-text {
  text-transform: none;
  letter-spacing: 0;
}
.hero-jumbo-title {
  position: absolute;
  padding: 0;
  top: -8.5em;
  width: 100%;
  z-index: 1;
  margin: 0 auto 0;
}
@media (min-width: 768px) {
  .hero-jumbo-title {
    top: -9.5em;
  }
}
@media (min-width: 992px) {
  .hero-jumbo-title {
    position: relative;
    top: 0;
    flex-shrink: 0;
  }
}
.hero-jumbo-title .title-text {
  margin: 0;
  padding: 0 0 0;
  line-height: normal;
  font-size: 2rem;
  text-align: center;
  letter-spacing: 0.25em;
  padding-left: 0.25em;
}
@media (min-width: 768px) {
  .hero-jumbo-title .title-text {
    font-size: 5rem;
  }
}
@media (min-width: 992px) {
  .hero-jumbo-title .title-text {
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    text-align: center;
    letter-spacing: 0.15em;
    padding-left: 0.15em;
    font-size: 6.25rem;
  }
}
@media (min-width: 1200px) {
  .hero-jumbo-title .title-text {
    text-align: center;
    letter-spacing: 0.25em;
    padding-left: 0.25em;
    font-size: 7.8125rem;
  }
}

.no-img {
  margin-bottom: 32px;
}
@media screen and (max-width: 992px) {
  .no-img {
    margin-bottom: 48px;
  }
}

.hero-columns {
  position: relative;
  width: 100%;
  display: block;
  justify-content: center;
  align-items: stretch;
}
@media (min-width: 992px) {
  .hero-columns {
    min-height: 297.6px;
  }
}
@media (min-width: 1200px) {
  .hero-columns {
    min-height: 360px;
  }
}
@media (min-width: 1366px) {
  .hero-columns {
    min-height: 409.8px;
  }
}
@media (min-width: 1920px) {
  .hero-columns {
    min-height: 576px;
  }
}
.hero-columns__column {
  position: relative;
}
.hero-columns__column--primary {
  width: 100%;
  z-index: 2;
}
@media (min-width: 992px) {
  .hero-columns__column--primary {
    min-height: 297.6px;
  }
}
@media (min-width: 1200px) {
  .hero-columns__column--primary {
    min-height: 360px;
  }
}
@media (min-width: 1366px) {
  .hero-columns__column--primary {
    min-height: 409.8px;
  }
}
@media (min-width: 1920px) {
  .hero-columns__column--primary {
    min-height: 576px;
  }
}
.hero-columns__column--secondary {
  z-index: 1;
  height: 400px;
}
@media (min-width: 992px) {
  .hero-columns__column--secondary {
    min-height: 297.6px;
  }
}
@media (min-width: 1200px) {
  .hero-columns__column--secondary {
    min-height: 360px;
  }
}
@media (min-width: 1366px) {
  .hero-columns__column--secondary {
    min-height: 409.8px;
  }
}
@media (min-width: 1920px) {
  .hero-columns__column--secondary {
    min-height: 576px;
  }
}
.hero-columns__column--pushdown {
  margin-top: 96px;
  margin-bottom: -32px;
}
@media (min-width: 992px) {
  .hero-columns__column--pushdown {
    margin-top: 16px;
  }
}
@media (min-width: 992px) {
  .hero-columns {
    display: flex;
    flex-flow: row nowrap;
    /*height: auto;*/
    padding-bottom: 16px;
  }
  .hero-columns--pushdown {
    padding-bottom: 84.8px;
  }
  .hero-columns__column {
    position: relative;
    width: 50%;
  }
  .hero-columns__column--full-width {
    width: 100%;
  }
  .hero-columns__column--secondary {
    /*height: auto;*/
    margin-top: 16px;
    margin-bottom: -16px;
  }
  .hero-columns__column--pushdown {
    margin-top: 133px;
    margin-bottom: -78px;
  }
  .hero-columns--homepage {
    min-height: calc(100vh - 32px);
  }
  .hero-columns--homepage .hero-columns__column {
    min-height: 100%;
  }
  .hero-columns--homepage .hero-columns__column--primary {
    height: inherit;
  }
  .hero-columns--homepage .hero-columns__column--primary .hero-flex-content {
    min-height: calc(100vh - 2rem);
    height: inherit;
  }
  .hero-columns--homepage .hero-columns__column--secondary {
    height: auto;
    margin-bottom: 2rem;
    min-height: calc(100% - 2rem);
  }
  .hero-columns--homepage .hero-image {
    margin-bottom: 2rem;
    min-height: calc(100% - 2rem);
    position: absolute;
    width: 200%;
    background-position-x: left;
    overflow: visible;
  }
}
@media (min-width: 992px) {
  .introanim .hero-columns__column--primary {
    position: relative;
    right: 0;
    display: flex;
    flex-direction: column;
  }
}

.primary-no-img {
  width: 100%;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .primary-no-img {
    margin-bottom: -32px;
  }
}

.secondary-no-img {
  width: 0;
  height: 0;
  z-index: 2;
}

.hero-section.introanim .hero .hero-image {
  background-color: #FDFDFD;
  -webkit-transition: opacity 0.8s ease 0.5s;
  -o-transition: opacity 0.8s ease 0.5s;
  transition: opacity 0.8s ease 0.5s;
  /*height: auto;*/
}
.hero-section.introanim .hero .hero-flex-content {
  -webkit-transition: top 0.8s ease 2.5s, opacity 0.8s ease 2.5s;
  -o-transition: top 0.8s ease 2.5s, opacity 0.8s ease 2.5s;
  transition: top 0.8s ease 2.5s, opacity 0.8s ease 2.5s;
  height: inherit;
  align-content: center;
  justify-content: center;
}
.hero-section.introanim .hero .hero-jumbo-title {
  -webkit-transition: top 0.8s ease 2.5s, opacity 0.8s ease 2.5s;
  -o-transition: top 0.8s ease 2.5s, opacity 0.8s ease 2.5s;
  transition: top 0.8s ease 2.5s, opacity 0.8s ease 2.5s;
}
.hero-section.introanim .hero .hero-nav {
  -webkit-transition: transform 0.8s ease 2.5s, opacity 0.9s ease 2.5s;
  -o-transition: transform 0.8s ease 2.5s, opacity 0.9s ease 2.5s;
  transition: transform 0.8s ease 2.5s, opacity 0.9s ease 2.5s;
}
.hero-section.introanim .link-block-scroll {
  -webkit-transition: transform 0.8s ease 2.25s;
  -o-transition: transform 0.8s ease 2.25s;
  transition: transform 0.8s ease 2.25s;
}
@media (min-width: 992px) {
  .hero-section.introanim--before .hero .hero-flex-content {
    top: -10%;
    opacity: 0;
  }
  .hero-section.introanim--before .hero .hero-image {
    opacity: 0;
  }
}
.hero-section.introanim--before .hero .hero-jumbo-title {
  top: -10%;
  opacity: 0;
}
.hero-section.introanim--before .hero .hero-nav {
  opacity: 0;
  -webkit-transform: translate(15%, 0);
  -ms-transform: translate(15%, 0);
  -o-transform: translate(15%, 0);
  transform: translate(15%, 0);
}
.hero-section.introanim--before .link-block-scroll {
  -webkit-transform: translate(-180%, 0);
  -ms-transform: translate(-180%, 0);
  -o-transform: translate(-180%, 0);
  transform: translate(-180%, 0);
}

.hero__location-address, .hero__location-meta {
  margin-top: 0.25rem;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1.125rem;
  color: #FFF;
  font-weight: 300;
}
.hero__location-name {
  font-size: 1.125rem;
  margin-bottom: 16px;
}
.hero__location-link {
  font-size: 1.125rem;
  font-weight: 300;
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.hero__location-link:focus {
  text-decoration: none;
}
.hero__location-link:hover, .hero__location-link:active, .hero__location-link.active {
  color: #00B140;
  text-decoration: none;
}
.hero__location-map {
  margin: 8px 0 32px;
}
.hero__location-email {
  margin-bottom: 32px;
  color: #FFF;
  font-size: 1.125rem;
}
.hero__location-meta a {
  color: inherit;
}
.hero__location-meta .label {
  padding-left: 1rem;
}
.hero__location-list {
  width: 100%;
  display: block;
  margin-bottom: -48px;
}
.hero__location-list-item {
  margin-bottom: 48px;
}
@media (min-width: 768px) {
  .hero__location-list {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
  .hero__location-list-item {
    flex: 1 0 auto;
    margin-right: 5%;
  }
  .hero__location-list-item:nth-child(2) {
    margin-right: 0;
  }
}
@media (min-width: 992px) {
  .hero__location-link {
    font-size: 0.7rem;
  }
  .hero__location-address, .hero__location-meta {
    font-size: 0.7rem;
  }
  .hero__location-email {
    font-size: 0.7rem;
  }
}
@media (min-width: 1047px) {
  .hero__location-link {
    font-size: 0.8rem;
  }
  .hero__location-address, .hero__location-meta {
    font-size: 0.8rem;
  }
  .hero__location-email {
    font-size: 0.8rem;
  }
}
@media (min-width: 1143px) {
  .hero__location-link {
    font-size: 0.9rem;
  }
  .hero__location-address, .hero__location-meta {
    font-size: 0.9rem;
  }
  .hero__location-email {
    font-size: 0.9rem;
  }
}
@media (min-width: 1367px) {
  .hero__location-link {
    font-size: 1rem;
  }
  .hero__location-address, .hero__location-meta {
    font-size: 1rem;
  }
  .hero__location-email {
    font-size: 1rem;
  }
}
@media (min-width: 1450px) {
  .hero__location-link {
    font-size: 1.125rem;
  }
  .hero__location-address, .hero__location-meta {
    font-size: 1.125rem;
  }
  .hero__location-email {
    font-size: 1.125rem;
  }
}

.social-box {
  display: block;
  margin: 0 auto 32px;
  text-align: center;
}
.social-box .social-item {
  position: relative;
  display: inline-block;
}
.social-box .social-item-link {
  color: #FFF;
  font-size: 0.875rem;
  margin-right: 0.5em;
}
@media (min-width: 768px) {
  .social-box {
    float: left;
    display: block;
    margin: 0;
    text-align: left;
  }
  .social-box .social-item-link {
    font-size: 0.75rem;
  }
}
@media (min-width: 1366px) {
  .social-box .social-item-link {
    font-size: 0.875rem;
  }
}

.share-toggle .icon,
.share-toggle .title {
  display: inline-block;
}
.share-toggle .icon.open,
.share-toggle .title.open {
  display: inline-block;
}
.share-toggle .icon.close,
.share-toggle .title.close {
  display: none;
}
.share-toggle.active .icon.open,
.share-toggle.active .title.open {
  display: none;
}
.share-toggle.active .icon.close,
.share-toggle.active .title.close {
  display: inline-block;
}

.card-box {
  position: relative;
  text-align: center;
  font-weight: 300;
}
.card-box .card-item {
  display: inline-block;
  position: relative;
  margin-left: 1em;
}
.card-box .card-item:first-child {
  margin-left: 0;
}
.card-box .card-item-link {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #FFF;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.card-box .card-item-link:focus {
  text-decoration: none;
}
.card-box .card-item-link:hover, .card-box .card-item-link:active, .card-box .card-item-link.active {
  color: #00B140;
  text-decoration: none;
}
@media (min-width: 1366px) {
  .card-box .card-item-link {
    font-size: 0.875rem;
  }
}

.card-box-float-rt {
  position: relative;
  text-align: center;
  font-weight: 300;
}
@media (min-width: 768px) {
  .card-box-float-rt {
    float: right;
  }
}

.card-box-center {
  position: relative;
  text-align: center;
  font-weight: 300;
  float: none;
  padding: 1.25rem;
}

.pro-email {
  margin-bottom: 24px;
  font-size: 1.25rem;
  font-weight: 300;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  color: #00B140;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.pro-email:focus {
  text-decoration: none;
}
.pro-email:hover, .pro-email:active, .pro-email.active {
  color: #00fe5c;
  text-decoration: none;
}

.pro-location {
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  color: #00B140;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.pro-location:focus {
  text-decoration: none;
}
.pro-location:hover, .pro-location:active, .pro-location.active {
  color: #00fe5c;
  text-decoration: none;
}

.share-block {
  overflow: hidden;
  position: absolute;
  left: auto;
  top: auto;
  z-index: 1;
  text-align: center;
}
.share-block-list {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: max-height 0.6s ease;
  -o-transition: max-height 0.6s ease;
  transition: max-height 0.6s ease;
}
.share-block.active .share-block-list {
  max-height: 400px;
}
.share-block-link {
  display: block;
  background-color: #D2D2D2;
  font-size: 0.875rem;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: normal;
  text-decoration: none;
  padding: 0.5em 1.5em;
  border-top: 1px solid #848484;
}
.share-block-link:hover {
  color: black;
  background-color: #9f9f9f;
  text-decoration: none;
}
.share-block-link:first-child {
  border-top: 0;
}

.section-title {
  margin: 0 0 32px;
  color: #000;
  font-size: 1.125rem;
}
.section-title--dark {
  color: #000;
}
.section-title .title {
  font-size: 1.125rem;
  text-align: left;
  letter-spacing: 1px;
  line-height: 1.6em;
}
.section-title .title--has-action-button {
  text-align: left;
}
@media (min-width: 1200px) {
  .section-title .title--has-action-button {
    text-align: center;
  }
}
.section-title .title--center {
  text-align: center;
}
.section-title .title--bold {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 600;
}
.section-title .title--small {
  font-size: 1.125rem;
}
@media (min-width: 768px) {
  .section-title .title {
    font-size: 1.375rem;
  }
}
@media (min-width: 992px) {
  .section-title .title {
    font-size: 1.5rem;
  }
}
.section-title .subtitle {
  text-align: center;
  margin-top: 16px;
  font-size: 0.875rem;
}
.section-title--hero {
  margin: 0 0 32px;
}
.section-title--hero:after {
  content: "";
  display: block;
  margin: 25px auto 0;
  width: 100px;
  border-bottom: 1px solid #97D700;
}
.section-title--has-action-button {
  position: relative;
  text-align: center;
}
@media (min-width: 600px) {
  .section-title--has-action-button {
    text-align: initial;
  }
}
.section-title .btn-position-right {
  margin-top: 16px;
  margin-bottom: 2rem;
}
@media (min-width: 600px) {
  .section-title .btn-position-right {
    margin-top: 0;
    position: absolute;
    right: 0;
    top: -50%;
  }
}
@media (min-width: 992px) {
  .section-title .title {
    font-size: 1.75rem;
    letter-spacing: 1px;
    line-height: normal;
  }
  .section-title .title--small {
    font-size: 1.125rem;
  }
  .section-title .subtitle {
    font-size: 1.125rem;
  }
  .section-title--hero {
    margin: 0 0 64px;
  }
}

.link-columns {
  position: relative;
  min-height: 325px;
}
@media (min-width: 992px) {
  .link-columns {
    height: 725px;
  }
}

.link-column {
  position: relative;
  z-index: 2;
  height: 100%;
  border-top: 1px solid #929292;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.link-column:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.link-column:first-child {
  border-top: 0;
}
.link-column .buttons {
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  padding-bottom: 0;
}
@media (min-width: 992px) {
  .link-column {
    position: static;
    padding: 128px 32px;
    border-top: 0;
    border-left: 1px solid #929292;
    background-image: none !important;
  }
  .link-column:before {
    content: none;
  }
  .link-column:first-child {
    border-left: 0;
  }
  .link-column, .link-columns:hover .link-column {
    opacity: 0.5;
  }
  .link-column .buttons, .link-columns:hover .link-column .buttons {
    opacity: 0;
  }
  .link-column:first-child, .link-columns:hover .link-column:hover {
    opacity: 1;
  }
  .link-column:first-child + .link-column-image, .link-columns:hover .link-column:hover + .link-column-image {
    opacity: 1;
  }
  .link-column:first-child .link-column-title, .link-columns:hover .link-column:hover .link-column-title {
    color: #00B140;
  }
  .link-column:first-child .buttons, .link-columns:hover .link-column:hover .buttons {
    opacity: 1;
  }
}
.link-column .column-inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  padding: 64px 2em;
  z-index: 1;
}
.link-column .column-inner > * {
  display: block;
  flex: 1 1 100%;
}
@media (min-width: 992px) {
  .link-column .column-inner {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    background: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .link-column .column-inner > * {
    display: block;
    flex: 0 1 auto;
    width: 100%;
  }
  .link-column .column-inner .buttons {
    align-self: flex-end;
  }
}

.link-column-title {
  width: 100%;
  margin-bottom: 64px;
  color: #00B140;
  letter-spacing: 0.15em;
  font-size: 1.375rem;
}
@media (min-width: 992px) {
  .link-column-title {
    width: 60%;
    margin-bottom: 0;
    color: #FFF;
    font-size: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .link-column-title {
    font-size: 1.25rem;
  }
}

.link-column-image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  min-width: 100%;
  height: auto;
  z-index: 0;
  opacity: 0;
  text-align: center;
  flex-wrap: nowrap;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
.link-column-image > img {
  max-width: none;
  min-width: 100%;
  min-height: 100%;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (min-width: 992px) {
  .link-column-image {
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  .link-column-image > img {
    min-width: 100%;
    min-height: 0;
  }
}

.tile-list {
  margin: 0;
  border-left: 1px solid #000;
  position: relative;
}
.tile-list .tile {
  margin: 0;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: -1px;
}

@media (min-width: 992px) {
  .columns-3.tile-list {
    border-top: 1px solid #000;
  }
  .columns-3.tile-list .tile:nth-child(1), .columns-3.tile-list .tile:not(.column--wide):nth-child(2) {
    border-top: 1px solid #000;
  }
}
.tile {
  overflow: hidden;
  position: relative;
  display: block;
  min-height: 140px;
  margin: 0;
  color: #000;
  background-color: #FFF;
  -webkit-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}
.tile:not(.hover-static):hover {
  background-color: #0D7D2E;
  color: #FFF;
}
@media (min-width: 480px) {
  .tile {
    min-height: 240px;
  }
}
@media (min-width: 992px) {
  .tile {
    display: flex;
    flex-flow: row nowrap;
    min-height: 340px;
  }
}
.tile a {
  color: inherit;
  width: 100%;
}
.tile.dark {
  background-color: #000;
  color: #FFF;
}
.tile.dark .tile-date,
.tile.dark .tile-time,
.tile.dark .category-list-item {
  color: inherit;
}
.tile-link {
  display: block;
}
.tile:not(.hover-static):hover .tile-date,
.tile:not(.hover-static):hover .tile-time,
.tile:not(.hover-static):hover .category-list-item {
  color: inherit;
}
.tile-date, .tile-time {
  color: #929292;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 300;
}
.tile .accent-arrow-solo {
  top: calc(100% - 2px);
}
.tile .accent-arrow-solo .icon {
  opacity: 0;
}
.tile:hover .accent-arrow-solo {
  width: 20%;
}
.tile:hover .accent-arrow-solo .icon {
  opacity: 1;
}
.tile:hover .tile-image {
  transform: scale(1.15);
}
.tile__address-row {
  font-size: 1.125rem;
  line-height: 1.4em;
  font-weight: 300;
}
.tile__address-title {
  margin-bottom: 16px;
  font-size: 1.75rem;
  font-weight: 600;
}
@media (min-width: 992px) {
  .tile__address-title {
    min-height: 1.5em;
  }
}

.tile-details {
  position: relative;
  z-index: 2;
  padding: 16px 0;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1.375rem;
  font-weight: 300;
}
@media (min-width: 992px) {
  .tile-details {
    font-size: 1.75rem;
  }
}

.tile-image {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.tile-image--static-right {
  background-position: right center;
  background-size: auto;
}
.tile-image--static-right:before {
  content: "";
  display: none;
}

.tile-inner {
  position: static;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  flex-direction: row;
  margin: 0 16px;
  padding: 8px 0;
  width: calc(100% - 16px);
}
.tile-inner + .tile-inner {
  margin-top: 8px;
}
@media (min-width: 768px) {
  .tile-inner {
    padding: 16px 0;
    margin: 0 32px;
    width: calc(100% - 64px);
  }
}
@media (min-width: 992px) {
  .tile-inner {
    padding: 32px 0;
    margin-left: 32px;
  }
  .tile-inner + .tile-inner {
    margin-top: 0;
  }
}
.column--wide .tile-inner {
  height: 50%;
}
@media (min-width: 992px) {
  .column--wide .tile-inner {
    height: 100%;
  }
}
.tile-inner .tile-row {
  position: relative;
  width: 100%;
  padding: 4px 0;
  z-index: 1;
}
@media (min-width: 480px) {
  .tile-inner .tile-row {
    padding: 8px 0;
  }
}
@media (min-width: 768px) {
  .tile-inner .tile-row {
    padding: 16px 0;
  }
}
.tile-inner .tile-row-last {
  margin-top: auto;
  align-self: flex-end;
}
.tile-inner .tile-row__title {
  position: relative;
  z-index: 1;
  width: 100%;
  letter-spacing: 0.15em;
  font-size: 1.375rem;
}
.tile-inner > * {
  flex: 0 1 auto;
}

.masongrid {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}
.masongrid .accent-arrow-solo {
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.masongrid .accent-arrow-solo .icon {
  opacity: 0;
}
.masongrid__decor {
  position: relative;
  height: 104px;
}
.masongrid__decor--short {
  height: 16px;
  padding: 0 0 32px;
}
.masongrid__decor-arrow {
  display: block;
}
.masongrid__item {
  flex-basis: 100%;
  margin: 0 0 16px;
}
.masongrid__item--wide .accent-arrow-solo {
  display: block;
}
.masongrid__item--wide .masongrid__decor-arrow {
  display: none;
}
.masongrid__item--careers-path {
  min-height: 224px;
  margin: 0 0 40px;
}
.masongrid__item--button-container {
  position: relative;
}
.masongrid__link {
  width: 100%;
  height: 100%;
  display: block;
}
.masongrid__box {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 3px solid #D2D2D2;
  background-size: cover;
  background-position: 10% 15%;
  background-color: transparent;
  background-repeat: no-repeat;
  color: #000;
  background-color: #FFF;
  -webkit-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}
.masongrid__box:not(.hover-static):hover {
  background-color: #0D7D2E;
  color: #FFF;
}
.masongrid__box a {
  color: inherit;
}
.masongrid__box--inner {
  padding: 24px;
}
.masongrid__box--dark {
  color: #FFF;
  background-color: #003057;
  -webkit-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}
.masongrid__box--dark:not(.hover-static):hover {
  background-color: #0D7D2E;
  color: #003057;
}
.masongrid__box--img a {
  color: #FFF;
}
.masongrid__box:hover .accent-arrow-solo {
  width: 20%;
}
.masongrid__box:hover .accent-arrow-solo .icon {
  opacity: 1;
}
.masongrid__box:hover .masongrid__image {
  transform: scale(1.15);
}
.masongrid__title {
  font-size: 1.125rem;
}
.masongrid__title--center {
  text-align: center;
}
.masongrid__title--left {
  text-align: left;
}
.masongrid--large-title .masongrid__title {
  font-size: 1.5rem;
}
.masongrid__description {
  margin-top: 24px;
  font-size: 1.25rem;
  font-weight: 300;
}
.masongrid--large-description .masongrid__description {
  font-size: 1.5rem;
}
.masongrid__button {
  position: absolute;
  bottom: -24px;
}
.masongrid__button--center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 80%;
}
.masongrid__button--center a {
  width: 100%;
}
.masongrid__button .btn {
  white-space: initial;
}
@media (min-width: 768px) {
  .masongrid__item {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
    margin-left: 4px;
    margin-right: 4px;
  }
  .masongrid__item--wide {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .masongrid__item {
    flex-basis: calc(33.33% - 8px);
    max-width: calc(33.33% - 8px);
    margin-left: 4px;
    margin-right: 4px;
  }
  .masongrid__item--wide {
    flex-basis: calc(66.66% - 4px);
    max-width: calc(66.66% - 4px);
    margin-left: 2px;
    margin-right: 2px;
  }
}
@media (min-width: 1920px) {
  .masongrid__item--small {
    flex-basis: calc(25% - 8px);
    max-width: calc(25% - 8px);
    margin-left: 4px;
    margin-right: 4px;
  }
}
.masongrid--center {
  justify-content: center;
}
.masongrid--left {
  justify-content: flex-start;
}

.teaser {
  color: #000;
}
.teaser-title {
  margin-bottom: 8px;
  font-size: 1.375rem;
}
.teaser-details {
  margin-bottom: 8px;
  font-size: 1.375rem;
  font-weight: 300;
}
.teaser-item {
  padding-right: 16px;
  padding-bottom: 16px;
}
.teaser .columns-inline > .column.teaser__col {
  width: 100%;
  text-align: center;
}
@media (min-width: 1200px) {
  .teaser .columns-inline > .column.teaser__col {
    width: auto;
    text-align: left;
  }
  .teaser__col--icon {
    padding-right: 16px;
  }
}
@media (min-width: 1200px) {
  .teaser-details {
    padding-right: 64px;
  }
  .teaser .columns-inline > .column.teaser__col.teaser__col--buttons {
    text-align: right;
  }
}

.tabs {
  position: relative;
}
.tabs-list {
  overflow: hidden;
  position: absolute;
  z-index: 99;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  margin: 0 0 0 0;
  max-width: 100%;
  max-height: 0;
  width: 100%;
  top: auto;
  left: 0;
  -webkit-transition: max-height 0.6s linear;
  -o-transition: max-height 0.6s linear;
  transition: max-height 0.6s linear;
}
.tabs-list:last-child:not(:first-child) {
  border-top: 0;
}
@media (min-width: 992px) {
  .tabs-list {
    margin: 1px 0 0 1px;
    overflow: visible;
    flex-flow: row;
    flex-wrap: nowrap;
    position: static;
    max-height: none;
  }
}
.tabs-list-item {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  margin: 0;
  padding: 24px 16px;
  text-align: center;
  border: 1px solid #0E1841;
  background: #FFF;
  -webkit-transition: background 0.3s ease, color 0.3s ease;
  -o-transition: background 0.3s ease, color 0.3s ease;
  transition: background 0.3s ease, color 0.3s ease;
  line-height: 1.1;
}
.tabs-list-item:hover, .tabs-list-item.active {
  opacity: 1;
  border-color: #0D7D2E;
  background-color: #0D7D2E;
  color: #FFF;
  overflow: visible;
}
.tabs-list-item:only-of-type {
  display: none;
}
@media (min-width: 992px) {
  .tabs-list-item {
    width: 33.333%;
    margin: -2px 0 0 -1px;
  }
  .tabs-list-item.active:before {
    display: inline-block;
  }
}
.tabs-list-bottom .tabs-list-item.active:before {
  border-top-color: transparent;
  top: -24px;
}
.tabs-list-title {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 600;
}
.tabs-sections {
  position: relative;
  max-height: 0;
}
.tabs-section {
  position: relative;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  height: 0;
  z-index: 0;
  padding: 0;
  margin: 0;
}
.tabs-section .area:first-of-type:not(.area--spaced) {
  padding-top: 0;
}
.tabs-section .area-block:first-of-type:not(.area-spaced) {
  margin-top: 0;
}
.tabs-section .section:first-of-type {
  margin-top: 0;
}
.tabs-section .section:last-of-type {
  margin-bottom: 0;
}
.tabs-section.active {
  visibility: visible;
  opacity: 1;
  overflow: visible;
  height: auto;
  width: auto;
  z-index: 1;
  padding: 32px 0;
}
.tabs-section:only-of-type {
  padding-top: 0;
}
.tabs-section:only-of-type.active {
  padding-top: 0;
}
@media (min-width: 992px) {
  .tabs-section.active {
    padding: 64px 0;
  }
}
.tabs-close {
  position: absolute;
  display: none;
  padding: 0;
  right: 1px;
  top: 8px;
  z-index: 2;
  font-size: 2.2rem;
  background-color: rgba(255, 255, 255, 0);
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.tabs-close:focus {
  text-decoration: none;
}
.tabs-close:hover, .tabs-close:active, .tabs-close.active {
  color: #0D7D2E;
  text-decoration: none;
}
.tabs-close > .icon-x {
  line-height: normal;
}
@media (min-width: 992px) {
  .tabs.active .tabs-close {
    display: inline-block;
  }
}
.tabs-toptitle {
  position: relative;
  flex: 1;
  width: 100%;
  margin: 0;
  padding: 24px 32px;
  text-align: center;
  border: 1px solid #0E1841;
  background: #FFF;
  -webkit-transition: background 0.3s ease, color 0.3s ease;
  -o-transition: background 0.3s ease, color 0.3s ease;
  transition: background 0.3s ease, color 0.3s ease;
}
.tabs-toptitle:before {
  content: " ";
  display: block;
  position: absolute;
  right: 20px;
  top: 45%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #A9ED77 transparent transparent transparent;
  -webkit-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
.tabs-toptitle--active:before {
  -webkit-transform: translate(0, -50%) rotate(180deg);
  -ms-transform: translate(0, -50%) rotate(180deg);
  -o-transform: translate(0, -50%) rotate(180deg);
  transform: translate(0, -50%) rotate(180deg);
}
@media (min-width: 992px) {
  .tabs-toptitle {
    display: none;
  }
}
.tabs.dropdownactive {
  position: relative;
}
.tabs.dropdownactive .tabs-list {
  max-height: 999px;
}
.tabs.active .tabs-sections {
  max-height: none;
}
.tabs.active .tabs-list-item {
  background-color: #FFF;
  border-color: #000;
  color: #000;
}
.tabs.active .tabs-list-item.active {
  border-color: #0D7D2E;
  background-color: #0D7D2E;
  color: #FFF;
}

.buttons {
  padding: 16px 0;
}
@media (min-width: 992px) {
  .buttons {
    padding: 32px 0;
  }
}
.buttons-form-submit {
  padding: 0.875rem 48px;
  border: 1px solid #000;
  background: #FFF;
  color: #000;
  font-size: 0.875rem;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 400;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.buttons-form-submit:hover {
  background: #000;
  color: #FFF;
}
@media screen and (max-width: 480px) {
  .buttons-form-submit {
    width: 100%;
  }
}

.filtertoggles {
  margin-bottom: 48px;
}
.filtertoggles--centered {
  text-align: center;
}
.filtertoggles-item {
  display: block;
  padding: 16px 0;
  border-bottom: 2px solid #929292;
  color: #929292;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 400;
  text-align: left;
  width: 100%;
  cursor: pointer;
}
.filtertoggles-item:nth-child(odd):not(.filtertoggles-item--centered) {
  margin-left: 0;
}
.filtertoggles-item:hover, .filtertoggles-item.active {
  color: #000;
  border-color: #0D7D2E;
}
@media (min-width: 992px) {
  .filtertoggles {
    border-bottom: 1px solid #0D7D2E;
    margin-bottom: 34px;
  }
  .filtertoggles-item {
    display: inline-block;
    width: auto;
    padding: 8px 0;
    margin: 0 8px -2px;
    border-bottom-color: transparent;
  }
  .filtertoggles-item:nth-child(odd):not(.filtertoggles-item--centered) {
    margin-left: 8px;
  }
  .filtertoggles-item:first-child:not(.filtertoggles-item--centered) {
    margin-left: 0;
  }
  .filtertoggles-item:hover, .filtertoggles-item.active {
    color: #000;
    border-color: #0D7D2E;
  }
}

.accordion {
  position: relative;
}
.accordion + .accordion {
  margin: -32px 0 0;
}
.accordion + .accordion:last-of-type {
  border-bottom: 1px solid #215732;
}
.accordion .accordion__item + .accordion__item {
  margin: -32px 0 0;
}
.accordion .accordion__item + .accordion__item:last-of-type {
  border-bottom: 1px solid #215732;
}
.accordion__close {
  position: absolute;
  left: 100%;
  top: calc(50% - 16px);
  z-index: 100;
  display: none;
  padding: 0 16px;
}
.accordion__close:before {
  position: relative;
  display: block;
  content: " ";
  width: 60%;
  margin: 0 auto 8px;
  height: 1px;
  border-top: 1px solid #000;
}
.accordion__close--active {
  display: block;
}
.accordion__bar {
  display: block;
  position: relative;
  flex: 1;
  width: 100%;
  margin: 32px 0 0;
  padding: 16px 8px;
  padding-right: 23.5294117647px;
  text-align: left;
  border-top: 1px solid #215732;
  background-color: #FFF;
  color: #000;
  font-size: 1.125rem;
  line-height: 1.1em;
  outline: none;
  cursor: initial;
}
@media (min-width: 992px) {
  .accordion__bar {
    padding: 16px 32px;
    padding-right: 40px;
  }
}
.accordion__bar:focus {
  outline: none !important;
}
.accordion__bar:not(.accordion__bar--no-hover) {
  -webkit-transition: background-color 0.3s ease, color 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.3s ease;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.accordion__bar:hover:not(.accordion__bar--no-hover), .accordion__bar--active {
  opacity: 1;
  border-color: #00B140;
  background-color: #00B140;
  outline: none;
}
.accordion__bar--active {
  color: #FFF;
}
.accordion__section {
  padding: 0 0 32px;
}
.accordion__section:last-child {
  padding-bottom: 0;
}
.accordion__icon {
  position: absolute;
  right: 8.8888888889px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
}
.accordion__icon .icon,
.accordion__icon .title {
  display: block;
}
.accordion__icon .icon.open,
.accordion__icon .title.open {
  display: block;
}
.accordion__icon .icon.close,
.accordion__icon .title.close {
  display: none;
}
.accordion__icon.active .icon.open,
.accordion__icon.active .title.open {
  display: none;
}
.accordion__icon.active .icon.close,
.accordion__icon.active .title.close {
  display: block;
}
@media (min-width: 992px) {
  .accordion__icon {
    right: 13.3333333333px;
  }
}
.accordion__icon .title {
  font-weight: 300;
  color: #000;
  font-size: 16px;
}
.accordion__icon.active .close.title {
  display: none;
}
.accordion__icon.active.stuck {
  top: 0;
  right: -4px;
  -webkit-transform: translate(100%, 0);
  -ms-transform: translate(100%, 0);
  -o-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-transition: margin-top 0.3s ease;
  -o-transition: margin-top 0.3s ease;
  transition: margin-top 0.3s ease;
}
.scrolling-up .accordion__icon.active.stuck {
  margin-top: 40px;
}
.accordion__icon.active.stuck .close.title {
  display: block;
  font-size: 0.5625rem;
}
.accordion__icon.active.stuck .close.icon {
  display: block;
  line-height: normal;
}
@media (min-width: 992px) {
  .accordion__icon.active.stuck {
    right: -16px;
  }
  .accordion__icon.active.stuck .close.title {
    font-size: 16px;
  }
}
.accordion__title {
  line-height: 1.1;
  font-weight: 300;
}
.accordion__item {
  background-color: #FFF;
}
@keyframes activeAccordion {
  0% {
    overflow: hidden;
  }
  99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}
.accordion__inner {
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}
.accordion__inner--closed {
  overflow: hidden;
}
.accordion__inner--active {
  animation: activeAccordion 1s ease-in-out;
  -webkit-animation: activeAccordion 1s ease-in-out;
  -ms-animation: activeAccordion 1s ease-in-out;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.accordion__inner--light {
  background: #FFF;
}
@media (max-width: 768px) {
  .accordion__inner--active {
    overflow: visible;
  }
}
.accordion__content {
  padding: 16px 8px;
}
@media (min-width: 992px) {
  .accordion__content {
    padding: 16px 32px;
  }
  .accordion__icon {
    right: 22px;
  }
}
.accordion .child-service-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
}
.accordion .child-service-list__item {
  display: inline-block;
  width: 100%;
  padding-right: 0px;
  margin-bottom: 19px;
  overflow: visible;
}
.accordion .child-service-list__item:after {
  content: "";
  display: block;
  position: relative;
  width: 47px;
  height: 1px;
  transition: width 0.3s ease-in-out;
  top: 4.5px;
  background-color: #97D700;
}
.accordion .child-service-list__item:hover:after {
  width: 67px;
}
@media (min-width: 992px) {
  .accordion .child-service-list__item {
    width: 33.3333%;
    padding-right: 70px;
  }
}
.accordion .child-service-list__heading {
  border-bottom: 1px solid #0D7D2E;
  color: #0D7D2E;
}
.accordion .child-service-list__categorized:not(:first-child()) {
  padding-top: 1rem;
}

.accordion-collapse-buttons {
  text-align-last: end;
}
.accordion-collapse-buttons span {
  font-weight: bold;
  color: #0D7D2E;
  cursor: pointer;
}
.accordion-collapse-buttons .text-transform {
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

noscript + .accordion__item {
  margin: -28.8px 0 0;
}

.contactus-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.5625rem;
}
.contactus-form-asterisk {
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 0.625rem;
  font-weight: 100;
  color: #545454;
  text-align: left;
  width: 100%;
  padding-bottom: 8px;
}
.contactus-form input {
  width: 49%;
  margin-bottom: 16px;
  flex-grow: 1;
  padding: 16px 0 1.75rem;
  border: none;
  border-bottom: 1px solid hsla(0deg, 0%, 57%, 0.2);
}
.contactus-form input::placeholder {
  color: #545454;
  font-weight: 100;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
}
.contactus-form input:nth-child(even) {
  margin-right: 16px;
}
@media screen and (max-width: 992px) {
  .contactus-form input {
    width: 100%;
  }
  .contactus-form input:nth-child(even) {
    margin-right: 0;
  }
}
.contactus-form-field-long {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.375rem;
}
.contactus-form textarea {
  font-weight: 100;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  text-align: left;
  font-size: 16px;
  border: 1px solid #848484;
  background: #F8F8F8;
  margin-bottom: 32px;
  height: 208px;
}
.contactus-form-label {
  color: #545454;
  font-weight: 100;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  text-align: left;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.contactus-form-disclaimer {
  color: #545454;
  font-weight: 100;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  text-align: left;
  font-size: 16px;
  margin-top: 32px;
}

.stacklist {
  height: 100%;
}
.stacklist-block .column {
  position: relative;
  flex: 1 1 auto;
}
.stacklist-block .column:last-child {
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .stacklist-block .column {
    margin-bottom: 0;
  }
}
.stacklist .columns-inline {
  margin-bottom: 0;
}
.stacklist .columns-inline > .column.stacklist-metacol {
  width: 100%;
  padding-right: 0;
}
.stacklist .columns-inline > .column.stacklist-symbolcol {
  width: 96px;
  text-align: center;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  color: #929292;
  display: none;
}
@media (min-width: 480px) {
  .stacklist .columns-inline > .column.stacklist-metacol {
    width: 60%;
    padding-right: 32px;
  }
  .stacklist .columns-inline > .column.stacklist-symbolcol {
    display: block;
  }
}
.stacklist-symbol {
  display: inline-block;
  margin: 0 auto;
}
.stacklist-time-icon, .stacklist-event-icon {
  display: block;
}
.stacklist-event-icon {
  font-size: 2.8125rem;
}
.stacklist-time-icon {
  font-size: 2.8125rem;
}
.stacklist-item {
  display: block;
  padding-bottom: 24px;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.stacklist-item:focus {
  text-decoration: none;
}
.stacklist-item:hover, .stacklist-item:active, .stacklist-item.active {
  color: #0D7D2E;
  text-decoration: none;
}
@media screen and (max-width: 480px) {
  .stacklist-item {
    margin-bottom: 16px;
  }
}
.stacklist-item--bordered {
  border-top: 1px solid #0D7D2E;
  padding: 32px 0;
}
.stacklist-item--bordered:first-of-type {
  border-top: none;
  padding: 0 0 32px 0;
}
.stacklist-meta {
  color: #929292;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .stacklist-meta {
    font-size: 16px;
  }
}
.stacklist-meta--site-search {
  line-height: 1.5;
}
.stacklist-meta--site-search .highlight {
  font-weight: bold;
}
.stacklist-meta-item {
  display: inline-block;
  margin-bottom: 8px;
  margin-left: 0.25em;
  color: #000;
}
.stacklist-meta-item:before {
  margin-right: 0.5em;
  content: "|";
}
.stacklist-meta-item:first-child {
  color: #0D7D2E;
  margin-left: 0;
}
.stacklist-meta-item:first-child:before {
  margin-right: 0;
  content: "";
}
.stacklist-meta-item-alt {
  display: inline-block;
  margin-top: 8px;
  margin-left: 0.25em;
}
.stacklist-item-title {
  margin: 0;
  font-size: 1.75rem;
  line-height: 2rem;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.stacklist-item-title:focus {
  text-decoration: none;
}
.stacklist-item-title:hover, .stacklist-item-title:active, .stacklist-item-title.active {
  color: #0D7D2E;
  text-decoration: none;
}
.stacklist-item-title--uppercase {
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .stacklist-item-title {
    font-size: 1.75rem;
  }
}

.services-links {
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.6875rem;
}
.services-links .column-inner {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
}
.services-links .btn {
  margin-bottom: 8px;
  font-size: 0.625rem;
}
@media (min-width: 992px) {
  .services-links .btn {
    margin-bottom: 32px;
    font-size: 0.75rem;
  }
}

.peoplelist-keycontacts {
  background: #eeeeee;
  border: #777777 1px solid;
  margin: 1rem auto;
  padding-top: 1rem;
}
@media (min-width: 992px) {
  .peoplelist-keycontacts {
    max-width: 90%;
    padding: 1rem 1rem 0;
  }
}
.peoplelist-item {
  margin-bottom: 32px;
}
@media (min-width: 992px) {
  .peoplelist-item {
    padding-left: 32px;
    padding-right: 32px;
  }
  .peoplelist-item:nth-child(odd) {
    padding-left: 0;
  }
  .peoplelist-item:nth-child(even) {
    padding-right: 0;
  }
}
.peoplelist-name {
  font-size: 1.75rem;
  margin-top: 8px;
}
.peoplelist-link-pro {
  display: block;
}
.peoplelist-link-pro img {
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.peoplelist-link-pro:hover .peoplelist-image > img {
  border-color: #a6a6a6;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.peoplelist-image {
  border: 1px solid transparent;
  overflow: hidden;
  -webkit-transition: box-shadow 0.3s ease, border-color 0.3s ease;
  -o-transition: box-shadow 0.3s ease, border-color 0.3s ease;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}
.peoplelist-image > img {
  display: block;
  width: 100%;
}
.peoplelist-role {
  color: #929292;
  font-weight: 300;
}
.peoplelist-meta {
  padding: 16px 0 16px;
  border-top: 1px solid rgba(146, 146, 146, 0.2);
}
.peoplelist .meta-block {
  color: #004163;
  font-weight: 300;
}
.peoplelist .meta-block .meta-item {
  display: inline-block;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
}
.peoplelist .meta-block .meta-item.phone {
  font-size: 1.125rem;
}
.peoplelist .meta-block .meta-item.phone:after {
  margin-right: 0;
  margin-left: 0.3em;
  content: "|";
}
.peoplelist .meta-block .meta-item.location {
  font-size: 1.125rem;
}
.peoplelist .meta-block .meta-item:first-child:before {
  margin-right: 0;
  content: "";
}

.authors {
  width: 100%;
}
.authors-list {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}
.authors-module {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 16px 0 16px;
}
@media screen and (max-width: 480px) {
  .authors-module {
    flex-direction: column;
    margin-bottom: 48px;
  }
}
.authors-module .authors-link {
  order: 0;
  flex-shrink: 0;
}
@media screen and (max-width: 768px) {
  .authors-module .authors-link {
    flex-shrink: 1;
  }
}
.authors-module .authors-meta {
  order: 1;
}
.authors-link .peoplelist-image {
  max-width: 400px;
}
.authors-link .peoplelist-image > img {
  border: 1px solid transparent;
  -webkit-transition: border-color 0.3s ease;
  -o-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
.authors-link:hover .peoplelist-image > img {
  border-color: #a6a6a6;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
.authors-name {
  font-size: 1.75rem;
  width: 100%;
  font-weight: 400;
  margin-bottom: 16px;
}
.authors-name--small {
  font-size: 1.375rem;
}
@media screen and (max-width: 480px) {
  .authors-name {
    margin-bottom: 0;
  }
}
.authors-role {
  color: #929292;
  font-weight: 300;
  margin-bottom: 16px;
}
.authors-meta {
  width: 100%;
  padding-left: 48px;
  font-weight: 300;
  display: flex;
  flex-wrap: wrap;
}
.authors-meta .meta-block-titles {
  color: #004163;
  font-weight: 300;
  border-bottom: 1px solid rgba(146, 146, 146, 0.2);
  margin-bottom: 16px;
  flex-basis: 100%;
}
.authors-meta .meta-block-column {
  flex-basis: 50%;
  margin-bottom: 1em;
}
.authors-meta .meta-block-column .meta-block {
  flex-basis: 100%;
}
@media screen and (max-width: 992px) {
  .authors-meta {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .authors-meta {
    padding-left: 32px;
  }
}
@media screen and (max-width: 480px) {
  .authors-meta {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .authors-meta .meta-block-column {
    display: block;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
}

.alphalist {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.alphalist-item {
  display: inline-block;
  flex: 0 1 auto;
  text-align: center;
  width: 1.4em;
  margin-bottom: 0.5em;
  cursor: pointer;
}
.alphalist-item:hover .alphalist-text {
  font-size: 1.25rem;
}
.alphalist-item:hover .alphalist-text-disabled {
  font-size: 1.375rem;
}
.alphalist-text {
  padding: 0;
  margin: 0;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.375rem;
  line-height: 1.25rem;
  vertical-align: text-bottom;
  color: #000;
  -webkit-transition: font-size 0.2s ease;
  -o-transition: font-size 0.2s ease;
  transition: font-size 0.2s ease;
}
.alphalist-text-disabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.5);
  transition: none;
}
@media (min-width: 768px) {
  .alphalist {
    justify-content: space-around;
  }
  .alphalist-item {
    flex: 1 1 auto;
    width: 1em;
  }
}
@media (min-width: 992px) {
  .alphalist-text {
    font-size: 1.125rem;
    line-height: 2rem;
  }
  .alphalist-item:hover .alphalist-text {
    font-size: 2rem;
  }
  .alphalist-item:hover .alphalist-text-disabled {
    font-size: 1.125rem;
  }
}

.category-list-item {
  color: #929292;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 400;
}
.category-list-item:after {
  content: "|";
  margin: 0 0.5em 0 0.5em;
}
.category-list-item:last-child:after {
  content: "";
}

.tagslist-item {
  margin-right: 16px;
  margin-bottom: 16px;
  text-transform: none;
  letter-spacing: 0.05em;
}

.linklist {
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
}
.linklist:before, .linklist:after {
  content: " ";
  display: table;
}
.linklist:after {
  clear: both;
}
.linklist__columns {
  margin-left: -16px;
  margin-right: -16px;
  columns: 2;
  -moz-columns: 2;
}
@media screen and (max-width: 992px) {
  .linklist__columns {
    columns: 1;
    -moz-columns: 1;
  }
}
.linklist__item {
  width: 100%;
  display: inline-block;
  padding: 0 16px 5px;
  margin: 2px 0;
}
.linklist__title {
  display: block;
  border-bottom: 1px solid #CCC;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 300;
  padding: 10.6666666667px;
}
.linklist__title--big {
  font-size: 2rem;
  line-height: 2rem;
  padding: 8px;
}
@media (min-width: 992px) {
  .linklist__title--big {
    line-height: 2.75rem;
  }
}

.sidefixer {
  position: absolute;
  right: 0;
  top: auto;
  z-index: 97;
  display: block;
  height: auto;
}
.sidefixer--placeholder {
  height: 0px;
  width: 0px;
  overflow: hidden;
  z-index: 0;
  visibility: hidden;
}
.sidefixer--active {
  z-index: 97;
}
.sidefixer__inner {
  position: relative;
  overflow: visible;
  margin-left: 100px;
}
.sidefixer__trigger {
  position: absolute;
  top: 0%;
  display: inline-block;
  padding: 8px 16px;
  -webkit-transition: top 0.6s ease, margin-top 0.6s ease;
  -o-transition: top 0.6s ease, margin-top 0.6s ease;
  transition: top 0.6s ease, margin-top 0.6s ease;
  background-color: #00B140;
  color: #000;
  letter-spacing: 0.3em;
  transform: rotateZ(-90deg) translateX(-100%) translateY(-100%);
  transform-origin: 0% 0%;
  overflow-y: hidden;
}
.sidefixer__trigger--reachedlimit {
  top: 100%;
  transform: rotateZ(-90deg) translateX(0%) translateY(-100%);
}
.sidefixer__trigger-title {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 600;
}
.sidefixer__trigger-open-text {
  display: inline-block;
}
.sidefixer--active .sidefixer__trigger-open-text {
  display: none;
}
.sidefixer__trigger-close-text {
  display: none;
}
.sidefixer--active .sidefixer__trigger-close-text {
  display: inline-block;
}
.sidefixer__content {
  position: relative;
  overflow: hidden;
  width: 0;
  background-color: #FFF;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
}
.sidefixer__content--active {
  width: calc(100vw - 100px);
}
.sidefixer__content-inner {
  width: calc(100vw - 100px);
}
@media (min-width: 768px) {
  .sidefixer {
    height: calc(100vh - 64px);
  }
  .sidefixer__inner {
    height: 100%;
    margin-left: 116px;
  }
  .sidefixer--active .sidefixer__inner {
    margin-left: 160px;
  }
  .sidefixer__trigger {
    padding: 16px 32px;
  }
  .sidefixer__trigger-title {
    font-size: 1.125rem;
  }
  .sidefixer__content {
    height: 100%;
    width: 0;
  }
  .sidefixer__content--active {
    width: 474px;
  }
  .sidefixer__content-inner {
    height: 100%;
    width: 474px;
  }
}

.stacktiles {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
.stacktiles__buttons {
  flex: 0 1 auto;
}
.stacktiles__inner {
  height: 30%;
  flex: 1 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
}
.stacktiles__tile {
  display: block;
  flex: 1 1 33.33%;
  position: relative;
  padding: 32px 32px;
  border-top: 1px solid #929292;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #D2D2D2;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.stacktiles__tile:focus {
  text-decoration: none;
}
.stacktiles__tile:hover, .stacktiles__tile:active, .stacktiles__tile.active {
  color: #00B140;
  text-decoration: none;
}
.stacktiles__tile:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.stacktiles__tile:first-child {
  border-top: 0;
}
.stacktiles__tile .buttons {
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  padding-bottom: 0;
}
.stacktiles__tile__tile-inner {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  z-index: 1;
}
.stacktiles__tile__tile-inner > * {
  display: block;
  flex: 1 1 100%;
}
@media (min-width: 480px) {
  .stacktiles__tile {
    padding: 64px 64px;
  }
}
.stacktiles__title {
  position: relative;
  z-index: 1;
  width: 100%;
  letter-spacing: 0.15em;
  font-size: 1.375rem;
}
@media (min-width: 992px) {
  .stacktiles {
    height: 100%;
    justify-content: flex-end;
  }
  .stacktiles__tile {
    padding: 3% 64px;
  }
  .stacktiles__buttons {
    flex: 0 1 auto;
  }
  .stacktiles__inner {
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
  }
  .stacktiles__tile-inner {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }
  .stacktiles__tile-inner > * {
    display: block;
    flex: 0 1 auto;
    width: 100%;
  }
  .stacktiles__title {
    width: 60%;
    margin-bottom: 0;
    font-size: 1.75rem;
  }
}

.card {
  overflow: hidden;
  position: relative;
  margin: 0;
  border: 1px solid #000;
  text-align: center;
  color: #000;
  background-color: #FFF;
  -webkit-transition: background-color 0.6s ease;
  -o-transition: background-color 0.6s ease;
  transition: background-color 0.6s ease;
}
.card:not(.hover-static):hover {
  background-color: #0D7D2E;
  color: #FFF;
}
.card.dark {
  background-color: #000;
  color: #FFF;
}
.card--left {
  text-align: left;
}
.card__link {
  display: block;
  color: inherit;
  height: 100%;
}
.card__link--img {
  color: #FFF;
}
.card .accent-decor {
  display: inline-block;
  position: relative;
  margin: 0 auto;
}
.card .accent-download {
  font-size: 1.25rem;
  line-height: 1;
  color: #000;
  top: 0;
  left: 0;
  -webkit-transition: transform 0.6s ease, color 0.3s ease;
  -o-transition: transform 0.6s ease, color 0.3s ease;
  transition: transform 0.6s ease, color 0.3s ease;
}
.card .accent-arrow {
  border-color: #000;
  transition: border-color 0.3s ease, color 0.3s ease, width 0.3s ease;
}
.card .accent-arrow .icon {
  color: #000;
}
.card__link--img .accent-download,
.card__link--img .accent-arrow {
  color: #FFF;
}
.card__link--img .accent-arrow {
  border-color: #FFF;
}
.card__link--img .accent-arrow .icon {
  color: #FFF;
}
.card:hover a {
  color: inherit;
}
.card:hover .accent-arrow-solo {
  width: 20%;
}
.card:hover .accent-download {
  -webkit-transform: translate(0, 7px);
  -ms-transform: translate(0, 7px);
  -o-transform: translate(0, 7px);
  transform: translate(0, 7px);
  color: #FFF;
}
.card:hover .accent-arrow {
  border-color: #FFF;
}
.card:hover .accent-arrow .icon {
  color: #FFF;
}
.card:hover .card__image {
  transform: scale(1.15);
}
.card__list {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
  margin-top: -16px;
}
.card__list .card {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .card__list {
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
  }
  .card__list .card {
    flex: 1 1 calc(30% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    width: calc(30% - 48px);
    max-width: 40%;
    min-height: 204px;
  }
  .card__list .card:nth-child(3n+1) {
    margin-left: 0;
  }
  .card__list .card:nth-child(3n) {
    margin-right: 0;
  }
  .card__list .card:only-child {
    margin-left: 0;
    margin-right: 0;
  }
}
.card__title {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1.375rem;
  font-weight: 300;
}
.card__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 32px 32px;
  background-size: cover;
  background-position: 50% 50%;
}
.card__inner:before {
  content: "";
  flex-grow: 1;
}
.card__row {
  position: relative;
  width: 100%;
  margin: 16px 0;
}
.card__row:first-child {
  margin-top: 0;
}
.card__row:last-child {
  margin-bottom: 0;
}
.card__image {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.narrative__text {
  font-size: 1.125rem;
  padding-right: 64px;
}
.narrative__button {
  text-align: left;
  padding-top: 32px;
}
@media (min-width: 992px) {
  .narrative__button {
    padding-top: 0;
    text-align: right;
  }
}

.search--fade-in {
  transition: visibility 0.3s ease, opacity 0.3s ease;
}
.search__results {
  display: none;
}
.search__results--active {
  display: block;
}
.search__placeholder {
  display: block;
}
.search__placeholder--active {
  display: none;
}
.search-block__title {
  position: relative;
  margin: 64px 0 32px;
}
.search__keywordlist {
  position: relative;
  margin: 32px 0 16px;
  text-align: center;
  display: none;
}
.search__keywordlist--active {
  display: block;
}
.search__keyword {
  padding: 8px 16px 8px 8px;
  font-size: 16px;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 300;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  border: solid 1px #0D7D2E;
  border-radius: 20px;
  margin-right: 5px;
}
.search__keyword:focus {
  text-decoration: none;
}
.search__keyword:hover, .search__keyword:active, .search__keyword.active {
  color: #0D7D2E;
  text-decoration: none;
}
.search__keyword .icon {
  font-size: 1rem;
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.search__keyword.clear-all {
  background-color: #D2D2D2;
  border: none;
}
.search__sections {
  float: right;
  margin-top: 1rem;
  font-weight: bold;
  color: #FFF;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 1.5px;
}
.search__sections .section-option {
  font-weight: normal;
  color: #FFF;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 0.875rem;
}
.search__sections .section-option:hover, .search__sections .section-option--active {
  color: #00B140;
}
.search__sections--hidden {
  visibility: hidden;
}
.search__share {
  position: relative;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0 auto;
  font-size: 0.875rem;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
}
.search__share:focus {
  text-decoration: none;
}
.search__share:hover, .search__share:active, .search__share.active {
  color: #004163;
  text-decoration: none;
}
.title + .search__share {
  margin-top: 1em;
}
@media (min-width: 992px) {
  .search__share {
    position: absolute;
    top: -16px;
    right: 0;
  }
  .search__share, .title + .search__share {
    margin: 0;
  }
}
.search__resultstitle {
  position: relative;
  margin: 40px 0 32px;
  color: #666666;
  text-align: center;
  text-transform: uppercase;
}
.search__resultstitle .title {
  font-size: 0.875rem;
  text-align: center;
  letter-spacing: 0.25em;
  padding-left: 0.25em;
  line-height: 1.6em;
  text-transform: uppercase;
}
.search__resultstitle.no-results .title {
  color: #000;
  font-size: 1.75rem;
  text-transform: none;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0;
  padding-left: 0;
}
.search__resultstitle.no-results .sub-title {
  font-size: 1.125rem;
  text-transform: none;
}
@media (min-width: 992px) {
  .search__resultstitle .title {
    font-size: 1.125rem;
  }
}
.search__resultstitle--site-search:after {
  content: " ";
  clear: both;
  display: table;
}
.search__resultstitle--site-search .title--left {
  float: left;
}
.search__resultstitle--site-search .title--right {
  float: right;
}
.search .tabs-list-container .tabs-list {
  flex-flow: row;
  overflow: visible;
  position: relative;
  max-height: none;
}
.search .tabs-list-container .tabs-list .tabs-list-item {
  width: auto;
  height: fit-content;
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  display: inline-block;
  padding-bottom: 0.5rem;
  background-color: transparent;
  border: none;
  flex: none;
  margin-right: 2rem;
  margin-bottom: 1rem;
  color: #D2D2D2;
}
.search .tabs-list-container .tabs-list .tabs-list-item:hover {
  color: #00B140;
  border-bottom: 0.25rem solid;
  border-color: #00B140;
}
.search .tabs-list-container .tabs-list .active-button {
  color: #FFF;
  border-bottom: 0.25rem solid;
  border-color: #00B140;
}
.search .tabs-sections {
  position: absolute;
}
.search .active-section {
  padding: 0;
  width: 100%;
  visibility: visible;
  height: auto;
  z-index: 1;
  overflow: visible;
  opacity: 1;
}

.mobileshowhide__mobile {
  display: block;
}
.mobileshowhide__desktop {
  display: none;
}
@media (min-width: 992px) {
  .mobileshowhide__mobile {
    display: none;
  }
  .mobileshowhide__desktop {
    display: block;
  }
}

.readmore__content {
  overflow: hidden;
  -webkit-transition: height 0.6s ease-out, margin-top 0.6s ease-out;
  -o-transition: height 0.6s ease-out, margin-top 0.6s ease-out;
  transition: height 0.6s ease-out, margin-top 0.6s ease-out;
}
.readmore__inner {
  margin-bottom: 32px;
}
.readmore__inner--active {
  margin-top: 32px;
}
.readmore__limit {
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.readmore__trigger {
  position: relative;
  padding: 0;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  outline: none;
}
.readmore__trigger:focus {
  text-decoration: none;
}
.readmore__trigger:hover, .readmore__trigger:active, .readmore__trigger.active {
  color: #0D7D2E;
  text-decoration: none;
}
.readmore__trigger .icon,
.readmore__trigger .title {
  display: inline-block;
}
.readmore__trigger .icon.open,
.readmore__trigger .title.open {
  display: inline-block;
}
.readmore__trigger .icon.close,
.readmore__trigger .title.close {
  display: none;
}
.readmore__trigger.readmore__trigger--active .icon.open,
.readmore__trigger.readmore__trigger--active .title.open {
  display: none;
}
.readmore__trigger.readmore__trigger--active .icon.close,
.readmore__trigger.readmore__trigger--active .title.close {
  display: inline-block;
}
.readmore .btn {
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  padding: 0.8em 4em;
  font-size: 16px;
  line-height: 1.4;
  border-radius: 0;
}
.readmore .btn, .readmore .btn:visited {
  background-color: transparent;
  border-color: #97D700;
}
.readmore .btn:focus {
  text-decoration: none;
}
.readmore .btn:hover, .readmore .btn:active, .readmore .btn.active {
  color: #000;
  text-decoration: none;
}
.readmore .btn, .readmore .btn:visited, .readmore .btn:focus, .readmore .btn.focus {
  background-color: transparent;
  border-color: #97D700;
}
.readmore .btn:hover, .readmore .btn:active, .readmore .btn.active {
  background-color: #00B140;
  border-color: #00B140;
}
.readmore .btn:hover .accent-arrow, .readmore .btn:active .accent-arrow, .readmore .btn.active .accent-arrow {
  border-color: #000;
}
.readmore .btn.disabled, .readmore .btn[disabled] {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.readmore .btn.disabled:hover, .readmore .btn.disabled:focus, .readmore .btn.disabled.focus, .readmore .btn[disabled]:hover, .readmore .btn[disabled]:focus, .readmore .btn[disabled].focus, fieldset[disabled] .readmore .btn:hover, fieldset[disabled] .readmore .btn:focus, fieldset[disabled] .readmore .btn.focus {
  color: #777777;
  background-color: #eeeeee;
  border-color: #777777;
}
.readmore .btn .badge {
  color: transparent;
  background-color: #000;
}
.readmore--authors .readmore__inner--active {
  margin-top: 0;
}
.readmore--authors .authors-list {
  margin-top: 0;
}

.typeahead-list {
  position: absolute;
  background-color: #FFF;
  z-index: 10;
  min-width: 100%;
  display: none;
  left: 0;
  right: 0;
  top: calc(100% - 6px);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
  padding-bottom: 8px;
  max-height: 500%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.customselect .typeahead-list {
  box-shadow: none;
  border-top: none;
  width: calc(100% - 2px);
}
.typeahead-list .typeahead-list-inprogress,
.typeahead-list .typeahead-list-empty {
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  color: #a1a1a1;
  text-align: left;
  padding: 0 40px 8px;
}
.typeahead-list .typeahead-list-item {
  cursor: pointer;
  display: block;
  outline: none;
  width: 100%;
  margin-top: -8px;
  margin-bottom: 8px;
  padding: 8px 40px;
  text-align: left;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  color: #a1a1a1;
  font-weight: 400;
}
.customselect .typeahead-list .typeahead-list-item {
  margin-top: 0;
  padding: 8px 16px;
}
.typeahead-list .typeahead-list-item:hover, .typeahead-list .typeahead-list-item:focus {
  color: #FFF;
  font-weight: 400;
  background-color: #00B140;
}
.typeahead-list .typeahead-list-item:last-of-type {
  margin-bottom: 0;
}
.typeahead-list.active {
  display: block;
  border: 0.0625rem solid #000;
  border-top: #a1a1a1 0.1rem solid;
  box-shadow: none;
  transform: translate(-0.25%);
  width: 100.5%;
}
.typeahead-list--header {
  padding-top: 16px;
}
.typeahead-list--header .typeahead-list-item {
  padding-left: 16px;
}
@media (min-width: 992px) {
  .typeahead-list .typeahead-list-inprogress,
.typeahead-list .typeahead-list-empty {
    padding: 0 48px 8px;
  }
  .typeahead-list .typeahead-list-item {
    margin-top: -8px;
    margin-bottom: 8px;
    padding: 8px 48px;
  }
  .typeahead-list .typeahead-list-item:last-of-type {
    margin-bottom: 0;
  }
  .typeahead-list--header .typeahead-list-item {
    padding-left: 16px;
  }
}

.loading-overlay {
  position: absolute;
  z-index: 20;
  height: 100%;
  width: 100%;
  background-color: rgba(185, 185, 185, 0.5);
  color: #00B140;
}
.loading-overlay-spinner {
  position: absolute;
  top: 51px;
  left: calc(50% - 24px);
  font-size: 3rem;
  line-height: 1rem;
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.view-more {
  position: relative;
  color: #00B140;
}
.view-more-spinner {
  position: absolute;
  left: calc(50% - 24px);
  font-size: 3rem;
  line-height: 1rem;
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.languageprompt {
  position: fixed;
  z-index: 0;
  top: 40%;
  left: 0;
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  background-color: #545454;
  color: #FFF;
  -webkit-transition: top 0.6s ease-in, opacity 0.6s ease-in;
  -o-transition: top 0.6s ease-in, opacity 0.6s ease-in;
  transition: top 0.6s ease-in, opacity 0.6s ease-in;
}
.languageprompt--animate, .languageprompt--active {
  height: auto;
  width: 100%;
  overflow: visible;
  z-index: 96;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.languageprompt--animate {
  opacity: 0;
  top: 40%;
}
.languageprompt--active {
  opacity: 1;
  top: 50%;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.languageprompt--hide {
  display: none;
}
.languageprompt__inner {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
.languageprompt__row + .languageprompt__row {
  margin-top: 32px;
}
.languageprompt__description {
  font-size: 1.125rem;
  font-weight: 300;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  letter-spacing: 0.02em;
}
.languageprompt__button {
  margin: 0 16px;
}

.cookienotification {
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 70%;
  overflow: hidden;
  opacity: 0;
  background-color: #ffffff;
  color: #000000;
  -webkit-transition: bottom 0.6s ease-in, opacity 0.6s ease-in;
  -o-transition: bottom 0.6s ease-in, opacity 0.6s ease-in;
  transition: bottom 0.6s ease-in, opacity 0.6s ease-in;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
  line-height: 22px;
  font-family: Cardo Regular;
  display: none;
}
.cookienotification--animate, .cookienotification--active {
  height: auto;
  overflow: visible;
  z-index: 95;
  display: block;
}
.cookienotification--animate {
  opacity: 0;
}
.cookienotification--active {
  opacity: 0.9;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}
.cookienotification--hide {
  display: none;
}
.cookienotification__inner {
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 0px 36px;
}
.cookienotification__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.cookienotification__row + .cookienotification__row {
  margin-top: 24px;
}
@media (max-width: 768px) {
  .cookienotification__row {
    flex-direction: column;
  }
}
.cookienotification__description {
  font-size: 1.125rem;
  font-weight: 300;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  letter-spacing: 0.02em;
}
.cookienotification__button {
  margin: 0 16px;
}
.cookienotification__row-item:not(:first-child) {
  margin-left: 5px;
}
@media (max-width: 768px) {
  .cookienotification__row-item:not(:first-child) {
    margin-left: 0;
    margin-top: 24px;
  }
}
@media (max-width: 768px) {
  .cookienotification__row-item {
    width: 100%;
    overflow: hidden;
  }
}

.subsection-list {
  display: flex;
  flex-wrap: wrap;
}
.subsection-list__item {
  display: block;
  border-bottom: solid 1px #666666;
  padding: 15px 40px 15px 20px;
  position: relative;
  font-size: 1.25rem;
  width: 100%;
  margin-right: 0;
}
.subsection-list__item:nth-child(even) {
  margin-left: 0px;
  margin-right: 0px;
}
.subsection-list__item .accent-arrow {
  right: 0;
  top: 50%;
  transition: 0.3s width ease-in-out;
}
.subsection-list__item:hover {
  color: #0D7D2E;
}
.subsection-list__item:hover .accent-arrow {
  width: 42px;
}
@media (min-width: 992px) {
  .subsection-list__item {
    width: calc(50% - 40px);
    margin-right: 40px;
  }
  .subsection-list:nth-child(even) {
    margin-left: 20px;
    margin-right: 0px;
  }
}

.simple-tabs .simple-tab {
  display: none;
}
.simple-tabs .simple-tab--active {
  display: block;
}

.button-alignment__left {
  text-align: left;
}
.button-alignment__center {
  text-align: center;
}
.button-alignment__right {
  text-align: right;
  margin-right: 12px;
}
@media (min-width: 992px) {
  .button-alignment__right {
    margin-right: 0;
  }
}

.modal-mask {
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.modal-mask.active {
  opacity: 1;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 75%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.7s ease;
}
@media (min-width: 480px) {
  .modal-container {
    width: 50%;
  }
}

.modal-header h4 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  padding-bottom: 20px;
  text-align: center;
}
@media (min-width: 992px) {
  .modal-footer {
    text-align: start;
  }
}
@media (min-width: 480px) {
  .modal-footer .btn {
    margin-right: 10px;
  }
}
.modal-footer .btn:not(:first-child) {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .modal-footer .btn:not(:first-child) {
    margin-top: 0;
  }
}

.modal-default-button {
  float: right;
}
.modal-default-button.btn-left {
  padding-right: 10px;
}

.modal-lock {
  overflow: hidden;
}

.insight-card {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .insight-card {
    padding-right: 25px;
  }
  .insight-card:nth-child(3n) {
    margin-right: -25px;
  }
}
.insight-card a {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.insight-card__image {
  padding-top: 66%;
  background-size: cover;
}
.insight-card__content {
  position: relative;
  padding-top: 20px;
  flex: 1;
  background-color: #FFF;
}
.insight-card__tag, .insight-card__title, .insight-card__blurb {
  -webkit-transition: color 0.6s ease;
  -o-transition: color 0.6s ease;
  transition: color 0.6s ease;
}
.insight-card__tag {
  color: #0D7D2E;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 6px;
  font-weight: 600;
  letter-spacing: 1px;
}
.insight-card__title {
  color: #000;
  font-size: 1.375rem;
  line-height: 1.5rem;
  margin-bottom: 6px;
}
@media (min-width: 768px) {
  .insight-card__title {
    font-size: 1.75rem;
    line-height: 1.875rem;
  }
}
.insight-card__title:not(.hover-static):hover {
  color: #0D7D2E;
}
.insight-card__blurb {
  color: #000;
  font-size: 1.125rem;
}
.insight-card .accent-arrow-solo {
  top: 30px;
  border-color: #0D7D2E;
}
.insight-card .accent-arrow-solo .icon {
  color: #0D7D2E;
  opacity: 0;
}
@media (min-width: 768px) {
  .insight-card .accent-arrow-solo {
    top: 35px;
  }
}
.insight-card:not(.hover-static):hover .accent-arrow-solo {
  width: 20%;
  border-color: #FFF;
}
.insight-card:not(.hover-static):hover .accent-arrow-solo .icon {
  color: #FFF;
  opacity: 1;
}

.home-professional-search {
  background-color: #000;
}
@media (min-width: 992px) {
  .home-professional-search {
    margin-bottom: 0;
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
}
.home-professional-search__inner {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}
.home-professional-search__title {
  padding: 1rem 1rem 0;
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1.75rem;
  color: #00B140;
  font-weight: 600;
}
@media (min-width: 992px) {
  .home-professional-search__title {
    padding: 2rem 1rem 0;
  }
}
.home-professional-search .search-block--name-filters {
  background-color: transparent;
  box-shadow: none;
  padding-top: 1rem;
}
@media (min-width: 992px) {
  .home-professional-search .search-block--name-filters {
    padding-top: 0rem;
  }
}
.home-professional-search .jumbo-input {
  background-color: transparent;
  padding: 0rem 1rem;
}
@media (min-width: 992px) {
  .home-professional-search .jumbo-input {
    padding: 2rem 0.5rem;
  }
}
.home-professional-search .jumbo-input .form-control {
  border: none;
  font-family: "acumin-pro-regular", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  padding: 1rem 3rem 1rem 1rem;
  font-size: 1.125rem;
  font-weight: 400;
}
.home-professional-search .jumbo-input .form-control:focus {
  background: #FFF;
}
.home-professional-search .jumbo-input .form-control:focus::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #000;
}
.home-professional-search .jumbo-input .form-control:focus::-moz-placeholder { /* Firefox 19+ */
  color: #000;
}
.home-professional-search .jumbo-input .form-control:focus:-ms-input-placeholder { /* IE 10+ */
  color: #000;
}
.home-professional-search .jumbo-input .form-control:focus:-moz-placeholder { /* Firefox 18- */
  color: #000;
}
.home-professional-search .icon-search {
  color: #00B140;
  font-size: 1.5rem;
  -webkit-transition: font-size 0.3s ease;
  -o-transition: font-size 0.3s ease;
  transition: font-size 0.3s ease;
}
.home-professional-search .submit-button {
  right: 1rem;
}

.feature-video {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  padding-left: 36px;
  padding-right: 36px;
}
@media (min-width: 768px) {
  .feature-video {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .feature-video__image {
    width: 50%;
    padding-right: 3.75rem;
  }
}
.feature-video__image img {
  width: 100%;
}
.feature-video__content {
  padding: 1.25rem 0;
}
@media (min-width: 768px) {
  .feature-video__content {
    width: 50%;
    padding: 1.25rem 1.25rem 1.25rem 0;
  }
}
.feature-video__tag {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.feature-video__tag:after {
  content: "";
  display: block;
  margin-top: 25px;
  width: 100px;
  border-bottom: 1px solid #97D700;
}
.feature-video__title {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 100;
  margin-bottom: 3.5rem;
}
@media (min-width: 992px) {
  .feature-video__title {
    font-size: 2.375rem;
  }
}
.feature-video:before, .feature-video:after {
  content: " ";
  display: table;
}
.feature-video:after {
  clear: both;
}
@media (min-width: 1920px) {
  .feature-video {
    max-width: 1924px;
  }
}

.trending-topics {
  color: #FFF;
}
.trending-topics .section-title {
  color: #FFF;
}
.trending-topics .section-title:after {
  border-color: #97D700;
}
.trending-topics__decor {
  background-size: cover;
  padding: 2rem 0;
}
.trending-topics__block {
  margin-bottom: 2rem;
}
.trending-topics__block:last-child {
  margin-bottom: 0;
}
.trending-topics__image {
  position: relative;
}
.trending-topics__content {
  background: rgba(0, 0, 0, 0.4);
  padding: 2rem 1rem;
}
.trending-topics__tag {
  font-family: "acumin-pro-semibold", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
}
.trending-topics__title {
  font-size: 2rem;
  line-height: 1.1;
  margin-bottom: 0.75rem;
  font-weight: 100;
}
.trending-topics__blurb {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
}
.trending-topics__button a {
  color: #97D700;
}
@media (min-width: 992px) {
  .trending-topics {
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
    padding-left: 36px;
    padding-right: 36px;
  }
  .trending-topics:before, .trending-topics:after {
    content: " ";
    display: table;
  }
  .trending-topics:after {
    clear: both;
  }
  .trending-topics__decor {
    padding: 3rem 0;
  }
  .trending-topics__block {
    display: flex;
  }
  .trending-topics__block:first-child {
    flex-direction: row-reverse;
  }
  .trending-topics__block:first-child .trending-topics__content {
    margin-top: 3rem;
    margin-right: -6.5rem;
    padding-right: 9.5rem;
  }
  .trending-topics__block:nth-child(2) {
    align-items: flex-end;
  }
  .trending-topics__block:nth-child(2) .trending-topics__content {
    margin-bottom: 3rem;
    margin-left: -6.5rem;
    padding-left: 9.5rem;
  }
  .trending-topics__content {
    padding: 2rem 3rem;
    max-width: 700px;
  }
  .trending-topics__tag {
    color: #00B140;
    font-size: 1.125rem;
  }
  .trending-topics__title {
    font-size: 2.5rem;
  }
}

.location_bar {
  display: block;
  border: 1px solid #003150;
  background-color: #00B140;
  line-height: 1.1em;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: left;
  outline: none;
  width: 100%;
  flex: 1;
  position: relative;
  padding: 16px 32px 0px;
  font-family: Gill Sans W01, Gill Sans, sans-serif;
  font-weight: 100;
  margin-bottom: 16px;
}
.location_bar h3 {
  color: #ffffff;
  font-family: Gill Sans W01, Gill Sans, sans-serif;
  font-weight: 100;
  line-height: 1.1em;
  font-size: 1.5rem;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-transition: top 0.6s ease, background-color 0.2s ease;
  -o-transition: top 0.6s ease, background-color 0.2s ease;
  transition: top 0.6s ease, background-color 0.2s ease;
}
.scrolling-down .header:not(.menu-active) {
  top: -100%;
}
.scrolling-up .header {
  background-color: #000;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.header-section-left {
  position: absolute;
  max-width: calc(48% - 32px);
  padding: 0 32px;
  top: 0;
  left: 0;
  z-index: 4;
  margin-top: 64px;
  -webkit-transition: top 0.4s ease 0.1s;
  -o-transition: top 0.4s ease 0.1s;
  transition: top 0.4s ease 0.1s;
}
.brand-secondary .header-section-left {
  margin-top: 48px;
}
@media (min-width: 992px) {
  .brand-secondary .header-section-left {
    margin-top: 32px;
  }
}
@media (min-width: 992px) {
  .header-section-left {
    padding: 16px;
    left: 64px;
    margin-top: 56px;
  }
}
.scrolling-down .header-section-left {
  display: none;
}
.scrolling-up .header-section-left {
  margin-top: 32px;
}
.header-section-left-breadcrumb {
  color: #00B140;
  text-align: right;
  padding-right: 16px;
  padding-top: 1.25rem;
  vertical-align: bottom;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  display: none;
}
.header-section-left-breadcrumb:hover {
  color: #00fe5c;
}
.scrolling-up .header-section-left-breadcrumb {
  display: block;
}
@media screen and (max-width: 992px) {
  .header-section-left-breadcrumb {
    padding-top: 0.875rem;
  }
}
@media screen and (max-width: 480px) {
  .scrolling-up .header-section-left-breadcrumb {
    display: none;
  }
}

.header-section-right {
  position: relative;
  float: right;
  right: -32px;
  z-index: 3;
  background-color: #000;
}
.scrolling-up .header-section-right {
  margin-top: 32px;
}

.menu-active .header-section-left a {
  cursor: default;
}
@media (max-width: 767px) {
  .menu-active .header-section-left {
    display: none;
  }
}

.header-section-full {
  position: absolute;
  top: -16px;
  right: 0;
  z-index: 2;
  width: 100%;
}

.admin-banner {
  font-size: 1.5rem;
  justify-content: center;
  background-color: #800000;
  color: white;
  width: 100%;
  height: 5rem;
  position: fixed;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  text-align: center;
  z-index: 100;
  font-weight: 400;
}

.admin-banner + .header {
  position: relative;
  top: 5rem;
}

.header-section-centre {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  font-size: 10px !important;
  line-height: 1;
}
.scrolling-up .header-section-centre {
  background-color: white;
  color: black;
  padding: 0 16px;
  margin-left: -2rem;
  position: absolute;
  width: 100%;
  height: 2rem;
}
@media screen and (max-width: 992px) {
  .scrolling-up .header-section-centre {
    justify-content: left;
  }
}
@media screen and (max-width: 992px) {
  .header-section-centre {
    justify-content: left;
  }
}

@media (min-width: 992px) {
  .header.introanim .header-link-blocks {
    -webkit-transition: transform 0.8s ease 0.5s;
    -o-transition: transform 0.8s ease 0.5s;
    transition: transform 0.8s ease 0.5s;
  }
  .header.introanim .brand {
    -webkit-transition: opacity 0.8s ease 0.5s;
    -o-transition: opacity 0.8s ease 0.5s;
    transition: opacity 0.8s ease 0.5s;
  }
  .header.introanim--before .header-link-blocks {
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    -o-transform: translate(100%, 0);
    transform: translate(100%, 0);
  }
  .header.introanim--before .brand {
    opacity: 0;
  }
}
.footer {
  color: #000;
  padding-top: 0;
}
.footer-decor {
  position: relative;
}
.footer-inner {
  position: relative;
  padding: 96px 0 64px;
}
@media (min-width: 992px) {
  .footer-inner {
    padding: 96px 0 64px;
  }
}
.footer-top {
  position: relative;
  padding-bottom: 48px;
}
@media (min-width: 1200px) {
  .footer-top {
    display: flex;
    justify-content: space-between;
  }
}
.footer-link {
  display: block;
  color: #0D7D2E;
  font-family: "acumin-pro", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
  font-size: 28px;
  font-weight: 100;
  line-height: 1.05;
  padding-top: 1rem;
  transition: color 0.3s ease;
}
@media (min-width: 768px) {
  .footer-link {
    font-size: 44px;
    font-family: "acumin-pro", "Helvetica", Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
    font-size: 100;
  }
}
.footer-link:hover {
  color: #14c248;
}
.footer-link:first-child {
  padding-top: 0;
}
.footer-subscribe {
  padding-top: 32px;
}
@media (min-width: 1200px) {
  .footer-subscribe {
    padding-left: 2rem;
  }
}
.footer-nav-container {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .footer-nav-container .nav {
    margin-bottom: 32px;
  }
}
@media (min-width: 768px) {
  .footer-nav-container .navlist {
    column-count: 3;
    column-gap: 30px;
  }
}
.footer-nav-container .navlist-title-link {
  display: block;
}
.footer-nav-container .navlist-text-title {
  font-weight: 300;
}
.footer-nav-container .navlist-section .navlist-text {
  font-size: 0.875rem;
  text-transform: uppercase;
}
@media (min-width: 992px) {
  .footer-nav-container .navlist-section .navlist-title {
    font-size: 16px;
  }
  .footer-nav-container .navlist-section .navlist-text {
    font-size: 16px;
  }
}
.footer-nav-container .navlist-item {
  margin-bottom: 8px;
}
.footer-nav-container .navlist-item .icon {
  font-size: 2rem;
}
.footer-nav-container .navlist-item--spaced {
  padding-top: 32px;
}
.footer-nav-container a {
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  font-weight: 300;
}
.footer-nav-container a:focus {
  text-decoration: none;
}
.footer-nav-container a:hover, .footer-nav-container a:active, .footer-nav-container a.active {
  color: #000;
  text-decoration: none;
}
.footer .nav.column .navlist {
  margin-bottom: 32px;
}
.footer .columns-footer .navlist {
  padding-top: 32px;
  border-top: 1px solid #0D7D2E;
}

.footer-callout {
  position: relative;
  top: -50%;
}

.legal {
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}
.legal a {
  font-size: 1rem;
  color: #000;
  text-decoration: none;
  -webkit-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  -o-transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  transition: color 0.5s ease, background 0.5s ease, opacity 0.5s ease;
  margin-top: 0.5rem;
}
.legal a:focus {
  text-decoration: none;
}
.legal a:hover, .legal a:active, .legal a.active {
  color: #0D7D2E;
  text-decoration: none;
}
@media (min-width: 768px) {
  .legal a {
    margin-top: 0;
  }
  .legal a:not(:first-of-type) {
    margin-left: 12px;
  }
}
.legal:not(first-of-type) {
  margin-top: 1rem;
}
.legal > .legal-row {
  display: flex;
  flex-direction: column;
}
.legal > .legal-row:last-of-type {
  padding-top: 1rem;
}
@media (min-width: 768px) {
  .legal > .legal-row {
    flex-direction: row;
  }
  .legal > .legal-row:last-of-type {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .legal {
    font-size: 1rem;
    flex-direction: row;
  }
}

.copyright {
  font-size: 1rem;
  font-weight: 600;
}

.page-sections {
  position: relative;
}
.page-sections-neg {
  position: relative;
  margin-top: -64px;
  z-index: 2;
}
@media (min-width: 992px) {
  .page-sections-neg {
    position: relative;
    margin-top: -128px;
    z-index: 2;
  }
}

.section {
  margin: 32px 0;
  position: relative;
}
.section--inactive {
  display: none;
}
.section--narrow {
  width: 50%;
}
.section--bg-faded {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #E9E9E9;
}
.section-nospacing {
  margin-top: 0;
  margin-bottom: 0;
}
.section-nospacing-bottom {
  margin-bottom: 0;
}
.section-nospacing-top {
  margin-top: 0;
}
.section__subsection-title {
  font-size: 1.375rem;
  margin-bottom: 16px;
}
.section__subsection-subtitle {
  margin-bottom: 16px;
}
.section--hero {
  margin: 32px 0 0;
}
.section--hero:last-of-type {
  margin-bottom: 32px;
}
@media (min-width: 992px) {
  .section {
    margin: 3.25em 0;
  }
  .section--bg-faded {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .section-nospacing {
    margin-top: 0;
    margin-bottom: 0;
  }
  .section-nospacing-bottom {
    margin-bottom: 0;
  }
  .section-nospacing-top {
    margin-top: 0;
  }
  .section--hero {
    margin: 64px 0 0;
  }
  .section--hero:last-of-type {
    margin-bottom: 64px;
  }
}
.section__subsection {
  margin: 0 0 32px;
}
.section__subsection:last-child {
  margin-bottom: 0;
}
.section__subsection--border-btm {
  border-bottom: 1px solid #0D7D2E;
  margin: 0 0 32px;
  padding: 0 0 32px;
}
.section__subsection--border-btm:last-child {
  border: none;
  padding-bottom: 0;
}
.section__subsection--margin-top {
  margin-top: 32px;
}
@media (min-width: 992px) {
  .section__subsection {
    margin: 0 0 64px;
  }
  .section__subsection:last-child {
    margin-bottom: 0;
  }
  .section__subsection--border-btm {
    padding: 0 0 64px;
  }
  .section__subsection--margin-top {
    margin-top: 64px;
  }
}
.section__subsection-buttons {
  margin: 32px 0 0;
}
@media (min-width: 992px) {
  .section__subsection-buttons {
    margin: 64px 0 0;
  }
}
.section-fade-in {
  overflow: hidden;
  opacity: 0;
  -webkit-transform: translate(0, 25%);
  -ms-transform: translate(0, 25%);
  -o-transform: translate(0, 25%);
  transform: translate(0, 25%);
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.section-fade-in .link-column-image:before,
.section-fade-in .link-column-image img {
  visibility: hidden;
  -webkit-transition: visibility 0.6s ease 2.1s;
  -o-transition: visibility 0.6s ease 2.1s;
  transition: visibility 0.6s ease 2.1s;
}
.section-fade-in .subsection-slidein {
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transform: translate(0, 40%);
  -ms-transform: translate(0, 40%);
  -o-transform: translate(0, 40%);
  transform: translate(0, 40%);
}
.section-fade-in .subsection-slidein.slidein-1 {
  -webkit-transition: transform 0.6s ease 0.3s, background 0.6s ease 2.1s;
  -o-transition: transform 0.6s ease 0.3s, background 0.6s ease 2.1s;
  transition: transform 0.6s ease 0.3s, background 0.6s ease 2.1s;
}
.section-fade-in .subsection-slidein.slidein-2 {
  -webkit-transition: transform 0.6s ease 0.9s, background 0.6s ease 2.1s;
  -o-transition: transform 0.6s ease 0.9s, background 0.6s ease 2.1s;
  transition: transform 0.6s ease 0.9s, background 0.6s ease 2.1s;
}
.section-fade-in .subsection-slidein.slidein-3 {
  -webkit-transition: transform 0.6s ease 1.5s, background 0.6s ease 2.1s;
  -o-transition: transform 0.6s ease 1.5s, background 0.6s ease 2.1s;
  transition: transform 0.6s ease 1.5s, background 0.6s ease 2.1s;
}
.section-fade-in.scroll-active {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.section-fade-in.scroll-active .link-column-image:before,
.section-fade-in.scroll-active .link-column-image img {
  visibility: visible;
}
.section-fade-in.scroll-active .subsection-slidein {
  background-color: transparent;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}
.section-block-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media screen and (max-width: 992px) {
  .section-block-wrapper {
    flex-direction: column;
  }
}
@media screen and (max-width: 992px) {
  .section .block-wrapper {
    margin-bottom: 32px;
  }
}

.inner-section {
  margin-bottom: 32px;
}
.inner-section-nospacing-bottom {
  margin-bottom: 0;
}

.area {
  padding-top: 64px;
}
.area-bordered {
  border-top: 1px solid #0D7D2E;
}
.area-block {
  margin-top: 64px;
}
.area-block:first-child {
  margin-top: 0;
}
.area + .area {
  margin-top: 64px;
}

.arrow-section {
  position: absolute;
  right: 0;
  top: 0;
}
@media screen and (max-width: 480px) {
  .arrow-section {
    position: relative;
    margin: 20px 0 40px;
  }
}

.component--bordered {
  padding-top: 48px;
  border-top: 1px solid #0D7D2E;
}
@media (min-width: 992px) {
  .component--limited {
    margin-left: 64px;
    margin-right: 64px;
  }
}
.component-title {
  font-size: 1.375rem;
  margin-bottom: 32px;
}
.component--accordion + .component--accordion .accordion__bar:last-of-type {
  border-bottom: 1px solid #215732;
}

.component:not(.component-inner) + .component {
  margin-top: 48px;
}

.component--accordion.component--accordion + .component--accordion {
  margin-top: -32px;
}

.experience-editor .header {
  display: none;
}
.experience-editor .component {
  padding: 20px;
  border: 2px green solid;
  margin-bottom: 10px;
}
.experience-editor .component > .tabs .tabs-section {
  padding-bottom: 16px;
}
.experience-editor .component.card:not(.hover-static):hover {
  background-color: white;
}
.experience-editor .card__listwrap {
  padding: 20px;
  border: 2px green solid;
}
.experience-editor .experience-section {
  padding: 20px;
  border: 2px brown solid;
}
.experience-editor .tabs {
  position: static;
}
.experience-editor .tabs-sections {
  position: static;
  max-height: initial;
}
.experience-editor .tabs-section {
  position: static;
  visibility: visible;
  opacity: 1;
  overflow: visible;
  height: auto;
  width: auto;
  z-index: 1;
  padding: 32px 0;
}
.experience-editor .tabs-section .component-title {
  display: block;
}
@media (min-width: 992px) {
  .experience-editor .tabs-section {
    padding: 64px 0;
  }
}
.experience-editor .accordion__item .component-title {
  display: block;
}
.experience-editor .accordion__inner {
  height: auto !important;
}
.experience-editor .experienceeditoronly {
  display: block;
}
.experience-editor div.scInsertionHandleCenter {
  height: 23px;
}
.experience-editor .hero img {
  max-width: 40%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  max-height: 100%;
  padding-bottom: 16px;
}
.experience-editor .hero .bg-hero-image {
  z-index: -1;
  height: 100%;
  min-width: 100%;
  background-size: cover;
  position: absolute;
  padding: 0;
}
.experience-editor .scEmptyPlaceholder {
  width: 100%;
}
.experience-editor .card .scLooseFrameZone {
  height: 100%;
}

.onenorthsearchdata--opened {
  overflow: hidden;
}

.onenorthsearchdata__panel * {
  font-size: initial;
  font-family: sans-serif !important;
  letter-spacing: initial;
}

.onenorthsearchdata__panel {
  /* need to perform a reset on all * underneath this */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  padding-right: 0;
  margin: 0;
  line-height: initial;
  font-size: initial;
  box-sizing: border-box;
  background: #FFF;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  z-index: 100000;
}

.onenorthsearchdata__panel--displayed {
  display: block !important;
}

.onenorthsearchdata__panel--hidden {
  display: none !important;
}

.onenorthsearchdata__content {
  color: #444;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  align-content: flex-start;
  height: 100%;
  overflow-y: auto;
}
.onenorthsearchdata__content pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.onenorthsearchdata__content--loading::before {
  content: "Loading...";
  display: block;
  text-align: center;
  font-size: 5em;
  font-weight: bold;
}

.onenorthsearchdata__closebutton {
  position: fixed;
  top: 0;
  right: 0;
  margin: 10px 10px 0 0;
  background: none;
  border: none;
  display: block;
  font-size: 2em;
}

.onenorthsearchdata__content > .onenorthsearchdata__block {
  display: inline-block;
  flex-grow: 1;
  flex-basis: 25%;
  min-width: 300px;
}

.onenorthsearchdata__content > onenorthsearchdata__block--full {
  text-align: center;
}

.onenorthsearchdata__content h4 {
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 1.5em;
}

.onenorthsearchdata__content h5 {
  font-size: 1.125em;
  font-weight: bold;
}

.onenorthsearchdata__content ul {
  list-style: none;
  padding-left: 12px;
}

.onenorthsearchdata__content ul li {
  margin-top: 8px;
}

.onenorthsearchdata__content ul li li {
  margin-top: 0px;
}

.onenorthsearchdata__content item::before {
  content: "[";
}

.onenorthsearchdata__content item::after {
  content: "] ";
}

.onenorthsearchdata__content small {
  font-size: 0.85em;
  color: #666;
}

.onenorthsearchdata__contactprogress {
  height: 16px;
  width: 100px;
  display: inline-block;
  box-sizing: border-box;
  background: #f5f5f5;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  overflow: hidden;
}

.onenorthsearchdata__contactprogress__inset {
  background-color: #dadada;
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  padding-left: 3px;
  overflow: visible;
}

.onenorthsearchdata__contactprogress__inset--max {
  background-color: #8def82;
}