.column {
  flex: 0 1 auto;
  width: 100%;

  &.column-vertical-centered {
    align-self: center;
  }
}

*[class^="columns-"] {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
}

.columns-2--centered{
    justify-content: center;
}

.columns-2 {
	> .column {
    @include min-width-md {
      width: 50%;
    }
  }
  > .column--wide {
    @include min-width-md {
      width: 100%;
    }
  }
}

.columns-2-sm-2 {
	> .column {
    @include min-width-xs {
      width: 50%;
    }
	}
}

.columns-3 {
	> .column {
    flex: 1 1 auto;

    @include min-width-md {
      width: 33.33%;
    }
	}
}

.columns-inline {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;

  &-break {
    flex-wrap: wrap;

    @include min-width-lg {
      flex-wrap: nowrap;
    }
  }

  > .column {
    width: auto;
    flex: 1 1 auto;
    &.column-static { flex: 0 0 auto; }
    &.column-5 {
      width: 5%;
    }
    &.column-10 {
      width: 10%;
    }
    &.column-50 {
      width: 50%;
    }
    
	}
}

.columns-footer {
  flex-flow: column nowrap;

  > .column {
    flex: 0 1 auto;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    
    &:last-child {
      width: 100%;
    }
  }

  .columns-all {
      flex-wrap: wrap;
  }

  @include min-width-xs {
    flex-flow: row wrap;

  > .column {
    flex: 0 1 auto;
    width: 50%;
    
    &:last-child {
      width: 100%;
    }
  }

  }

  @include min-width-md {
      
    flex-flow: row nowrap;
    justify-content: space-between;

    > .column {
      width: 15%;
      flex: 0 1 auto;
      margin-left: 0;
      margin-right: 2%;

      &:last-child {
        //flex: 1 0 auto;
        width: auto;
        min-width: 65%;
        margin-right: 0;
      }
    }
  }
}


.columns-2-30-70 {

	> .column {
    @include min-width-md {
      width: 30%;

      &:last-child {
        width: 70%;
      }
    }
	}
}

.columns-2-70-30 {
	> .column {
    @include min-width-md {
      width: 35%;

      &:first-child {
        width: 65%;
      }
    }
	}
}

.columns-all {
  
  @include min-width-sm {
    flex-wrap: nowrap;
  }

  > .column {
    flex: 1 50%;
    width: 50%;
    margin-bottom: $spacing;

    @include min-width-sm {
      flex: 1 100%;
      width: 100%;
    }
  }
}
