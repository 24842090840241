@use "sass:math";

@mixin borderBox {
  box-sizing: border-box;
}

@mixin display($display: block) {
    display: $display;

    @if ($display == inline-block and $ie_legacy_support) {
        *display: inline;
        zoom: 1;
    }
}

/// Helper mixin to size elements
/// @param {Number} $width - Width
/// @param {Number} $height ($width) - Height
@mixin squareSize($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin resetList {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

@mixin backgroundFull {
  background-size: cover;
  background-position: 10% 15%;
  background-color: transparent;
  background-repeat: no-repeat;
}

@mixin menuDynaIcon($color: black, $borderSize: 3px, $width: 27px) {

  &,
  &:before,
  &:after {
    display: block;
    position: absolute;
    left: 0;
    width: $width;
    height: $borderSize;
    min-height: 0;
    margin: 0 auto;
    content: '';
    background-color: inherit;
    @include transition(all 500ms ease-in-out);
  }

  & {
    position: relative;
    margin: ceil($borderSize * 4.5) auto;
    background-color: $color;
  }

  &:before { top: -($borderSize * 3); }

  &:after { top: ($borderSize * 3); }

  
}

@mixin xIcon($color: black, $width: 23px, $borderSize: 2px) {

  &,
  &:before,
  &:after {
    display: block;
    position: absolute;
    left: 0;
    width: $width;
    height: $borderSize;
    min-height: 0;
    margin: 0 auto;
    content: '';
    background-color: inherit;
  }

  & {
    position: relative;
    background-color: transparent;
    width: 0;
    margin: ($borderSize * 4.5) 0;
    top: -(floor(math.div($borderSize, 2)));
    left: 0;
    background-color: $color;
  }

  &:before,
  &:after {
    left: floor($borderSize * 7);
    @include transform-origin(left);
  }
  &:before {
    top: -($borderSize * 3.5);
    @include rotate(45deg);
  }
  &:after {
    top: $borderSize * 4;
    @include rotate(-45deg);
  }

}

@mixin fullheight() {
  min-height: 100%;
  height: 100%;
}

@mixin placeholderPrefix() {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}

@mixin linkColor($color: $link-color, $colorhover: false, $colortransition: false) {

    color: $color;
    text-decoration: none;
    @if $colortransition {
        @include transition($colortransition);
    } @else {
        @include transition($transition-interactive);
    }

    &:focus {
      text-decoration: $link-hover-decoration;
    }

    &:hover,
    &:active,
    &.active {
      @if $colorhover {
        color: $colorhover;
      } @else {
        color: $color-brand-green;
      }
      text-decoration: $link-hover-decoration;
    }
}

@mixin blockQuote($bg-color: $color-brand-light) {
  display: block;
  position: relative;
  padding: $spacing $spacing*3;
  margin-bottom: $spacing*3;
  color: $color-brand-dark;
  font-size: px-to-rem(18px);
  font-family: $font-family-regular;
  line-height: 1.855em;
  text-align: left;
  letter-spacing: 0;
  background-color: $bg-color;
  border-bottom: 0;  
}

@mixin pullQuote($bg-color: $color-brand-light) {
  border-top: .0625rem solid $color-gray-9;
  font-size: $font-size-xl;
  letter-spacing: .03em;
  line-height: 2.25em;
  text-align: center;
  margin-bottom: $spacing * 3;
  padding: $spacing * 2 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .blockquote-text {
    padding: 0 $spacing * 1.5;
  }

  > .attribution-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    &::before,
    &::after {
      content: '';
      flex-grow: 1;
      height: 1px;
      background-color: $color-gray-9;
    }

    .attribution {
      padding: math.div($spacing, 2);
      background-color: $bg-color;
      font-style: normal;
      text-transform: uppercase;
      font-size: $font-size-base;
      font-family: $font-family-regular;
      color: $color-gray-9;
      text-align: center;
      text-shadow: none;
      font-weight: $weight-light-standard;
      max-width: 50%;
    }
  }
}

@mixin letterSpacingCenter($l-space: .25em) {
  text-align: center;
  letter-spacing: $l-space;
  padding-left: $l-space;
}

@mixin letterSpacingReset() {
  text-align: left;
  padding-left: 0;
}