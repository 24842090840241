// Global
// -------------------------
.link-reset {
  text-decoration: none;
}

.interactive {
  cursor: pointer;

  &:focus {
    outline: $focus;
  }  
}

.focus-style {
  &:focus {
    outline: $focus;
  }
}


// Headings
// -------------------------
.title-style,
.header-style,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.title-style {
  text-transform: uppercase;
}

.subtitle-style {
  font-family: $headings-sub-font-family;
  font-style: italic;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  .small {
    font-weight: normal;
    line-height: 1;
  }
}

.h1,
.h2,
.h3 {
  margin-top: 0;
  margin-bottom: $type-bottom-spacing;

  .small {
    font-size: 65%;
  }
}
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: $type-bottom-spacing;

  .small {
    font-size: 75%;
  }
}

.h1 { font-size: $font-size-h1; }
.h2 { font-size: $font-size-h2; }
.h3 { font-size: $font-size-h3; }
.h4 { font-size: $font-size-h4; }
.h5 { font-size: $font-size-h5; }
.h6 { font-size: $font-size-h6; }


// Body text
// -------------------------

.lead {
  margin-bottom: $type-bottom-spacing;
  font-size: floor(($font-size-base * 1.15));
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    font-size: ($font-size-base * 1.5);
  }
}


// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
.small {
  font-size: $font-size-xs;
}

.mark {
  padding: .2em;
}

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }

// Transformation
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

.serif {
  font-family: $font-family-regular;
}

.sans-serif {
  font-family: $font-family-regular;
}