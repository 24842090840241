.insight-byline
{
    .byline-label
    {
        font-weight: bold;
    }

    .byline-link
    {
        color: $color-brand-green;
        &:hover {
            color:$color-brand-green--light;
        }
    }
        border-bottom: 1px solid $color-brand-forest-green;
}