.section-title {
    margin: 0 0 $spacing-med;
    color: $color-brand-dark;
    font-size: $font-size-large;

    &--dark {
        color: $color-brand-dark;
    }

    .title {
        font-size: $font-size-large;
        text-align: left;
        letter-spacing: 1px;
        line-height: 1.6em;

        &--has-action-button {
            text-align: left;

            @include min-width-lg {
                text-align: center;
            }
        }

        &--center {
            text-align: center;
        }

        &--bold {
            font-family: $font-family-semibold;
            font-weight: $weight-bold-standard;
        }

        &--small {
            font-size: $font-size-large;
        }

        @include min-width-sm {
            font-size: $font-size-evenlarger;
        }

        @include min-width-md {
            font-size: $font-size-xl;
        }
    }

    .subtitle {
        text-align: center;
        margin-top: $spacing;
        font-size: $font-size-small;
        @extend .subtitle-style;
    }

    &--hero {
        margin: 0 0 $spacing-med;

        &:after {
            content: "";
            display: block;
            margin: 25px auto 0;
            width:  100px;
            border-bottom: 1px solid $color-brand-chartreuse;
        }
    }

    &--has-action-button {
        position: relative;
        text-align: center;

        @media (min-width: 600px) {
            text-align: initial;
        }
    }

    .btn-position-right {
        margin-top: $spacing;
        margin-bottom: 2rem;

        @media (min-width: 600px) {
            margin-top: 0;

            position: absolute;
            right: 0;
            top: -50%;
        }
    }

    @include min-width-md {
        .title {
            font-size: $font-size-xxl;
            letter-spacing: 1px;
            line-height: normal;

            &--small {
                font-size: $font-size-large;
            }
        }

        .subtitle {
            font-size: $font-size-large;
        }

        &--hero {
            margin: 0 0 $spacing-large;
        }
    }
}
