@use "sass:math";

.masongrid {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;

    .accent-arrow-solo {
        top: 50%;
        @include translate(0, -50%);

        .icon {
            opacity: 0;
        }
    }

    &__decor {
        position: relative;
        height: $spacing * 6.5;
    }

    &__decor--short {
        height: $spacing;
        padding: 0 0 $spacing * 2;
    }

    &__decor-arrow {
        display: block;
    }

    &__item {
        flex-basis: 100%;
        margin: 0 0 $spacing;

        &--wide {
            .accent-arrow-solo {
                display: block;
            }

            .masongrid__decor-arrow {
                display: none;
            }
        }

        &--careers-path {
            min-height: $spacing * 14;
            margin: 0 0 $spacing * 2.5;
        }

        &--button-container {
            position: relative;
        }
    }

    &__link {
        width: 100%;
        height: 100%;
        display: block;
    }

    &__box {
        position: relative;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 3px solid $color-gray-D;
        @include backgroundFull;
        @include hoverReverse($color-brand-blue--dark);
        
        a {
            color: inherit;
        }

        &--inner {
            padding: $spacing * 1.5;
        }

        &--dark {
            @include hoverReverse($color-brand-light, $color-brand-light, $color-brand-blue--dark);
        }

        &--img {
            a {
                color: $color-brand-light;
            }
        }

        &:hover {
            .accent-arrow-solo {
                width: 20%;

                .icon {
                    opacity: 1;
                }
            }

            .masongrid__image {
                transform: scale(1.15);
            }
        }
    }

    &__title {
        font-size: $font-size-h4;

        &--center {
            text-align: center;
        }

        &--left {
            text-align: left;
        }

        .masongrid--large-title & {
            font-size: $font-size-xl;
        }
    }

    &__description {
        margin-top: $spacing * 1.5;
        font-size: $font-size-larger;
        font-weight: $weight-light-standard;

        .masongrid--large-description & {
            font-size: $font-size-xl;
        }
    }

    &__button {
        position: absolute;
        bottom: -#{$spacing * 1.5};

        &--center {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            width: 80%;

            a {
                width: 100%;
            }
        }

        .btn {
            white-space: initial;
        }
    }

    @include min-width-sm {
        &__item {
            flex-basis: calc(50% - #{math.div($spacing, 2)});
            max-width: calc(50% - #{math.div($spacing, 2)});
            margin-left: math.div($spacing, 4);
            margin-right: math.div($spacing, 4);

            &--wide {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }

    @include min-width-md {
        &__item {
            flex-basis: calc(33.33% - #{math.div($spacing, 2)});
            max-width: calc(33.33% - #{math.div($spacing, 2)});
            margin-left: math.div($spacing, 4);
            margin-right: math.div($spacing, 4);

            &--wide {
                flex-basis: calc(66.66% - #{math.div($spacing, 4)});
                max-width: calc(66.66% - #{math.div($spacing, 4)});
                margin-left: math.div($spacing, 8);
                margin-right: math.div($spacing, 8);
            }
        }
    }

    @include min-width-xxl {
        &__item {
            &--small {
                flex-basis: calc(25% - #{math.div($spacing, 2)});
                max-width: calc(25% - #{math.div($spacing, 2)});
                margin-left: math.div($spacing, 4);
                margin-right: math.div($spacing, 4);
            }
        }
    }

    &--center {
        justify-content: center;
    }

    &--left {
        justify-content: flex-start;
    }
}
