@use "sass:math";

.accordion {
    $accordion__spacing: $spacing $spacing-med;
    $accordion__spacing--mobile: $spacing math.div($spacing, 2);
    $accordion__spacing--right: $spacing-med * 1.25;
    position: relative;

    + .accordion {
        margin: $spacing-accordion-negative-margin 0 0;
        &:last-of-type{
            border-bottom: 1px solid $color-brand-forest-green;
        }
    }

    .accordion__item + .accordion__item {
        margin: $spacing-accordion-negative-margin 0 0;
        &:last-of-type{
            border-bottom: 1px solid $color-brand-forest-green;
        }
    }    

    &__close {
        @extend .interactive;
        position: absolute;
        left: 100%;
        top: calc(50% - #{$spacing});
        z-index: 100;
        display: none;
        padding: 0 ($spacing);

        &:before {
            position: relative;
            display: block;
            content: " ";
            width: 60%;
            margin: 0 auto math.div($spacing, 2);
            height: 1px;
            border-top: 1px solid $color-brand-primary;
        }

        &--active {
            display: block;
        }
    }

    &__bar {
        display: block;
        position: relative;
        flex: 1;
        width: 100%;
        margin: $spacing-med 0 0;
        padding: $accordion__spacing--mobile;
        padding-right: math.div($accordion__spacing--right, 1.7);
        text-align: left;
        border-top: 1px solid $color-brand-forest-green;
        background-color: $color-brand-light;
        color: $color-brand-primary;
        font-size: $font-size-h4;
        line-height: 1.1em;
        outline: none;
        cursor: initial;

        @include min-width-md {
            padding: $accordion__spacing;
            padding-right: $accordion__spacing--right;
        }

        &:focus {
            outline: none !important;
        }

        &:not(.accordion__bar--no-hover) {
            @extend .interactive;
            @include transition(background-color .3s ease, color .3s ease);
        }

        &:hover:not(.accordion__bar--no-hover),
        &--active {
            opacity: 1;
            border-color:$color-brand-secondary;
            background-color: $color-brand-secondary;
            outline: none;
        }

        &--active {
            color: $color-brand-light;
        }
    }

    &__section {
        padding: 0 0 $spacing-med;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__icon {
        position: absolute;
        right: math.div($accordion__spacing--right, 4.5);
        top: 50%;
        @include translate(0, -50%);
        @include icontoggle(block);
        text-align: center;

        @include min-width-md {
            right: math.div($accordion__spacing--right, 3);
        }

        .title {
            @extend .title-style;
            font-weight: $weight-light-standard;
            color: $color-brand-primary;
            font-size: $font-size-base;
        }

        &.active {
            .close.title {
                display: none;
            }
        }

        &.active.stuck {
            top: 0;
            right: math.div(-$spacing, 4);
            @include translate(100%, 0);
            @include transition(margin-top .3s ease);

            .scrolling-up & {
                margin-top: $spacing * 2.5;
            }

            .close.title {
                display: block;
                font-size: $font-size-xxxxs;
            }

            .close.icon {
                display: block;
                line-height: normal;
            }

            @include min-width-md {
                right: -$spacing;

                .close.title {
                    font-size: $font-size-base;
                }
            }
        }
    }

    &__title {
        line-height: 1.1;
        font-weight: $weight-light-standard;
        @extend .header-style;
    }

    &__item {
        background-color: $color-brand-light;
    }
    

    @keyframes activeAccordion {
        0% {overflow: hidden;}
        99% { overflow: hidden;}
        100% {overflow: visible;}
    }

    &__inner {
        @include transition(height .6s ease);

        &--closed {
            overflow: hidden;
        }

        &--active {
            animation: activeAccordion  1s ease-in-out;
            -webkit-animation: activeAccordion 1s ease-in-out;
            -ms-animation: activeAccordion 1s ease-in-out;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
        }

        &--light {
            background: $white;
        }

        @include max-width-sm {
            &--active {
                overflow:visible;
            }
        }
    }

    &__content {
        padding: $accordion__spacing--mobile;
    }

    @include min-width-md {
        &__content {
            padding: $accordion__spacing;
        }
        // &__bar {
        //   font-size: $font-size-xxxl;
        // }
        &__icon {
            right: ($spacing-med - 10px);
        }
    }

    .child-service-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding-top: 16px;
        //justify-content: center;
        &__item {
            display: inline-block;
            width: 100%;
            padding-right: 0px;
            margin-bottom: 19px;
            overflow: visible;
            &:after {
                content: '';
                display: block;
                position: relative;
                width: 47px;
                height: 1px;
                transition: width 0.3s ease-in-out;
                top: 4.5px;
                background-color: $color-brand-chartreuse;
            }
            &:hover {
                &:after {
                    width: 67px;
                }
            }
            @include min-width-md {
                width: 33.3333%;
                padding-right: 70px;
            }
        }

        &__heading {
            border-bottom: 1px solid $color-brand-green;
            color: $color-brand-green;
        }

        &__categorized:not(:first-child()) {
            padding-top: 1rem;
        }
    }
}

.accordion-collapse-buttons
{
    text-align-last: end;
    span{
        font-weight: bold;
        color: $color-brand-green;
        cursor: pointer;
    }

    .text-transform{
        display: inline-block;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }
}

noscript {
    + .accordion__item {
        margin: -.9*$spacing-med 0 0;
    }
}