.mobileshowhide {
  &__mobile {
    display: block;
  }
  &__desktop {
    display: none;
  }

  @include min-width-md {
    &__mobile {
      display: none;
    }
    &__desktop {
      display: block;
    }
  }
}