.experience-editor {
    .header {
        display: none;
    }

    .component {
        padding: 20px;
        border: 2px green solid;
        margin-bottom: 10px;

        > .tabs .tabs-section {
            padding-bottom: $spacing;
        }

        &.card:not(.hover-static):hover {
            background-color: white;
        }
    }

    .card__listwrap {
        padding: 20px;
        border: 2px green solid;
    }

    .experience-section {
        padding: 20px;
        border: 2px brown solid;
    }

    .tabs {
        position: static;

        &-sections {
            position: static;
            max-height: initial;
        }

        &-section {
            position: static;

            .component-title {
                display: block;
            }

            @include pseudoDisplayShow;
            padding: $spacing-med 0;

            @include min-width-md {
                padding: $spacing-large 0;
            }
        }
    }

    .accordion {
        &__item {
            .component-title {
                display: block;
            }
        }
        &__inner {
            height: auto !important;
        }
    }

    .experienceeditoronly {
        display: block;
    }

    div.scInsertionHandleCenter {
        height: 23px;
    }

    .hero {
        img {
            max-width: 40%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            max-height: 100%;
            padding-bottom: 16px;
        }

        .bg-hero-image{
            z-index: -1;
            height: 100%;
            min-width: 100%;
            background-size: cover;
            position: absolute;
            padding: 0;
        }
    }

    .scEmptyPlaceholder {
        width: 100%;
    }

    .card {
        .scLooseFrameZone {
            height: 100%;
        }
    }
}
