@use "sass:math";

.peoplelist {

    &-keycontacts {
        background: $gray-lighter;
        border: $gray-light 1px solid;
        margin: 1rem auto;
        padding-top: 1rem;

        @include min-width-md {
            max-width: 90%;
            padding: 1rem 1rem 0;
        }
    }

    &-item {
        margin-bottom: $spacing-med;

        @include min-width-md {
            padding-left: $spacing-med;
            padding-right: $spacing-med;

            &:nth-child(odd) {
                padding-left: 0;
            }

            &:nth-child(even) {
                padding-right: 0;
            }
        }
    }

    &-name {
        font-size: $font-size-xxl;
        margin-top: math.div($spacing, 2);
    }

    &-link-pro {
        display: block;

        img {
            @include transition(all .6s ease);
        }

        &:hover {
            .peoplelist-image {
                > img {
                    border-color: lighten($color-brand-dark, 65%);
                    @include box-shadow($shadow-light);
                }
            }
        }
    }

    &-image {
        border: 1px solid transparent;
        overflow: hidden;
        @include transition(box-shadow .3s ease, border-color .3s ease);

        > img {
            display: block;
            width: 100%;
        }
    }

    &-role {
        color: $color-gray-9;
        font-weight: $weight-light-standard;
    }

    &-meta {
        padding: $spacing 0 $spacing;
        border-top: 1px solid rgba($color-gray-9, 0.2);
    }

    .meta-block {
        color: $color-brand-primary-light;
        font-weight: $weight-light-standard;

        .meta-item {
            display: inline-block;
            font-family: $font-family-regular;

            &.phone {
                font-size: $font-size-large;
                &:after {
                    margin-right: 0;
                    margin-left: .3em;
                    content: "|";
                }
            }

            &.location {
                font-size: $font-size-large;
            }

            &:first-child {
                &:before {
                    margin-right: 0;
                    content:"";
                }
            }
        }
    }
}
