@use "sass:math";

.linklist {
    @include container-fixed($spacing-med);

    &__columns {
        //  @include make-row($spacing-med);
        margin-left: -$spacing;
        margin-right: -$spacing;
        columns: 2;
        -moz-columns: 2;

        @media screen and (max-width: $screen-md) {
            columns: 1;
            -moz-columns: 1;
        }
    }

    &__item {
        // @include make-lg-column(6, $spacing-med);
        width: 100%;
        display: inline-block;
        padding: 0 $spacing 5px;
        margin: 2px 0;
    }

    &__title {
        display: block;
        border-bottom: 1px solid #CCC;
        font-family: $font-family-semibold;
        font-weight: $weight-light-standard;
        padding: math.div($spacing, 1.5);

        &--big {
            font-size: $font-size-linklist-big;
            line-height: 2rem;
            padding: math.div($spacing, 2);

            @include min-width-md {
                line-height: 2.75rem;
            }
        }
    }
}
