.cookienotification {
    $spacing--cookie: $spacing * 1.5;
    position: fixed;
    z-index: 0;
    bottom: 0;
    left: 0;
    height: 0;
    width: 70%;
    overflow: hidden;
    opacity: 0;
    background-color: #ffffff;
    color: #000000;
    @include transition(bottom .6s ease-in, opacity .6s ease-in);
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    line-height: 22px;
    font-family: Cardo Regular;
    display: none;

    &--animate,
    &--active {
        height: auto;
        overflow: visible;
        z-index: #{$z-overlay - 1};
        display: block;
    }

    &--animate {
        opacity: 0;
    }

    &--active {
        opacity: 0.9;
        @include box-shadow(0 0 6px rgba(0,0,0, 0.5));
    }

    &--hide {
        display: none;
    }

    &__inner {
        text-align: center;
        padding-top: $spacing--cookie;
        padding-bottom: $spacing--cookie;
        margin: 0px #{$spacing--cookie * 1.5};
    }

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        & + & {
            margin-top: $spacing--cookie;
        }

        @include max-width-sm {
            flex-direction: column;
        }
    }

    &__description {
        font-size: $font-size-large;
        font-weight: $weight-light-standard;
        font-family: $font-family-regular;
        letter-spacing: .02em;
    }

    &__button {
        margin: 0 $spacing;
    }

    &__row-item {
        &:not(:first-child) {
            margin-left: 5px;

            @include max-width-sm {
                margin-left: 0;
                margin-top: $spacing--cookie;
            }
        }

        @include max-width-sm {
            width: 100%;
            overflow: hidden;
        }
    }
}
