.richtext {
    color: $black;
    font-family: $font-family-regular;
    line-height: $font-size-xxl-up;
    font-size: px-to-rem(18px);
    
    h1, h2, h3, h4, h5, h6 {
        @extend .header-style;
        line-height: 1.1;
        font-weight: 300;
        color: $black;
        margin-bottom: $spacing-paragraph;
    }

    h1 {
        font-size: $font-size-h1;
    }

    h2 {
        font-size: $font-size-h2;
    }

    h3 {
        font-size: $font-size-h3;
        font-family: $font-family-semibold;
        font-weight: $weight-bold-standard;
    }

    h4 {
        font-size: $font-size-h4;
        color: $color-brand-primary;
    }

    h5 {
        font-size: $font-size-h5;
        font-weight: $weight-bold-serif;
    }

    h6 {
        font-size: $font-size-h6;
    }

    p,
    ul,
    ol,
    blockquote,
    figure {
        margin-bottom: $spacing-paragraph * 2;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul {
        list-style: disc;
        margin-left: 2em;
    }

    ol {
        margin-left: 2em;
    }
    // Emphasis & misc
    // -------------------------
    // Ex: (12px small font / 14px base font) * 100% = about 85%
    small {
        font-size: $font-size-xs;
    }

    mark {
        padding: .2em;
    }
    // em {
    //   font-size: $font-size-large;
    //   line-height: px-to-rem(32px);
    // }
    // em p {
    //   margin-bottom: $spacing-paragraph * 2;
    // }
    li {
        padding-left: 0.5em;
        margin: 0.5em 0;
    }

    strong, b {
        font-weight: $weight-bold-serif;
    }

    em, i {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    a {
        @include linkColor($color-richtext-link, $color-richtext-link-hover, $transition-interactive-rich-text);

        &:hover,
        &:visited,
        &:active,
        &:focus {
            text-decoration: none;
        }

        &.link--blue {
            @include linkColor($color-brand-primary, $color-brand-primary-light);
        }

        &.link--orange {
            @include linkColor($color-brand-tertiary, $color-brand-tertiary-light, $transition-interactive-rich-text);
        }

        &.btn {
            @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
            @include button-size($padding-button-base-vertical, $padding-button-base-horizontal, $font-size-button);
        }
    }

    blockquote {
        @include blockQuote();
    }
    
    .blockquote {
        @include pullQuote();
    }

    .no-attribution {
        border-bottom: .0625rem solid $color-gray-9;
    }

    q {
        quotes: '"' '"' "'" "'";

        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }

    figure {
        margin-left: auto;
        margin-right: auto;
        display: block;

        img {
            margin-left: auto;
            margin-right: auto;
            display: inherit;
            width: 100%;
        }
    }

    dl {
        display: block;
        width: 100%;

        > div {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            margin-top: $spacing;
        }

        dt {
            flex: 0 0 auto;
            white-space: normal;
            width: 60%;
            padding: 0;
            margin-right: 5%;
        }

        dd {
            flex: 1 1 auto;
            padding: 0;
            white-space: normal;
        }
    }

    iframe,
    img {
        max-width: 100%;
    }

    img {
        height: auto;
    }
}
