.home-professional-search {
  background-color: $color-brand-primary;

  @include min-width-md {
    margin-bottom: 0;
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  &__inner {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  &__title {
    padding: 1rem 1rem 0;
    font-family: $font-family-semibold;
    font-size: $font-size-xxl;
    color: $color-brand-secondary;
    font-weight: $weight-bold-standard;

    @include min-width-md {
      padding: 2rem 1rem 0;
    }
  }

  .search-block {
    &--name-filters {
      background-color: transparent;
      box-shadow: none;
      padding-top: 1rem;

      @include min-width-md {
        padding-top: 0rem;
      }
    }
  }
  
  .jumbo-input {
    background-color: transparent;
    padding: 0rem 1rem;

    @include min-width-md {
      padding: 2rem 0.5rem;
    }

    .form-control {
      border: none;
      font-family: $font-family-regular;
      padding: 1rem 3rem 1rem 1rem;
      font-size: $font-size-large;
      font-weight: $weight-normal-standard;
      &:focus {
        background: $color-brand-light;

        @include placeholderPrefix {
          color: $color-brand-primary;
        }
      }
    }
  }

  .icon-search {
    color: $color-brand-secondary;
    font-size: $font-size-xl;
    @include transition(font-size .3s ease);
  }

  .submit-button {
    right: 1rem;
  }
}