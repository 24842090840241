@use "sass:math";

.link-block {
    display: inline-block;
    padding: math.div($spacing, 2);
    vertical-align: bottom;
    @extend .title-style;
    @extend .interactive;
    text-transform: uppercase;

    @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border, $btn-dark-bg, $btn-dark-color);
    border-left: 1px solid $color-gray-2;

    &:hover {
        background-color: $btn-dark-bg-hover;
    }

    > .icon {
        font-size: .9rem;
        line-height: 1.4rem;
        min-height: .15rem;
        text-align: center;

        @include min-width-md {
            font-size: px-to-rem(28px);
            line-height: 1.96rem;
            min-height: .25rem;
        }

        &.icon-menu {
            margin-bottom: 12px;
            background-color: $color-brand-light;

            @include min-width-md {
                margin-bottom: 18px;
            }
        }

        &.icon-x {
            font-size: 1.4rem;
            line-height: 1rem;
            margin-top: 0;

            @include min-width-md {
                font-size: px-to-rem(28px);
                line-height: px-to-rem(30px);
                margin-top: 0;
            }
        }

        &-scroll {
            font-size: px-to-rem(36px);
            margin-bottom: .15em;
        }
    }

    .title {
        margin-top: .25em;
        font-size: $font-size-mini;
        letter-spacing: .15em;

        @include min-width-md {
            font-size: $font-size-xxxxs;
        }
    }

    .icon,
    .title {
        color: $color-brand-light;
    }

    @include icontoggle(block);

    &-menu {
        position: relative;
        margin: 0;

        .icon {
            min-height: 0;
        }

        &.active {
            .title {
                margin-top: .25rem;
            }
        }
    }

    &-scroll {
        line-height: .8;
        @include box-shadow($shadow);

        .hero-section & {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: $z-floater;

            @include min-width-md {
                display: block;
            }
        }
    }

    &-more {
        min-width: 240px;
        padding: $spacing;
        border: none;

        .title {
            margin: 0;
            padding-right: $spacing * 4.5;
            display: inline-block;
            font-size: $font-size-small;
            @media screen and (max-width: $screen-sm) {
                font-size: $font-size-xs;
            }
        }

        .accent-arrow {
            position: absolute;
            right: $spacing * 2;
            top: 50%;
            border-color: $color-brand-light;
            @include translate(0, -50%);

            .icon {
                font-size: 28px;
                line-height: 15px;
            }
        }
        .icon-icon-see-all {
            display: inline-block;
            font-size: px-to-rem(14px);
            margin: 0 $spacing;
            line-height: 1;

            @media screen and (max-width: $screen-sm) {
                font-size: px-to-rem(12px);
            }
            + .title {
                padding-right: 0;
            }
        }

        .hero-section & {
            position: relative;
            margin-top: $spacing * 2;

            @media screen and (max-width: $screen-sm) {
                margin-top: 0;                
            }
        }

        &--fixed {
            .hero-section & {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: $z-floater;
                text-align: right;

                @media screen and (max-width: $screen-sm) {
                    width: calc(49.3%) !important;
                    min-width: 0;
                    font-size: px-to-rem(12px);
                    display: inline-block;
                }
                @include min-width-md {
                    right: -2.25rem;
                }
            }
            &.left {
                .hero-section & {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: inherit;
                    z-index: $z-floater;
                    text-align: left;

                    @media screen and (max-width: $screen-sm) {
                        position: relative;
                        @include translate(0, 0);
                        margin-top: 0;
                    }
                }    
            }
        }
    }
}
