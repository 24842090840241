// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant($color, $background, $border, $hoverbg: false, $colorhover: false) {

  &,
  &:visited {
    background-color: $background;
    border-color: $border;
  }

  @if $colorhover {
    @include linkColor($color, $colorhover);
  } @else {
    @include linkColor($color, $background);
  }

  &,
  &:visited,
  &:focus,
  &.focus {
    background-color: $background;
    border-color: $border;
  }

  &:hover,
  &:active,
  &.active  {
    background-color: $hoverbg;
    border-color: $hoverbg;
    .accent-arrow {
      border-color: $colorhover;
    }
  }

  &.disabled,
  &[disabled] {
    color: $btn-disabled-color;
    background-color: $btn-disabled-bg;
        border-color: $btn-disabled-border;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      color: $btn-disabled-color;
      background-color: $btn-disabled-bg;
          border-color: $btn-disabled-border;
    }
  }

  .badge {
    color: $background;
    background-color: $color;
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height: 1.4, $border-radius: 0) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
