@use "sass:math";
// THEME COLORS
// --------------------------------------------------
$base-colors: (
    dark: rgb(30, 30, 30),
    medium: rgb(160, 160, 160),
    light: rgb(242, 242, 242),
    black: rgb(0, 0, 0),
    white: rgb(255, 255, 255),
    blue: rgb(41, 63, 84),
    lightblue:rgb(90, 149, 229)
);

// Vertical Rhythm
// --------------------------------------------------
$spacing-paragraph: $spacing;
$spacing-column: $spacing;

$spacing-small: math.div($spacing, 2);
$spacing-small-plus: math.div($spacing, 1.5);
$spacing-med: $spacing*2;
$spacing-xmed: $spacing*3;
$spacing-large: $spacing*4;
$spacing-xlarge: $spacing*6;
$spacing-xxlarge: $spacing*7;
$spacing-xxxlarge: $spacing*13;

$spacing-hero-push-sm: $spacing-large;
$spacing-hero-push: $spacing-xlarge;

$spacing-footer-callout: $spacing-xlarge;
$spacing-footer-callout-tall: $spacing*8;
$spacing-footer-callout-tall-homepage: $spacing*13;

$padding-small-vertical: $spacing-small;
$padding-small-horizontal: $spacing-small;
$padding-large-vertical: $spacing-med;
$padding-large-horizontal: $spacing-med;

$spacing-hero: $spacing;

$spacing-component: $spacing-xmed;

$spacing-accordion-negative-margin: -$spacing-med;

//** Link hover decoration.
$link-hover-decoration: none !default;


// Typography
// --------------------------------------------------

// FAMILY
// --------------------------------------------------

//helvetica is temporary - will be aileron once hooked up


$font-family-light: "acumin-pro", 'Helvetica', Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
$font-family-regular: "acumin-pro-regular", 'Helvetica', Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
$font-family-semibold: "acumin-pro-semibold", 'Helvetica', Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
$font-family-ultralight: "acumin-pro", 'Helvetica', Arial, NSimSun, 新宋体, STHeiti, 华文黑体, sans-serif;
$font-family-base:        $font-family-regular !default;


// FONT SIZE
// --------------------------------------------------
$font-size-xxxl:          px-to-rem(34px) !default;
$font-size-xxl-up:        px-to-rem(30px) !default;
$font-size-xxl:           px-to-rem(28px) !default;
$font-size-xl:            px-to-rem(24px) !default;
$font-size-evenlarger:    px-to-rem(22px) !default;
$font-size-larger:        px-to-rem(20px) !default;
$font-size-large:         px-to-rem(18px) !default;

$font-size-med:           px-to-rem(16px) !default;
$font-size-small:         px-to-rem(14px) !default;
$font-size-smaller:       px-to-rem(13px) !default;
$font-size-xs:            px-to-rem(12px) !default;
$font-size-xxs:           px-to-rem(11px) !default;
$font-size-xxxs:          px-to-rem(10px) !default;
$font-size-xxxxs:         px-to-rem(9px) !default;
$font-size-mini:          px-to-rem(8px) !default;
$font-size-xmini:         px-to-rem(7px) !default;

$font-size-h1:            px-to-rem(45px) !default;
$font-size-h2:            px-to-rem(32px) !default;
$font-size-h3:            px-to-rem(20px) !default;
$font-size-h4:            px-to-rem(18px) !default;
$font-size-h5:            px-to-rem(16px) !default;
$font-size-h6:            $font-size-base !default; //16px

$font-size-button:        $font-size-base !default;
$font-size-button-lg:     $font-size-larger !default;
$font-size-button-sm:     $font-size-small !default;
$font-size-button-xs:     $font-size-xxs !default;

$font-size-linklist-big: px-to-rem(32px) !default;

$font-size-jumbo:         px-to-rem(125px) !default;
$font-size-jumbo-md:      px-to-rem(100px) !default;
$font-size-jumbo-sm:      px-to-rem(80px) !default;
$font-size-jumbo-xs:        px-to-rem(50px) !default;
$font-size-jumbo-tiny:      px-to-rem(32px) !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

$type-bottom-spacing: math.div($line-height-computed, 1.5);

$line-height-large: 1.6 !default;;
$line-height-small: 1.1 !default;

$weight-bold-serif:       700;
$weight-bold-standard:       600;
$weight-light-standard:       300;
$weight-normal-standard:       400;

$headings-font-family:    $font-family-semibold;
$headings-font-weight:    $weight-normal-standard !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;

$headings-sub-font-family:    $font-family-semibold !default;

//Basic inputs / button-size
// --------------------------------------------------
$input-height-base: 48px;
$padding-base-vertical: .8em;
$padding-base-horizontal: .8em;

$padding-button-base-vertical: .8em;
$padding-button-base-horizontal: 4em;

$input-height-small: 38px;
$input-height-large: 58px;

$form-group-margin-bottom: $spacing;

// Grid system
// --------------------------------------------------
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         $spacing-large !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


// Container sizes
// --------------------------------------------------
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;

// Larger screen / wide desktop
$container-xl-desktop:      (1306px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-xl:                 $container-xl-desktop !default;

// Even Larger screen / wide desktop
$container-xxl-desktop:      (1860px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-xxl:                 $container-xxl-desktop !default;


// Buttons
// --------------------------------------------------
//## For each of Bootstrap's buttons, define text, background and border color.
$btn-font-weight:     normal !default;

// Z Axis
// --------------------------------------------------

$z-bg-overlay:        0;
$z-reset:             1;
$z-content:           2;
$z-floater:           3;
$z-site-shell:        99;
$z-site-shell-top:    $z-site-shell - 1;
$z-overlay:           $z-site-shell-top - 2;
$z-site-shell-bottom: $z-overlay - 1;
$z-site-main:         $z-site-shell-bottom + 1;


// GLOBAL Style Configs
// --------------------------------------------------
//Border
$border-default: 1px solid $color-brand-green;

//Focus
$focus: thin solid $color-gray-9;

//Box shadow

$shadow: 0 0 6px rgba(0,0,0,0.5);
$shadow-light: 0 0 6px rgba(0,0,0,0.2);


//Cursor
$cursor-disabled: cursor-disabled;


//transition

$transition-interactive: color .5s ease, background .5s ease, opacity .5s ease;
$transition-interactive-rich-text: color .35s ease, background .35s ease, border-color .35s ease, opacity .35s ease;
