.navlist {

  &.inline {
    .navlist-item {
      display: inline-block;
      margin-left: .25em;
      margin-bottom: 0;

      &:first-child {
        margin-left: auto;
      }
    }
  }

  &__button {
    padding: 0;
    color: $color-brand-light;

    &.active,
    &:hover {
      color: $color-gray-95;
      font-weight: $weight-bold-standard;
    }
  }

  .navlist-fillspace &,
  &.navlist-fillspace {

    .navlist-item {
      display: block;
      width: 100%;
      vertical-align: text-top;

      &:nth-child(odd) {
        margin-left: auto;
      }

      @include min-width-md {
        display: block;
        width: auto;
        margin-left: auto;
      }
    }
  }
}

.navlist-title {
  @extend .title-style;
  margin-bottom: $spacing * 2;
  
  font-weight: $weight-normal-standard;

  .social-legal & {
    font-weight: $weight-light-standard;
    text-transform: capitalize;
    margin-bottom: $spacing;
  }
}

.navlist-text {
  font-family: $font-family-semibold;

  &:hover {
    color: $color-brand-green;
    .navlist-text-title
    {
      color: $color-brand-green--light;
    }
  }
}
