.trending-topics {
  color: $color-brand-light;

  .section-title {
    color: $color-brand-light;

    &:after {
      border-color: $color-brand-chartreuse;
    }
  }

  &__decor {
    background-size: cover;
    padding: 2rem 0;
  }

  &__block {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;
  }

  &__content {
    background: rgba(0,0,0,0.4);
    padding: 2rem 1rem;
  }

  &__tag {
    font-family: $font-family-semibold;
    font-size: $font-size-med;
    font-weight: $weight-bold-standard;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
  }

  &__title {
    font-size: $font-size-linklist-big;
    line-height: 1.1;
    margin-bottom: 0.75rem;
    font-weight: 100;
  }

  &__blurb {
    font-size: $font-size-large;
    margin-bottom: 0.75rem;
  }

  &__button {
    a {
      color: $color-brand-chartreuse;
    }
  }

  @include min-width-md {
    margin-bottom: 0;
    @include container-fixed(72px);

    &__decor {
      padding: 3rem 0;
    }

    &__block {
      display: flex;

      &:first-child {
        flex-direction: row-reverse;

        .trending-topics {
          &__content {
            margin-top: 3rem;
            margin-right: -6.5rem;
            padding-right: 9.5rem;
          }
        }
      }

      &:nth-child(2) {
        align-items: flex-end;
        
        .trending-topics {
          &__content {
            margin-bottom: 3rem;
            margin-left: -6.5rem;
            padding-left: 9.5rem;
          }
        }
      }
    }

    &__content {
      padding: 2rem 3rem;
      max-width: 700px;
    }

    &__tag {
      color: $color-brand-secondary;
      font-size: $font-size-large;
    }

    &__title {
      font-size: px-to-rem(40px);
    }
  }
}