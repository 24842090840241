@use "sass:math";

.search {
    &--fade-in {
        transition: visibility 0.3s ease, opacity 0.3s ease;
    }

    &__results {
        display: none;

        &--active {
            display: block;
        }
    }

    &__placeholder {
        display: block;

        &--active {
            display: none;
        }
    }

    &-block__title {
        position: relative;
        margin: $spacing-large 0 $spacing-med;
    }

    &__keywordlist {
        position: relative;
        margin: $spacing-med 0 $spacing;
        text-align: center;
        display: none;

        &--active {
            display: block;
        }
    }

    &__keyword {
        padding: math.div($spacing, 2) $spacing math.div($spacing, 2) math.div($spacing, 2);
        font-size: $font-size-button;
        font-family: $font-family-regular;
        font-weight: $weight-light-standard;
        @include linkColor($color-brand-primary);
        border: solid 1px $color-brand-green;
        border-radius: 20px;
        margin-right: 5px;
        .icon {
            font-size: 1rem;
            position: relative;
            top: 2px;
            padding-right: 6px;
        }
        &.clear-all {
            background-color: $color-gray-D;
            border: none;
        }
    }

    &__sections {
        float: right;
        margin-top: 1rem;
        font-weight: bold;
        color: $white;
        font-family: $font-family-regular;
        font-size: $font-size-button-sm;
        letter-spacing: 1.5px;

        .section-option{
            font-weight: normal;
            color: $white;
            font-family: $font-family-regular;
            font-size: $font-size-button-sm;
            &:hover, &--active {
                color: $color-brand-secondary;
            }
        }

        &--hidden {
            visibility: hidden;
        }
    }

    &__share {
        position: relative;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0 auto;
        @extend .title-style;
        font-size: $font-size-small;
        cursor: pointer;
        @include linkColor($color-brand-primary, $color-brand-primary-light);

        .title + & {
            margin-top: 1em;
        }

        @include min-width-md {
            position: absolute;
            top: -#{$spacing};
            right: 0;  

            &,
            .title + & {
                margin: 0;
            }
        }
    }

    &__resultstitle {
        position: relative;
        margin: 40px 0 $spacing-med;
        color: $color-gray-6;
        text-align: center;
        text-transform: uppercase;

        .title {
            @extend .header-style;
            font-size: $font-size-small;
            @include letterSpacingCenter(.25em);
            line-height: 1.6em;
            text-transform: uppercase;        
        }
        &.no-results {
            .title {
                color: $black;
                font-size: $font-size-xxl;
                text-transform: none; 
                font-family: $font-family-semibold;    
                font-weight: $weight-bold-standard;
                @include letterSpacingCenter(0);
            }
            .sub-title {
                font-size: $font-size-large;
                text-transform: none; 

            }
        }

        @include min-width-md {
            .title {
                font-size: $font-size-h4;
            }
        }

        &--site-search {
            &:after {
                content: ' ';
                clear: both;
                display: table;
            }

            .title {
                &--left {
                    float: left;
                }

                &--right {
                    float: right;
                }
            }
        }
    }

    .tabs-list-container {

        .tabs-list {
            flex-flow: row;
            overflow: visible;
            position: relative;
            max-height: none;

            .tabs-list-item {
                width: auto;
                height: fit-content;
                margin: 0;
                padding: 0;
                font-size: 1.25rem;
                display: inline-block;
                padding-bottom: .5rem;
                background-color: transparent;
                border: none;
                flex: none;
                margin-right: 2rem;
                margin-bottom: 1rem;
                color: $color-gray-D;
                &:hover {
                    color: $color-brand-secondary;
                    border-bottom: .25rem solid;
                    border-color: $color-brand-secondary;
                }
            }
            
            .active-button {
                color: $color-brand-light;
                border-bottom: .25rem solid;
                border-color: $color-brand-green--light;
            }
        }
    }

    .tabs-sections {
        position: absolute;
    }

    .active-section {
        padding: 0;
        width: 100%;
        visibility: visible;
        height: auto;
        z-index: 1;
        overflow: visible;
        opacity: 1;
    }
}
