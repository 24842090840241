@use "sass:math";

.brand {

  width: 98px;

  @include min-width-md {
    width: auto;
  }

  .brand-title {
    position: absolute;
    left: -100%;
    top: -100%;
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .brand-logo {
    display: block;
    transform: translate3d(0, 0, 0);

    .scrolling-up &,
    .brand-secondary & {
      display: none;
    }

    &-secondary,
    .brand-secondary & {
      display: none;

      .scrolling-up & {
        display: none;
      }
    }
  }

  &.brand-secondary {
    .brand-logo {
      display: none;

      .scrolling-up & {
        display: block;
      }

      &-secondary {
        display: block;

        .scrolling-up & {
          display: none;
        }
      }
    }
  }

  .scrolling-up & {
    width: 100px;
    margin-top: math.div($spacing, 10);

    @include min-width-md {
      margin-top: math.div(-$spacing, 2);
    }

    .brand-logo {
      display: block;

      &-secondary {
        display: none;
      }
    }
  }
}
