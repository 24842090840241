.buttons {
  padding: $spacing 0;

  @include min-width-md {
    padding: $spacing-med 0;
  }

  &-form-submit {
    padding: px-to-rem(14px) $spacing-xmed;
    border: 1px solid $color-brand-primary;
    background: $white;
    color: $color-brand-primary;
    font-size: $font-size-small;
    font-family: $font-family-regular;
    font-weight: 400;
    transition: $transition-interactive;

    &:hover {
      background: $color-brand-primary;
      color: $white;
    }

    @media screen and (max-width: $screen-xs) {
      width: 100%;
    }
  }
}
