@use "sass:math";

.tile-list {
    @extend .reset-list;
    margin: 0;
    border-left: 1px solid $color-brand-dark;
    position: relative;

    .tile {
        
        margin: 0;
        
        border-right: 1px solid $color-brand-dark;
        border-top: 1px solid $color-brand-dark;
        border-bottom: 1px solid $color-brand-dark;
        margin-top: -1px; // this collapses the border-top and border-bottom of adjacent cells

    }
}

@include min-width-md {
    .columns-3.tile-list {
        border-top: 1px solid $color-brand-dark;

        .tile {
            &:nth-child(1),
            &:not(.column--wide):nth-child(2) {
                border-top: 1px solid $color-brand-dark;
            }
        }
    }
}

.tile {
    overflow: hidden;
    position: relative;
    display: block;
    min-height: 140px;
    margin: 0;

    @include hoverReverse($color-brand-blue--dark);

    @include min-width-xs {
        min-height: 240px;
    }

    @include min-width-md {
        display: flex;
        flex-flow: row nowrap;
        min-height: 340px;
    }

    a {
        color: inherit;
        width: 100%;
    }

    &.dark {
        background-color: $color-brand-dark;
        color: $color-brand-light;

        .tile-date,
        .tile-time,
        .category-list-item {
            color: inherit;
        }
    }

    > &-link {
        display: block;
    }

    &:not(.hover-static):hover {
        .tile-date,
        .tile-time,
        .category-list-item {
            color: inherit;
        }
    }


    &-date,
    &-time {
        color: $color-gray-9;
        font-family: $font-family-regular;
        font-weight: $weight-light-standard;
    }

    .accent-arrow-solo {
        top: calc(100% - 2px);

        .icon {
            opacity: 0;
        }
    }

    &:hover {
        .accent-arrow-solo {
            width: 20%;

            .icon {
                opacity: 1;
            }
        }

        .tile-image {
            transform: scale(1.15);
        }
    }

    &__address-row {
        font-size: $font-size-large;
        line-height: 1.4em;
        font-weight: $weight-light-standard;
    }

    &__address-title {
        margin-bottom: $spacing;
        font-size: $font-size-xxl;
        font-weight: $weight-bold-standard;

        @include min-width-md {
            min-height: 1.5em;
        }
    }
}

.tile-details {
    position: relative;
    z-index: $z-content;
    padding: $spacing 0;
    font-family: $font-family-regular;
    font-size: $font-size-evenlarger;
    font-weight: $weight-light-standard;

    @include min-width-md {
        font-size: $font-size-xxl;
    }
}

.tile-image {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-bg-overlay;
    width: 100%;
    height: 100%;
    @extend .background-full;
    @extend .blackout-image-overlay;
    @include transition(all .6s ease);

    &--static-right {
        background-position: right center;
        background-size: auto;

        &:before {
            content: '';
            display: none;
        }
    }
}

.tile-inner {
    position: static;
    z-index: $z-bg-overlay + 1;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    flex-direction: row;
    margin: 0 $spacing;
    padding: math.div($spacing, 2) 0;
    width: calc(100% - #{$spacing});

    + .tile-inner {
        margin-top: math.div($spacing, 2);
    }

    @include min-width-sm {
        padding: $spacing 0;
        margin: 0 ($spacing * 2);
        width: calc(100% - #{$spacing * 4});
    }

    @include min-width-md {
        padding: ($spacing * 2) 0;
        margin-left: ($spacing * 2);

        + .tile-inner {
            margin-top: 0;
        }
    }

    .column--wide & {
        height: 50%;

        @include min-width-md {
            height: 100%;
        }
    }

    .tile-row {
        position: relative;
        @extend .clearfix;
        width: 100%;
        padding: #{math.div($spacing, 4)} 0;
        z-index: 1;

        @include min-width-xs {
            padding: #{math.div($spacing, 2)} 0;
        }

        @include min-width-sm {
            padding: $spacing 0;
        }

        &-last {
            margin-top: auto;
            align-self: flex-end;
        }

        &__title {
            position: relative;
            z-index: 1;
            width: 100%;
            @extend .title-style;
            letter-spacing: .15em;
            font-size: $font-size-evenlarger;
        }
    }

    > * {
        flex: 0 1 auto;
    }
}
