.header-menu {
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: -1;
  @include translate(100%, 0);

  padding: ($spacing * 1.25) ($spacing * 2) ($spacing * 3);
  @include transition(transform .4s ease);

  @include min-width-sm {
    padding: ($spacing * 3) ($spacing * 4) ($spacing * 3);
  }

  &.active {
    width: 100%;
    @include translate(0, 0);
    z-index: $z-bg-overlay;

    @include min-width-sm {
      width: 60%;
    }

    @include min-width-md {
      width: 50%;
      width: 50vw;
    }
  }
}

.header-menu-nav {
    &.external {
        margin-bottom: $spacing * 4;
        //force words to wrap on smaller screens only
        //input height * 3 to account for 3 buttons in menu
        @media (max-width: $screen-xs) {
            max-width: calc(90% - (#{$input-height-base} * 3));
        }

        @include min-width-sm {
            margin-bottom: $spacing * 6;
            font-size: $font-size-small;
        }

        @include min-width-lg {
            margin-bottom: $spacing * 8;
            font-size: $font-size-base;
        }

        &:after {
            @include icon(external-link);
            content: "\e914";
            margin-left: px-to-rem(4px);
            color: $white;
        }

        .nav-item {
            @extend .title-style;
            letter-spacing: .3em;
            font-weight: $weight-light-standard;

            @include linkColor($color-brand-light, $color-brand-light);
        }
    }


    .navlist-item {
        @extend .title-style;
        letter-spacing: .3em;
        font-size: $font-size-h4;
        margin-top: $spacing * 3;

        @include min-width-sm {
            font-size: $font-size-h4;
            margin-top: $spacing * 3;
        }

        @include min-width-md {
            font-size: $font-size-xxl-up;
            margin-top: $spacing * 3;
        }
    }

    .navlist-text {
        position: relative;
        @include linkColor($color-brand-light, $color-brand-light);

        &:after {
            position: absolute;
            top: 100%;
            margin-top: .25em;
            content: ' ';
            display: block;
            width: 100%;
            height: 3px;
            background-color: transparent;
            @include transition(background-color .6s ease)
        }

        &:hover {
            &:after {
                background-color: $color-brand-green--light;
            }
        }
    }

    &.secondary {
        .navlist-text-title {
            font-weight: $weight-light-standard;
        }
    }
}
