.authors {
    width: 100%;

    &-list {
        display: flex;
        flex-direction: column;
        margin-top: $spacing-xmed;
    }

    &-module {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: $spacing 0 $spacing;

        @media screen and (max-width: $screen-xs) {
            flex-direction: column;
            margin-bottom: $spacing-xmed;
        }

        .authors-link {
            order: 0;
            flex-shrink: 0;

            @media screen and (max-width: $screen-sm) {
                flex-shrink: 1;
            }
        }

        .authors-meta {
            order: 1;
        }
    }

    &-link {
        .peoplelist-image {
            max-width: 400px;

            > img {
                border: 1px solid transparent;
                @include transition(border-color .3s ease);
            }
        }

        &:hover {
            .peoplelist-image {
                > img {
                    border-color: lighten($color-brand-dark, 65%);
                    @include box-shadow($shadow-light);
                }
            }
        }
    }

    &-name {
        font-size: $font-size-xxl;
        width: 100%;
        font-weight: $weight-normal-standard;
        margin-bottom: $spacing;

        &--small {
            font-size: $font-size-evenlarger;
        }

        @media screen and (max-width: $screen-xs) {
            margin-bottom: 0;
        }
    }

    &-role {
        color: $color-gray-9;
        font-weight: $weight-light-standard;
        margin-bottom: $spacing;
    }

    &-meta {
        width: 100%;
        padding-left: $spacing-xmed;
        font-weight: $weight-light-standard;
        display: flex;
        flex-wrap: wrap;

        .meta-block-titles {
            color: $color-brand-primary-light;
            font-weight: $weight-light-standard;
            border-bottom: 1px solid rgba($color-gray-9, 0.2);
            margin-bottom: $spacing;
            flex-basis: 100%;
        }

        .meta-block-column {
            flex-basis: 50%;
            margin-bottom: 1em;

            .meta-block {
                flex-basis: 100%;
            }
        }

        @media screen and (max-width: $screen-md) {
            padding-top: 0;
            padding-bottom: 0;
        }

        @media screen and (max-width: $screen-sm) {
            padding-left: $spacing-med;
        }

        @media screen and (max-width: $screen-xs) {
            padding-left: 0;
        }

        @include min-width-sm {
            .meta-block-column {
                display: block;
                justify-content: flex-start;
                flex-flow: row wrap;
            }
        }
    }
}
