.page {
  &-limit {
    @include container-flexy;

    &-narrow {
      @include container-flexy(1.25);
    }
  }
  &--menuactive {
    overflow: hidden !important;
  }
}

.inner-limit {
  margin-left: auto;
  margin-right: auto;
  @include min-width-md  {
    max-width: 90%;
  }
}

.reduced-inner-limit {
  @include min-width-md  {
    padding-left: $spacing-large;
    padding-right: $spacing-large;
  }
}

.body-wrapper {
  @include clearfix();
}

.header,
.footer,
.main-content {
  position: relative;

  //all general inner divs for main areas
  .inner {
    position: relative;
    @include clearfix();
    z-index: $z-content;
  }
}

.header {
  z-index: $z-site-shell-top;
}

.footer {
  z-index: $z-site-shell-bottom;
}

.main-content {
  position: static;
  z-index: $z-site-main;

  .inner {
    position: static;
  }
}

.ga-article-category{
    display: none;
}
