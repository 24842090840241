// THEME COLORS
// --------------------------------------------------
// $base-colors: (
//     dark: rgb(30, 30, 30),
//     medium: rgb(160, 160, 160),
//     light: rgb(242, 242, 242),
//     black: rgb(0, 0, 0),
//     white: rgb(255, 255, 255),
//     blue: rgb(41, 63, 84),
//     lightblue:rgb(90, 149, 229)
// );

// COLORS
// --------------------------------------------------
$white:                  #FFF;
$black:                  #000;
$gray-base:              $black !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$color-gray-2: #272727;
$color-gray-6: #666666;
$color-gray-5: #545454; 
$color-gray-8: #848484;
$color-gray-9: #929292;

$color-gray-95:   #959798;
$color-gray-A: #a1a1a1;
$color-gray-D: #D2D2D2;
$color-gray-D--light: #D8D8D8;
$color-gray-E: #E9E9E9;

$color-brand-light: $white;
$color-brand-dark: $black;

$color-brand-primary:     $color-brand-dark;
$color-brand-primary-light: #004163;
$color-brand-primary-pale: #F8F8F8;
$color-brand-primary-dark: #0E1841;
$color-brand-secondary:   #00B140;


$color-brand-secondary-light: lighten($color-brand-secondary, 15%);
$color-brand-secondary-dark: #A9ED77;
$color-brand-tertiary: #C7442E;
$color-brand-tertiary-light: #C37C00;
$color-brand-tertiary-blue: #D5E7FA;

$color-brand-green: #0D7D2E;
$color-brand-green--light: #00B140;
$color-brand-blue--dark: #003057;
$color-brand-ecru: #D1CCBD;
$color-brand-plum: #722257;
$color-brand-chartreuse: #97D700;
$color-brand-forest-green: #215732;

$color-brand-success:         #5cb85c !default;
$color-brand-info:            #5bc0de !default;
$color-brand-warning:         #f0ad4e !default;
$color-brand-danger:          #d9534f !default;

$text-color: $black;
$body-bg: $white;
$color-tan: #9B9B93;
$color-tan-dark: #DED8C4;;

$link-color: $color-brand-primary;
$link-color-hover: $black;

$color-richtext-link: #0067a0;
$color-richtext-link-hover: lighten($color-richtext-link, 15%);

$green-gradient: linear-gradient(217.9deg, #00B140 0%, #000000 98.92%);

// Inputs
// --------------------------------------------------
$input-color: $color-brand-dark;
$input-bg: $color-brand-light;
$input-border: $color-brand-dark;
$input-color-placeholder: $input-color;

$input-bg-disabled: $color-brand-primary-pale;


// Buttons
// --------------------------------------------------
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal !default;

$btn-default-color:              $input-color !default;
$btn-default-bg:                 transparent !default;
$btn-default-border:             $input-border !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $color-brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-secondary-color:             $color-brand-primary !default;
$btn-secondary-bg:                 $color-brand-light !default;
$btn-secondary-color--dark:       $color-brand-light !default;
$btn-secondary-bg--dark:          $color-gray-5 !default;
$btn-secondary-border:             $color-brand-secondary !default;

$btn-light-color:                 $color-brand-light !default;
$btn-light-bg:                    rgba($color-brand-dark, 0) !default;
$btn-light-border:                $color-brand-light !default;
$btn-light-color-hover:           $color-brand-dark;


$btn-dark-color:                 $color-brand-light !default;
$btn-dark-bg:                    $color-brand-dark !default;
$btn-dark-border:                $color-brand-dark !default;
$btn-dark-color-hover:           $color-brand-secondary !default;
$btn-dark-bg-hover:              lighten($color-brand-dark, 20%);

$btn-success-color:              #fff !default;
$btn-success-bg:                 $color-brand-success !default;
$btn-success-border:             darken($btn-success-bg, 5%) !default;

$btn-warning-color:              #fff !default;
$btn-warning-bg:                 $color-brand-warning !default;
$btn-warning-border:             darken($btn-warning-bg, 5%) !default;

$btn-danger-color:               #fff !default;
$btn-danger-bg:                  $color-brand-danger !default;
$btn-danger-border:              darken($btn-danger-bg, 5%) !default;

$btn-link-disabled-color:        $gray-light !default;

$btn-disabled-color: $gray-light;
$btn-disabled-bg: $gray-lighter;
$btn-disabled-border: $gray-light;
