.alphalist {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &-item {
        display: inline-block;
        flex: 0 1 auto;
        text-align: center;
        width: 1.4em;
        margin-bottom: .5em;
        cursor: pointer;

        &:hover {
            .alphalist-text {
                font-size: $font-size-h3;

                &-disabled {
                    font-size: $font-size-evenlarger;
                }
            }
        }
    }

    &-text {
        padding: 0;
        margin: 0;
        font-family: $font-family-regular;
        font-weight: 400;
        text-transform: uppercase;
        font-size: $font-size-evenlarger;
        line-height: $font-size-h3;
        vertical-align: text-bottom;
        color: $color-brand-primary;
        @include transition(font-size .2s ease);

        &-disabled {
            cursor: default;
            color: rgba($color-brand-primary, .5);
            transition: none;
        }
    }

    @include min-width-sm {
        justify-content: space-around;

        &-item {
            flex: 1 1 auto;
            width: 1em;
        }
    }

    @include min-width-md {
        &-text {
            font-size: $font-size-h4;
            line-height: $font-size-h2;
        }

        &-item {
            &:hover {
                .alphalist-text {
                    font-size: $font-size-h2;

                    &-disabled {
                        font-size: $font-size-h4;
                    }
                }
            }
        }
    }
}
