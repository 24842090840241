@use "sass:math";
//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
    position: relative;
    display: inline-block;
    margin-bottom: 0; // For input.btn
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border: 1px solid transparent;
    font-family: $font-family-semibold;
    font-weight: $btn-font-weight;
    text-align: center;
    letter-spacing: 1px;
    box-sizing: border-box;
    max-width: calc(100vw - #{$spacing-large});
    overflow: visible;

    @include transition($transition-interactive);

    @include button-variant($btn-default-color, $btn-default-bg, $color-brand-chartreuse, $color-brand-secondary, $color-brand-dark);
    @include button-size($padding-button-base-vertical, math.div($padding-button-base-horizontal, 2), $font-size-button);
    @include user-select(none);

    @include min-width-xs {
        @include button-size($padding-button-base-vertical, $padding-button-base-horizontal, $font-size-button);
    }

    @extend .title-style;

    &:hover,
    &:focus,
    &.focus {
        text-decoration: none;
    }

    &:active,
    &.active {
        background-image: none;
        @include box-shadow(none);
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        cursor: disabled;
        @include box-shadow(none);
    }

    @include min-width-md {
        white-space: nowrap;
    }

    // [converter] extracted a& to a.btn
}

a.btn {

    &.disabled,
    fieldset[disabled] & {
        pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
}

a.btn {
    &--no-link {
        pointer-events: none;
    }
}


// Alternate buttons
// --------------------------------------------------
.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-color);
}

.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border, $btn-secondary-color);
}

.btn-secondary--dark {
    @include button-variant($btn-secondary-color--dark, $btn-secondary-bg--dark, $btn-secondary-border, $btn-secondary-color--dark);
}

.btn-light {
    @include button-variant($btn-light-color, $btn-light-bg, $btn-light-border, $btn-light-border, $btn-light-color-hover);
}

.btn-dark {
    @include button-variant($btn-dark-color, $btn-dark-bg, $btn-dark-border, $color-brand-secondary, $color-brand-dark);
}

// Success appears as green
.btn-success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}

// Warning appears as orange
.btn-warning {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}

// Danger and error appear as red
.btn-danger {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
}

// Button Sizes
// --------------------------------------------------

.btn-lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($padding-button-base-vertical, $padding-button-base-horizontal, $font-size-button-lg);
}

.btn-sm {
    // line-height: ensure proper height of button next to small input
    @include button-size($padding-button-base-vertical, $padding-button-base-horizontal, $font-size-button-sm);
}

.btn-xs {
    @include button-size($padding-button-base-vertical, $padding-button-base-horizontal, $font-size-button-xs);
}


// Block button
// --------------------------------------------------

.btn-link {
    font-weight: inherit;
    margin: 0;
    padding: 0;
}

.btn-block {
    display: block;
    width: 100%;
    max-width: 100%;
}

.btn-thick {
    border-width: 2px;
}

.btn-lower {
    text-transform: none;
}

// Vertically space out multiple block buttons
.btn-block+.btn-block {
    margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.btn-block {
        width: 100%;
    }
}

.btn {
    >.accent-arrow {
        position: absolute;
        right: $spacing * 2;
        top: 50%;
        border-color: $color-brand-primary;
        @include translate(0, -50%);

        .icon {
            color: inherit;
            font-size: 28px;
            line-height: 15px;
        }

        @include min-width-xs {
            right: $spacing * 4;
        }
    }

    >.icon-mail {
        font-size: $font-size-xxl;
        position: relative;
        top: .19em;
        height: 43px;
        margin-right: 5px;
    }

    >.icon-clipboard {
        font-size: $font-size-xxl;
        position: relative;
        top: .1em;
    }
}

.btn-arrow {
    padding-right: $spacing * 4.5;

    @include min-width-xs {
        padding-right: $spacing * 7;
    }
}

.btn-spaced {
    letter-spacing: 4px;
}

.btn-mail,
.btn-clipboard {
    font-size: $font-size-button-sm;

    @include min-width-md {
        font-size: $font-size-button-lg;
    }

    @media screen and (max-width: $screen-xs) {
        font-size: $font-size-smaller;
        padding: $spacing*1.25 0;
    }
}

a.rt {
    color: $color-brand-primary;
}

.btn-column {
    @media (min-width: 767.98px) {
        .btn-margin {
            margin-left: 1rem;
        }
    }

    @media (max-width: 767.98px) {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        .btn-margin {
            margin-top: 1rem;
        }
    }
}