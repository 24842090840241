.area {
    padding-top: $spacing-large;

    &-bordered {
        border-top: $border-default;
    }

    &-block {
        margin-top: $spacing-large;

        &:first-child {
            margin-top: 0;
        }
    }

    + .area {
        margin-top: $spacing-large;
    }
}

.arrow-section {
    position: absolute;
    right: 0;
    top: 0;

    @media screen and (max-width: $screen-xs) {
        position: relative;
        margin: $spacing*1.25 0 $spacing*2.5;
    }
}
