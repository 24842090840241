@use "sass:math";

.hero {
    position: relative;
    //No-image styles for Ideas Landing Only
    // &--no-img {
    //   @media screen and (max-width: $screen-lg) {
    //     padding-top: $spacing*4;
    //   }
    // }
    &-section {
        position: relative;
        margin-top: $spacing * 3;

        @include min-width-md {
            margin-top: $spacing * 2.25;
        }

        &.full-height {
            overflow: hidden;

            @include min-width-md {

                .hero-columns {
                    padding-bottom: 0;
                    min-height: calc(100vh - #{$spacing*2});
                }

                .hero-columns__column--secondary {
                    margin-bottom: $spacing;
                }

                .hero-image {
                    max-height: 100%;

                    img {
                        height: 100%;
                        width: auto;
                        max-width: none;
                        flex: 1 0 auto;
                    }
                }

                .hero-flex-content {
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }

    &-container-fluid {
        margin-bottom: $spacing * 3;

        &--no-margin-bottom {
            margin-bottom: 0;
        }

        @include min-width-md {
            margin-bottom: 0;
            @include container-fixed(72px);
        }
    }

    .bg-hero-image {
        z-index: -1;
        height: 100%;
        min-width: 100%;
        background-size: cover;
        position: absolute;
    }

    .hero-image {
        position: relative;
        overflow: hidden;
        min-height: 100%;
        height: 100%;
        margin-left: $spacing;
        margin-right: $spacing;

        @include backgroundFull();

        &:not(.nooverlay) {
            @extend .blackout-image-overlay-light;
        }

        &:before {
            display: block;
            min-width: 100%;
            min-height: 100%;
            z-index: 1;
        }

        &--professional {
            @include min-width-xs {
                background-position: 20% 15%;
            }

            @include min-width-sm {
                background-position: 50% 15%;
            }
        }

        @include min-width-md {
            margin: 0;
            max-height: none;
        }
    }

    &--homepage {
        padding-bottom: 1rem;

        @include min-width-md {
            height: calc(100vh - #{$spacing*2});
            max-height: 640px;
            padding-bottom: 0;
        }

        .hero-image {
            margin-left: 0;
            margin-bottom: 2rem;
            position: absolute;
            width: 100%;
            background-position-x: left;
            overflow: visible;

            &:not(.nooverlay):before {
                opacity: 0.5;
            }

            @include min-width-md {
                height: calc(100% - 2rem);
                max-height: 640px;
            }
        }

        .hero-flex-content {
            height: calc(100vh - #{$spacing*2});
            max-height: 640px;
            text-align: center;
            padding-top: 3.5rem;

            @include min-width-md {
                padding: 8rem 0;
            }

            &--short {
                padding-bottom: 0;
            }
        }

        .hero-flex-item {
            position: relative;
            color: $color-brand-light;
            text-align: center;
            max-width: px-to-rem(840px);
            margin: 0 auto;
            z-index: 10;

            &--wide {
                max-width: px-to-rem(1000px);
            }
        }

        .hero-title {
            text-align: unset;
            padding: 0;
            font-family: $font-family-semibold;
            font-size: px-to-rem(48px);
            font-weight: 100;

            @include min-width-md {
                font-size: px-to-rem(110px);
            }
        }

        .hero-subtitle {
            font-family: $font-family-semibold;
            font-size: $font-size-evenlarger;
            font-weight: $weight-bold-standard;
            @include min-width-md {
                font-size: $font-size-xl;
                margin-bottom: 2.5rem;
            }
        }
    }

    .navlist {
        padding-top: 2rem;
        margin: 0;
    }

    .navlist-item {
        margin: 0;
        margin-top: 2rem;

        &:first-child {
            margin: 0;
        }
    }

    .navlist-text {
        @extend .link-reset;
        position: relative;
        text-align: center;

        @include min-width-md {
            text-align: left;

            .navlist-text-subtitle {
                opacity: 0;
            }

            &:hover {
                .navlist-text-subtitle {
                    opacity: 1;
                    @include translate(0, -75%);
                }
            }
        }
    }

    .navlist-text-title {
        display: inline-block;
        @include linkColor($color-brand-light);
        @extend .title-style;
        font-size: $font-size-large;
        @include letterSpacingCenter(.4em);

        @include min-width-md {
            font-size: $font-size-xl;
            @include letterSpacingReset();
        }

        @include min-width-lg {
            font-size: $font-size-h2;
        }
    }

    .navlist-text-subtitle {
        position: relative;
        display: block;
        color: $color-brand-light;
        @extend .subtitle-style;
        padding-top: $spacing * 2.5;
        margin-top: $spacing * 1.5;
        font-size: $font-size-small;
        @include transition(opacity .6s ease, transform .6s ease);
        @include letterSpacingCenter(.15em);

        &:before {
            position: absolute;
            top: 7px;
            right: 50%;
            display: inline-block;
            content: " ";
            height: 2px;
            width: $spacing * 4;
            background-color: $color-brand-secondary;
            @include translate(50%, 0);
        }

        @include min-width-md {
            white-space: nowrap;
            position: absolute;
            right: 100%;
            top: calc(50%);
            @include translate(5%, -75%);
            padding-right: $spacing * 6;
            padding-top: 0;
            margin-top: 0;
            font-size: $font-size-evenlarger;
            @include letterSpacingReset();

            &:before {
                position: absolute;
                right: -2.5%;
                top: 50%;
                @include translate(-50%, 0);
            }
        }
    }

    &-nav {
        align-self: flex-start;
        padding: 0 ($spacing*2) ($spacing*1.5);

        @extend .text-center;

        @include min-width-md {
            padding: 0 ($spacing*3);
            text-align: left;
        }
    }

    &-flex-content {
        position: relative;
        margin-left: $spacing;
        margin-right: $spacing;
        padding: $spacing * 2.5 0;

        &--short {
            padding-bottom: 0;
        }

        &--tall {
            padding-bottom: $spacing * 5;
        }

        &--no-img {
            @include container-flexy(1.25);

            .hero-flex-item {
                margin: 0 0;
            }
        }

        @include min-width-sm {
            &--no-img {
                margin-left: auto;
                margin-right: auto;
                padding-top: $spacing * 2.5;

                @include container-flexy(1.25, $spacing * 4);
            }
        }

        @include min-width-md {
            position: static;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            margin-left: auto;
            margin-right: auto;
            padding: $spacing*4 0;
            height: 100%;

            &--no-img {
                padding-top: $spacing * 5;
            }

            &--short {
                padding-bottom: 0;
            }

            &--center {
                justify-content: center;
                align-items: center;
            }
        }

        &--pushdown {
            justify-content: flex-end;
        }
    }

    &-flex-item-row {
        @extend .clearfix;
        padding: $spacing*1 0 $spacing * 1.5 0;
        border-top: 1px solid $color-brand-chartreuse;

        &:first-child,
        &.flex-item-first {
            border-top: 0;
            padding-top: $spacing*4;
        }

        &.flex-item-last {
            padding-bottom: $spacing;
        }

        &--right {
            text-align: right;
        }

        &--no-border {
            border-top: none;
        }

        @include min-width-tiny {
            margin: 0 ($spacing * 1);
        }

        @include min-width-xs {
            margin: 0 ($spacing * 3);
        }
    }

    &-title {
        color: $color-brand-light;
        // padding: $spacing-med $spacing-med $spacing-large;
        padding: $spacing;
        width: 100%;
        text-align: center;

        &--center {
            text-align: center;
        }

        @include min-width-md {
            text-align: left;
            padding: 0 $spacing*3;
            padding-top: $spacing * 4.3125;

            &--center {
                text-align: center;
            }
        }
    }

    &-title-pro {
        color: $color-brand-light;
    }

    &-date {
        color: $color-brand-light;
        font-size: $font-size-base;
        margin-bottom: .8rem;
    }

    .title-text {
        margin-bottom: $spacing;
        font-size: $font-size-h4;
        @extend .title-style;
        letter-spacing: 0;
        text-transform: none;

        &-pro {
            margin-bottom: math.div($spacing, 2);
            font-size: $font-size-xxl;
            letter-spacing: normal;
            text-transform: none;
        }

        &-service {
            margin-bottom: 0;
        }

        @include min-width-sm {
            margin-bottom: $spacing * 2;

            &-pro {
                margin-bottom: math.div($spacing, 2);
                font-size: px-to-rem(40px);
            }

            &-service {
                margin-bottom: 0;
            }
        }

        @include min-width-md {
            font-size: px-to-rem(40px);
        }

        @include min-width-lg {
            font-size: px-to-rem(40px);

            &-pro {
                font-size: px-to-rem(40px);
            }
        }
    }

    .subtitle-text {
        @extend .subtitle-style;
        font-size: $font-size-h6;
        font-weight: $weight-light-standard;
        letter-spacing: .08em;

        &-pro {
            font-weight: $weight-light-standard;
            font-size: $font-size-large;
            font-style: normal;
            font-family: $font-family-semibold;
            margin-bottom: 0;
        }

        @include min-width-md {
            font-size: $font-size-larger;
            margin-right: .25em;
            margin-bottom: 1rem;
        }
    }

    .location-text {
        @extend .subtitle-style;
        font-size: $font-size-h6;
        font-weight: $weight-light-standard;
        letter-spacing: .08em;

        @include min-width-md {
            font-size: $font-size-larger;
            margin-right: .25em;
            margin-bottom: 1rem;
        }
    }

    &-lower-title {
        .title-text {
            text-transform: none;
            letter-spacing: 0;
        }
    }

    &-jumbo-title {
        position: absolute;
        padding: 0;
        top: -8.5em;
        width: 100%;
        z-index: 1;
        margin: 0 auto 0;
        //@include min-width-xs {
        //  top: -8.5em;
        //}
        @include min-width-sm {
            top: -9.5em;
        }

        @include min-width-md {
            position: relative;
            top: 0;
            flex-shrink: 0;
        }

        .title-text {
            margin: 0;
            padding: 0 0 0;
            line-height: normal;
            font-size: $font-size-jumbo-tiny;
            @include letterSpacingCenter(.25em);
            //@include min-width-tiny {
            //  @include translate(0, -50%);
            //}
            @include min-width-sm {
                font-size: $font-size-jumbo-sm;
            }

            @include min-width-md {
                @include translate(-50%, 0);
                @include letterSpacingCenter(.15em);
                font-size: $font-size-jumbo-md;
            }

            @include min-width-lg {
                @include letterSpacingCenter(.25em);
                font-size: $font-size-jumbo;
            }
        }
    }
}

.no-img {
    margin-bottom: $spacing-med;

    @media screen and (max-width: $screen-md) {
        margin-bottom: $spacing-xmed;
    }
}
