.contactus-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: px-to-rem(25px);

    &-asterisk {
        font-family: $font-family-regular;
        font-size: $font-size-xxxs;
        font-weight: 100;
        color: $color-gray-5;
        text-align: left;
        width: 100%;
        padding-bottom: $spacing-small;
    }

    input {
        width: 49%;
        margin-bottom: $spacing;
        flex-grow: 1;
        padding: $spacing 0 px-to-rem(28px);
        border: none;
        border-bottom: 1px solid hsla(0,0%,57%,.2);
        
        &::placeholder {
            color: $color-gray-5;
            font-weight: 100;
            font-family: $font-family-regular;
            text-align: left;
            font-size: $font-size-base;
            text-transform: uppercase;
        }

        &:nth-child(even) {
            margin-right: $spacing;
        }

        @media screen and (max-width: $screen-md) {
            width: 100%;
            
            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }

    &-field-long {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: px-to-rem(22px);
    }

    textarea {
        font-weight: 100;
        font-family: $font-family-regular;
        text-align: left;
        font-size: $font-size-base;
        border: 1px solid $color-gray-8;
        background: $color-brand-primary-pale;
        margin-bottom: $spacing-med;
        height: $spacing-xxxlarge;
    }

    &-label {
        color: $color-gray-5;
        font-weight: 100;
        font-family: $font-family-regular;
        text-align: left;
        font-size: $font-size-base;
        text-transform: uppercase;
        margin-bottom: $spacing;
    }

    &-disclaimer {
        color: $color-gray-5;
        font-weight: 100;
        font-family: $font-family-regular;
        text-align: left;
        font-size: $font-size-base;
        margin-top: $spacing-med;
    }
}