@use "sass:math";

.footer {
    color: $color-brand-dark;
    padding-top: 0;

    &-decor {
        position: relative;
        @extend .background-full;
    }

    &-inner {
        position: relative;
        padding: $spacing-xlarge 0 $spacing-large;

        @include min-width-md {
            padding: $spacing-xlarge 0 $spacing-large;
        }
    }

    &-top {
        position: relative;
        padding-bottom: $spacing-xmed;

        @include min-width-lg {
            display: flex;
            justify-content: space-between;
        }
    }

    &-link {
        display: block;
        color: $color-brand-green;
        font-family: $font-family-light;
        font-size: 28px;
        font-weight: 100;
        line-height: 1.05;
        padding-top: 1rem;
        transition: color 0.3s ease;

        @include min-width-sm {
            font-size: 44px;
            font-family: $font-family-ultralight;
            font-size: 100;
        }

        &:hover {
            color: lighten($color-brand-green, 15%);
        }

        &:first-child
        {
            padding-top: 0;
        }
    }

    &-subscribe {
        padding-top: $spacing-med;
        
        @include min-width-lg {
            padding-left: 2rem;
        }
    }

    &-nav-container {
        position: relative;
        overflow: hidden;

        .nav {
            @include min-width-sm {
                margin-bottom: $spacing*2;
            }
        }

        .navlist {
            @include min-width-sm {
                column-count: 3;
                column-gap: 30px;
            }
        }

        .navlist-title-link {
            display: block;
        }

        .navlist-text-title {
            font-weight: $weight-light-standard;
        }

        .navlist-section {

            .navlist-text {
                font-size: $font-size-small;
                text-transform: uppercase;
            }

            @include min-width-md {
                .navlist-title {
                    font-size: $font-size-base;
                }

                .navlist-text {
                    font-size: $font-size-base;
                }
            }
        }

        .navlist-item {
            margin-bottom: math.div($spacing, 2);

            .icon {
                font-size: $font-size-h2;
            }

            &--spaced {
                padding-top: $spacing * 2;
            }
        }

        a {
            @include linkColor($color-brand-dark, $color-brand-dark);
            font-weight: $weight-light-standard;
        }
    }

    .nav.column {
        .navlist {
            margin-bottom: $spacing * 2;
        }
    }

    .columns-footer {
        .navlist {
            padding-top: $spacing * 2;
            border-top: 1px solid $color-brand-green;
        }
    }
}

.footer-callout {
    position: relative;
    top: -50%;
}

.legal {
    font-size: $font-size-xs;
    font-weight: $weight-bold-standard;
    display: flex;
    flex-direction: column;

    a {
        font-size: $font-size-med;
        @include linkColor($color-brand-dark, $color-brand-green);
        margin-top: 0.5rem;
        
        @include min-width-sm {
            margin-top: 0;

            &:not(:first-of-type)
            {
                margin-left: 12px;
            }
        }
    }

    &:not(first-of-type)
    {
        margin-top: 1rem;
    }

    > .legal-row
    {
        display: flex;
        flex-direction: column;

        &:last-of-type {
            padding-top: 1rem;
        }

        @include min-width-sm {
            flex-direction: row;

            &:last-of-type {
                padding: 0;
            }
        }
    }

    @include min-width-sm {
        font-size: $font-size-med;
        flex-direction: row;
    }
}

.copyright {
    font-size: $font-size-med;
    font-weight: $weight-bold-standard;
}
