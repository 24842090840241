//COPY DIRECTLY FROM ICOMOON FILE fonts/icomoon/style.css
// INSTRUCTIONS:
//	1) to update files go to https://icomoon.io/app/#/select
//	2) import icons from selection.json
//	3) copy whole folder to replace fonts/icomoon
//	4) copy style.css to this file
//	5) update font path


$icomoon-hash: 'iru7tj';
$font-path-icomoon: 'icomoon/fonts/icomoon';
$font-family-icomoon: 'icomoon';
 

@include font-face-family($font-family-icomoon, $font-path-icomoon, 'normal', 'normal');

@mixin iconStyle() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon($name) {
  @include iconStyle();
  //@extend .icon-#{$name}:before;
  display: inline-block;
}

[class^="icon-"],
[class*=" icon-"] {
  &:before,
  &:after {
    @include iconStyle();
  }
}

.text-icon {
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: .75em;
}


//When including new icons, copy and paste from icomoon below
.icon-icon-see-all:before {
  content: "\e916";
}

.icon-clipboard:before {
  content: "\e915";
}
.icon-mail:before {
  content: "\e913";
}
.icon-calendar:before {
  content: "\e90c";
}
.icon-date:before {
  content: "\e90c";
}
.icon-schedule:before {
  content: "\e90c";
}
.icon-clock-thin:before {
  content: "\e912";
  color: #979797;
}
.icon-scroll:before {
  content: "\e90b";
}
.icon-group:before {
  content: "\e906";
}
.icon-users:before {
  content: "\e906";
}
.icon-arrow-up:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-external-link:before {
  content: "\e914";
}
.icon-download:before {
  content: "\e911";
}
.icon-minus:before {
  content: "\e90f";
}
.icon-clock:before {
  content: "\e90d";
}
.icon-plus:before {
  content: "\e904";
}
.icon-x:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e905";
}
.icon-feed:before {
  content: "\e90e";
}
.icon-linkedin:before {
  content: "\e907";
}
.icon-youtube:before {
  content: "\e908";
}
.icon-twitter:before {
  content: "\e909";
}
.icon-facebook:before {
  content: "\e90a";
}
.icon-spinner11:before {
  content: "\e984";
}
.icon-instagram:before {
    content: "\ea92";
}
.icon-x1:before {
    content: "\e909";
}