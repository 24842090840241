@use "sass:math";

.search {

  &--top {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    padding: 0;

    .form-control {
      width: 100%;
      display: block;
      background-color: white;
      color: $color-gray-A;
      border: 1px solid $color-gray-A;
      line-height: 2em;
      padding: .25em 2.25rem .25em .5em;
      font-size: $font-size-large;
      font-family: $font-family-regular;
      height: 4rem;

      &--smallerhalf {
        padding-right: .5em;
      }

      &--half, &--smallerhalf {
        width: 40%;
        display: inline-block;
        float: left;
      }
      
      &::-ms-clear {
        display: none;
      } 

      @include min-width-md {
        font-size: $font-size-larger;

        &--half {
          width: 50%;
        }
        &--smallerhalf{
          width: 45%;
        }
      }

      @include min-width-lg {
        font-size: $font-size-xl;
      }
  
      @include placeholder($color-gray-A);
    }
  
    @include min-width-md {
      padding: $spacing-large $spacing-med;
    }
  }


  .icon-search {
    color: $color-brand-secondary;
    font-size: $font-size-xl;
    @include transition(font-size .3s ease);
  }

  &-field {
    position: relative;

    .submit-button {
      position: absolute;
      top: 50%;
      @include translate(0, -50%);
      right: $spacing;
      z-index: $z-content;

      &--side {
        right: 0;
        width: 20%;
        line-height: 2em;
        min-height: 3rem;
        padding: .25em .5em;
      }

      &--border{
        border: .0625rem solid #d8d8d8;

        &:hover {
            background-color: $color-brand-secondary;
            .icon-search {
              font-size: inherit;
              color: $white;
            }
        }
      }

      &:hover {
      .icon-search {
        font-size: $font-size-xxxl;
      }
    }
  }

    @include min-width-md {
      .submit-button {
        top: 50%;
        @include translate(0, -50%);
        right: $spacing * 2;

        &--top {
          right: 0;
        }

        &--side {
          min-height: 4rem;
          width: 10%;
        }
      }
    }
  }
  &-block {
    .accordion {
      &__bar {
        border: 1px solid $color-brand-forest-green;
        background-color: $color-brand-forest-green;
        color: $color-brand-light;
        &:hover:not(.accordion__bar--no-hover),
        &--active {
            border-color: $color-brand-secondary;
            background-color: $color-brand-secondary;
            color: $color-brand-primary;
        }
      }
    }
    
    @include min-width-md {
      &--name-filters{
        background-color: $color-brand-light;
      }
    }
  }  
}
.search-options {
  margin: 0 auto;

  &__fields {
    @include make-row($spacing-med);
  }

  &--wide {
    .search-options__field {
      @include make-lg-column(4, $spacing-med);
    }
  }

  &__fields--extra {
    overflow: hidden;
    max-height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    @include transition(max-height 0.15s ease-out);
    
    &.active {
      max-height: 400px;
      padding-bottom: $spacing-med;
      overflow: visible;
    }
  }

  &__field {
    @include make-lg-column(6, $spacing-med);
  }

}

.search-section-row {
    margin: $spacing 0;

    &:first-child {
        margin-top: 0;
    }

    @include min-width-md {
        margin: $spacing-med 0;
    }
}

.advanced-button {
  @include icontoggle();
}



.jumbo-input {
  padding: $spacing ($spacing*1.5);
  background-color: $color-brand-light;

  &--half {
    display: inline-block;
    width: 100%;
  }

  @include min-width-md {
    padding: $spacing-med;
    &--half{
      width: 50%;
      margin: 0;
      box-shadow: none;
    }
  
    &--right {
      float: right;
    }
  }

  > .form-control {
    font-size: $font-size-large;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: $color-brand-primary;
    padding-left: math.div($spacing, 2);
    padding-right: $spacing * 3;

    &::-ms-clear {
      display: none;
    } 
    
    @include min-width-md {
      font-size: $font-size-xl;
    }

    @include placeholderPrefix {
      @include transition(color .3s ease);
    }

    &:hover {
      @include placeholderPrefix {
        color: rgba($color-brand-primary, 0.7);
      }
    }

    &:focus {
      @include placeholderPrefix {
        color: rgba($color-brand-primary, 0.1);
      }
    }
  }
}
