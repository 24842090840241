@use "sass:math";

.hero__location {

  &-address,
  &-meta {
    margin-top: .25rem;
    font-family: $font-family-regular;
    font-size: $font-size-large;
    color: $color-brand-light;
    font-weight: $weight-light-standard;
  }

  &-name {
    font-size: $font-size-large;
    margin-bottom: $spacing;
  }

  &-link {
    font-size: $font-size-large;
    font-weight: $weight-light-standard;
    @include linkColor($color-brand-light, $color-brand-secondary);
  }

  &-map {
    margin: math.div($spacing, 2) 0 $spacing*2;
  }

  &-email {
    margin-bottom: $spacing * 2;
    color: $color-brand-light;
    font-size: $font-size-large;
  }

  &-meta {
    a { color: inherit; }
    .label {
      padding-left: 1rem;
    }
  }

  &-list {
    width: 100%;
    display: block;
    margin-bottom: -($spacing * 3);
  }

  &-list-item {
    margin-bottom: $spacing * 3;
  }

  @include min-width-sm {
    &-list {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
    }

    &-list-item {
      flex: 1 0 auto;
      margin-right: 5%;
      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }

  @include min-width-md {
    &-link {
      font-size: .7rem;
    }
    &-address,
    &-meta {
      font-size: .7rem;
    }
    &-email {
      font-size: .7rem;
    }
  }
  @media (min-width: 1047px) {
    &-link {
      font-size: .8rem;
    }
    &-address,
    &-meta {
      font-size: .8rem;
    }
    &-email {
      font-size: .8rem;
    }
	}
  @media (min-width: 1143px) {
    &-link {
      font-size: .9rem;
    }
    &-address,
    &-meta {
      font-size: .9rem;
    }
    &-email {
      font-size: .9rem;
    }
	}
  @media (min-width: 1367px) {
    &-link {
      font-size: 1rem;
    }
    &-address,
    &-meta {
      font-size: 1rem;
    }
    &-email {
      font-size: 1rem;
    }
	}
  @media (min-width: 1450px) {
    &-link {
      font-size: $font-size-large;
    }
    &-address,
    &-meta {
      font-size: $font-size-large;
    }
    &-email {
      font-size: $font-size-large;
    }
	}









}