.feature-video {
  display: flex;
  flex-direction: column;

  @include min-width-sm {
      flex-direction: row;
  }
  
  &__image {
    @include min-width-sm {
        width: 50%;
        padding-right: 3.75rem;
    }

    img {
      width: 100%;
    }
  }

  &__content {
    padding: 1.25rem 0;

    @include min-width-sm {
        width: 50%;
        padding: 1.25rem 1.25rem 1.25rem 0;
    }
  }

  &__tag {
    font-family: $font-family-semibold;
    font-weight: $weight-bold-standard;
    font-size: $font-size-large;
    letter-spacing: .125em;
    text-transform: uppercase;
    margin-bottom: 2rem;

    &:after {
      content: "";
      display: block;
      margin-top: 25px;
      width:  100px;
      border-bottom: 1px solid $color-brand-chartreuse;
    }
  }

  &__title {
    font-family: $font-family-semibold;
    font-size: $font-size-linklist-big;
    line-height: 1.1;
    font-weight: 100;
    margin-bottom: 3.5rem;
    
    @include min-width-md {
      font-size: px-to-rem(38px);
    }
  }

  @include container-fixed(72px);

  @media (min-width: $screen-xxl-min) {
    max-width: ceil(($container-xxl));
  }
}