@use "sass:math";
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  @include clearfix;
}
.center-block {
  @include center-block;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  @include text-hide;
}
.printonly {
  display: none;
}
.pdfonly {
    display: none;
}

.experienceeditoronly {
  display: none;
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}


// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}


.background-full {
  @include backgroundFull;
}

//use on UL or OL to remove styles
.reset-list {
  @include resetList;
}

.reset-link {
  text-decoration: none;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

.link-block {
  display: block;
}


.vertical-spacing {
    & {
      padding-top: $spacing;
      padding-bottom: $spacing;

      &-no-mobile {
        @include min-width-md {
          padding-top: $spacing;
          padding-bottom: $spacing;
        }
      }
    }
    &-top {
      padding-top: $spacing;

      &-no-mobile {
        @include min-width-md {
          padding-top: $spacing;
        }
      }
    }
    &-bottom {
      padding-bottom: $spacing;

      &-no-mobile {
        @include min-width-md {
          padding-bottom: $spacing;
        }
      }

      &-mobile {
        padding-bottom: $spacing;

        @include min-width-lg {
          padding-bottom: 0;
        }
      }
    }
}

@mixin container-flexy($limit: 1, $gutter: 0) {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    max-width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    max-width: ceil(math.div($container-md, $limit) - $gutter);
  }
  @media (min-width: $screen-lg-min) {
    max-width: ceil(math.div($container-lg, $limit) - $gutter);
  }
  @media (min-width: $screen-xl-min) {
    max-width: ceil(math.div($container-xl, $limit) - $gutter);
  }
  @media (min-width: $screen-xxl-min) {
    max-width: ceil(math.div($container-xxl, $limit) - $gutter);
  }
}


#mti_wfs_colophon { display: none !important; }