@use "sass:math";
.stacklist {
    height: 100%;

    &-block {
        .column {
            position: relative;
            flex: 1 1 auto;

            &:last-child {
                margin-bottom: 0;
            }

            @include min-width-md {
                margin-bottom: 0;
            }
        }
    }

    .columns-inline {
        margin-bottom: 0;
    }

    .columns-inline > .column {
        &.stacklist-metacol {
            width: 100%;
            padding-right: 0;
        }

        &.stacklist-symbolcol {
            width: $spacing * 6;
            text-align: center;
            font-family: $font-family-regular;
            color: $color-gray-9;
            display: none;
        }

        @include min-width-xs {
            &.stacklist-metacol {
                width: 60%;
                padding-right: $spacing-med;
            }

            &.stacklist-symbolcol {
                display: block;
            }
        }
    }

    &-symbol {
        display: inline-block;
        margin: 0 auto;
    }

    &-time-icon,
    &-event-icon {
        display: block;
    }

    &-event-icon {
        font-size: $font-size-h1;
    }

    &-time-icon {
        font-size: $font-size-h1;
    }

    &-item {
        display: block;
        padding-bottom: $spacing * 1.5;
        @include linkColor();

        @media screen and (max-width: $screen-xs) {
            margin-bottom: $spacing;
        }

        &--bordered {
            border-top: $border-default;
            padding: $spacing*2 0;
        }

        &--bordered:first-of-type {
            border-top: none;
            padding: 0 0 $spacing*2 0;
        }
    }

    &-meta {
        color: $color-gray-9;
        font-family: $font-family-regular;
        font-size: $font-size-small;

        @include min-width-md {
            font-size: $font-size-base;
        }

        &--site-search {
            line-height: 1.5;

            .highlight {
                font-weight: bold;
            }
        }
    }

    &-meta-item {
        display: inline-block;
        margin-bottom: $spacing-small;
        margin-left: .25em;
        color: $black;

        &:before {
            margin-right: .5em;
            content: "|";
        }

        &:first-child {
            color: $color-brand-green;
            margin-left: 0;

            &:before {
                margin-right: 0;
                content: "";
            }
        }
    }

    &-meta-item-alt {
        display: inline-block;
        margin-top: $spacing-small;
        margin-left: .25em;
    }

    &-item-title {
        @extend .header-style;
        margin: 0;
        font-size: px-to-rem(28px);
        line-height: px-to-rem(32px);
        //font-weight: $weight-standard;
        @include linkColor($black, $color-brand-green);

        &--uppercase {
            text-transform: uppercase;
        }

        @include min-width-md {
            // font-size: $font-size-h4;
            font-size: px-to-rem(28px);
        }
    }
}

.services-links {
    display: flex;
    flex-flow: column nowrap;
    font-size: $font-size-xxs;

    .column-inner {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        height: 100%;
    }

    .btn {
        margin-bottom: math.div($spacing, 2);
        font-size: $font-size-xxxs;

        @include min-width-md {
            margin-bottom: $spacing-med;
            font-size: $font-size-xs;
        }
    }
}
