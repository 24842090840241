@mixin min-width-tiny() {
  @media (min-width: $screen-tiny-min) {
	  @content;
	}
}

@mixin min-width-xs() {
  @media (min-width: $screen-xs-min) {
	  @content;
	}
}

@mixin min-width-sm() {
	@media (min-width: $screen-sm-min) {
	  @content;
	}
}

@mixin max-width-sm() {
    @media (max-width: $screen-sm-min) {
        @content;
    }
}

@mixin min-width-md() {
  @media (min-width: $screen-md-min) {
	  @content;
	}
}

@mixin min-width-lg() {
	@media (min-width: $screen-lg-min) {
	  @content;
	}
}

@mixin min-width-xl() {
	@media (min-width: $screen-xl-min) {
	  @content;
	}
}

@mixin min-width-xxl() {
	@media (min-width: $screen-xxl-min) {
	  @content;
	}
}
