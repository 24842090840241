.stacktiles {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: relative;

  &__buttons {
    flex: 0 1 auto;
  }

  &__inner {
    height: 30%;
    flex: 1 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;
  }
  
  &__tile {
    display: block;
    flex: 1 1 33.33%;
    position: relative;
    padding: $spacing-med $spacing-med;
    border-top: 1px solid $color-gray-9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include linkColor($color-gray-D, $color-brand-secondary);
  
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
  
    &:first-child {
      border-top: 0;
    }
  
    .buttons {
      @include transition($transition-interactive);
      padding-bottom: 0;
    }
  
    &__tile-inner {
      position: relative;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      z-index: 1;
  
      > * {
        display: block;
        flex: 1 1 100%;
      }
    }

    @include min-width-xs {
        padding: $spacing-large $spacing-large;
    }
  }

  &__title {
    position: relative;
    z-index: 1;
    width: 100%;
    @extend .title-style;
    letter-spacing: .15em;
    font-size: $font-size-evenlarger;
  }

  @include min-width-md {
    height: 100%;
    justify-content: flex-end;

    &__tile {
      padding: 3% $spacing-large;
    }

    &__buttons {
      flex: 0 1 auto;
    }

    &__inner {
      flex: 1 0 auto;
      display: flex;
      flex-flow: column nowrap;
    }

    &__tile-inner {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      height: 100%;

      > * {
        display: block;
        flex: 0 1 auto;
        width: 100%;
      }
    }

    &__title {
      width: 60%;
      margin-bottom: 0;
      font-size: $font-size-xxl;
    }
  }
}