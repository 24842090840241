// Select styling

.custominput {
  position: relative;
  min-height: $input-height-base;
  background: $color-brand-light;
  border: 1px solid $color-brand-primary;
//  border: 3px solid $color-brand-secondary-dark;

  @include box-shadow(none);
  
  &:before {
    width: 30px;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background: $color-brand-light;
    content: '';
    pointer-events: none;
  }

  &:hover {
    &:before {
      background: $color-brand-light;
    }
  }

  input {
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    height: 100%;
    line-height: normal;
    width: 100%;
    outline: none;
    padding-right: 35px;
    padding-left: 15px;
    border: none;
    font-family: $font-family-regular;

    // Disable default styling on ff
    -moz-appearance: none;

    // Disable ugly ass outline on firefox
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    // Disable default styling on webkit browsers
    -webkit-appearance: none;

    // Disable default arrow on IE 11+
    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: $focus;
    }
  }
}