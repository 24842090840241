.page-sections {
    position: relative;

    &-neg {
        position: relative;
        margin-top: -($spacing * 4);
        z-index: $z-content;
    }

    @include min-width-md {
        &-neg {
            position: relative;
            margin-top: -($spacing * 8);
            z-index: $z-content;
        }
    }
}

.section {
    &--inactive {
        display: none;
    }

    &--narrow {
        width: 50%;
    }

    &--bg-faded {
        padding-top: $spacing-med;
        padding-bottom: $spacing-med;
        background-color: $color-gray-E;
    }

    margin: $spacing-med 0;
    position: relative;

    &-nospacing {
        margin-top: 0;
        margin-bottom: 0;
    }

    &-nospacing-bottom {
        margin-bottom: 0;
    }

    &-nospacing-top {
        margin-top: 0;
    }

    &__subsection-title {
        font-size: $font-size-evenlarger;
        margin-bottom: $spacing;
    }

    &__subsection-subtitle {
        margin-bottom: $spacing;
    }

    &--hero {
        margin: $spacing-med 0 0;

        &:last-of-type {
            margin-bottom: $spacing-med;
        }
    }

    @include min-width-md {
        margin: 3.25em 0;

        &--bg-faded {
            padding-top: $spacing-large;
            padding-bottom: $spacing-large;
        }

        &-nospacing {
            margin-top: 0;
            margin-bottom: 0;
        }

        &-nospacing-bottom {
            margin-bottom: 0;
        }

        &-nospacing-top {
            margin-top: 0;
        }

        &--hero {
            margin: $spacing-large 0 0;

            &:last-of-type {
                margin-bottom: $spacing-large;
            }
        }
    }

    &__subsection {
        margin: 0 0 $spacing-med;

        &:last-child {
            margin-bottom: 0;
        }

        &--border-btm {
            border-bottom: 1px solid $color-brand-green;
            margin: 0 0 $spacing-med;
            padding: 0 0 $spacing-med;

            &:last-child {
                border: none;
                padding-bottom: 0;
            }
        }

        &--margin-top {
            margin-top: $spacing-med;
        }

        @include min-width-md {
            margin: 0 0 $spacing-large;

            &:last-child {
                margin-bottom: 0;
            }

            &--border-btm {
                padding: 0 0 $spacing-large;
            }

            &--margin-top {
                margin-top: $spacing-large;
            }
        }
    }

    &__subsection-buttons {
        margin: $spacing-med 0 0;

        @include min-width-md {
            margin: $spacing-large 0 0;
        }
    }

    &-fade-in {
        overflow: hidden;
        opacity: 0;
        @include translate(0, 25%);
        @include transition(all .6s ease);


        .link-column-image {
            &:before,
            img {
                visibility: hidden;
                @include transition(visibility .6s ease 2.1s);
            }
        }

        .subsection-slidein {
            background-color: rgba(0, 0, 0, 0.8);
            @include translate(0, 40%);

            &.slidein-1 {
                @include transition(transform .6s ease .3s, background .6s ease 2.1s);
            }

            &.slidein-2 {
                @include transition(transform .6s ease .9s, background .6s ease 2.1s);
            }

            &.slidein-3 {
                @include transition(transform .6s ease 1.5s, background .6s ease 2.1s);
            }
        }

        &.scroll-active {
            opacity: 1;
            @include translate(0, 0);

            .link-column-image {
                &:before,
                img {
                    visibility: visible;
                }
            }

            .subsection-slidein {
                background-color: transparent;
                @include translate(0, 0);
            }
        }
    }

    &-block-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

        @media screen and (max-width: $screen-md) {
            flex-direction: column;
        }
    }

    .block-wrapper {
        @media screen and (max-width: $screen-md) {
            margin-bottom: $spacing*2;
        }
    }
}

.inner-section {
    margin-bottom: $spacing-med;

    &-nospacing-bottom {
        margin-bottom: 0;
    }
}
