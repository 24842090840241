@use "sass:math";

.dategraphic {
  display: inline-block;
  border: 2px solid $color-gray-9;
  color: $color-gray-9;
  border-radius: 6px;
  width: $spacing * 3.5;
  font-size: $font-size-xxs;
  text-transform: uppercase;
  line-height: .9rem;

  &__header {
    display: block;
    border-bottom: 2px solid $color-gray-9;
    padding: math.div($spacing, 8) math.div($spacing, 2);
    overflow: hidden;

    &:before,
    &:after {
      content: "";
      display: inline-block;
      border: 2px solid $color-gray-9;
      border-radius: 100%;
      width: 6px;
      height: 6px;
    }
    &:before {
      float: left;
    }
    &:after {
      float: right;
    }
  }
  &__details {
    display: block;
    padding: math.div($spacing, 4);
  }
}
