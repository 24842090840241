// Select styling

.customselect {
  position: relative;
  min-height: $input-height-base;
  background: $color-brand-light;
  border: 1px solid transparent;
  @include box-shadow(none);

  option {
    font-family: inherit;
  }
  
  &[disabled] {
    opacity: .5;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: $color-brand-primary transparent transparent transparent;
    pointer-events: none;
  }

  &:before {
    width: 30px;
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    background: $color-brand-light;
    content: '';
    pointer-events: none;
  }

  &:hover {
    &:before {
      background: $color-brand-light;
    }
  }

  select {
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    height: 100%;
    line-height: normal;
    width: 100%;
    outline: none;
    padding-right: 35px;
    padding-left: 15px;
    font-family: $font-family-regular;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // Disable ugly ass outline on firefox
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    &::-ms-expand { /* for IE 11 */
      display: none;
    }

    // Disable default arrow on IE 11+
    &::-ms-expand {
      display: none;
    }

    &:focus {
      outline: $focus;
    }

  }
}

// IE 9 only
@media all and (min-width:0\0) and (min-resolution:.001dpcm){
  .select {
    select {
      padding-right: 0;
    }

    &:after, &:before {
      display: none;
    }
  }
}
