@use "sass:math";

.callout {

    &--footer {
        position: relative;
        top: -$spacing-footer-callout;
        margin-bottom: math.div(-$spacing-footer-callout, 2);
    }

    &__column {
        flex: 1 1 100%;
        height: auto;

        &:first-child {
            margin: 0 auto;
            z-index: 1;

            @include min-width-md {
                flex: 1 1 auto;
                width: 55%;
                margin: 0 auto;
                margin-right: -5%;
                align-self: flex-start;
            }
        }

        &:last-child {
            margin-top: 0;
            z-index: 2;
            @include box-shadow(0 0 25px rgba(0, 0, 0, .15));

            @include min-width-md {
                flex: 1 1 auto;
                width: 55%;
                margin-left: -5%;
                margin-top: $spacing-footer-callout*2;
                align-self: flex-end;
            }
        }

        &--narrow-offset {
            &:first-child {
                @include min-width-md {
                    width: 60%;
                    flex: 1 1 60%;
                    margin-right: -10%;
                }
            }

            &:last-child {
                @include min-width-md {
                    width: 50%;
                    flex: 1 1 50%;
                    margin-left: 0;
                }
            }
        }
    }

    &__image-col {
        height: auto;
        min-height: 180px;
        overflow: hidden;

        .tile {
            margin: 0 (-$spacing) (-$spacing-med);
            padding: 0;
            @include backgroundFull;
            height: 100%;

            @include min-width-md {
                margin: 0;
                padding: 0;
            }
        }
    }

    &__body-col {

        .tile {
            min-height: 0;
            padding: $spacing;

            .title {
                @extend .header-style;
                font-size: $font-size-larger;
                width: 100%;
            }

            .date {
                @extend .header-style;
                margin-top: $spacing;
                font-weight: $weight-light-standard;
                font-size: $font-size-xs;
                color: $color-gray-8;
            }

            .details {
                color: $color-tan;
                line-height: 1.6;
                margin: $spacing*.5 0 0;
                font-size: $font-size-xs;
                width: 100%;
            }

            .buttons {
                padding: $spacing*1.5 0 0;
            }

            @include min-width-sm {
                padding: $spacing * 3.5;
            }

            @include min-width-md {
                padding: $spacing * 3.5;

                .title {
                    font-size: $font-size-h1;
                    width: 100%;
                }

                .date {
                    font-size: $font-size-evenlarger;
                }

                .details {
                    margin-top: 2rem;
                    font-size: $font-size-large;
                    width: 100%;
                }

                .buttons {
                    margin-top: .5rem;
                }
            }
        }
    }

    @include min-width-md {
        flex: 1 1 50%;

        &--footer {
            top: -$spacing-footer-callout;
            margin-bottom: $spacing-footer-callout;
        }
    }
}
