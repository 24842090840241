.icon {
  display: inline-block;

  &.icon-menu {
    min-height: 0;
    @include menuDynaIcon($color-brand-primary, $borderSize: 2px, $width: 20px);

    @include min-width-md {
      @include menuDynaIcon($color-brand-primary, $borderSize: 3px, $width: 27px);      
    }
  }

  &.icon-xclose {
      @include xIcon($color-brand-primary, 20px);
  }

  &.icon-circle {
      border: 1px solid currentColor;
      border-radius: 100%;
      padding: .25em;
      width: 1.8em;
      height: 1.8em;
      text-align: center;
      vertical-align: middle;
  }
}

.accent-arrow {
  display: block;
  position: absolute;
  width: 32px;
  border-bottom: 2px solid $white;
  @include transition(border-color 0.5s ease);

  .icon {
    position: absolute;
    top: -13px;
    left: calc(100% - .55em);
    color: $white;
    font-size: 28px;
    line-height: 15px;
    
    @include transition(opacity .05s linear);
  }
}