.social-box {
    display: block;
    margin: 0 auto $spacing-med;
    text-align: center;

    .social-item {
        position: relative;
        display: inline-block;
    }

    .social-item-link {
        color: $color-brand-light;
        font-size: $font-size-small;
        margin-right: .5em;
    }

    @include min-width-sm {
        float: left;
        display: block;
        margin: 0;
        text-align: left;

        .social-item-link {
            font-size: $font-size-xs;
        }
    }

    @include min-width-xl {
        .social-item-link {
            font-size: $font-size-small;
        }
    }
}

.share-toggle {
    @include icontoggle;
}

.card-box {
    position: relative;
    text-align: center;
    font-weight: $weight-light-standard;

    .card-item {
        display: inline-block;
        position: relative;
        margin-left: 1em;

        &:first-child {
            margin-left: 0;
        }
    }

    .card-item-link {
        font-family: $font-family-semibold;
        font-weight: $weight-bold-standard;
        font-size: $font-size-xs;
        text-transform: uppercase;
        letter-spacing: .1em;
        @include linkColor($color-brand-light, $color-brand-secondary);

        @include min-width-xl {
            font-size: $font-size-small;
        }
    }
}

.card-box-float-rt {
    position: relative;
    text-align: center;
    font-weight: $weight-light-standard;

    @include min-width-sm {
        float: right;
    }
}

.card-box-center {
    position: relative;
    text-align: center;
    font-weight: $weight-light-standard;
    float: none;
    padding: px-to-rem(20px);
}

.pro-email {
    margin-bottom: $spacing * 1.5;
    font-size: $font-size-larger;
    font-weight: $weight-light-standard;
    font-family: $font-family-regular;
    @include linkColor($color-brand-secondary, $color-brand-secondary-light);
}

.pro-location {
    font-family: $font-family-regular;
    font-size: $font-size-larger;
    font-weight: $weight-light-standard;
    @include linkColor($color-brand-secondary, $color-brand-secondary-light);
}