//
// Scaffolding
// --------------------------------------------------


// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
*,
*:before,
*:after {
  @include box-sizing(border-box);
}


// Body reset
html,
body {
  height: 100%;
}

html {
  font-size: $font-size-base;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  font-feature-settings: "liga" 0;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links

a {
  @extend .interactive;
  text-decoration: none;
  @include linkColor();
}

a[x-apple-data-detectors] {
  color: inherit !important;
  text-decoration: none !important;
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  line-height: inherit !important;
}

a,
input,
button,
select,
textarea,
div.search-field {
  &:focus {
    outline: $focus;
  }
}


// Figures
//
// We reset this here because previously Normalize had no `figure` margins. This
// ensures we don't break anyone's use of the element.

.figure {
  margin: 0;
}

.p {
    margin-bottom: $spacing-paragraph;
    margin-top: 0;

    &:last-child {
        margin-bottom: 0;
    }
}
.ul,
.ol {
  margin-bottom: $spacing-paragraph;


  > .li {
    margin-bottom: $spacing-paragraph;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Images

img {
  vertical-align: middle;
  max-width: 100%;
}

// Horizontal rules

hr {
  margin-top:    $spacing-paragraph;
  margin-bottom: $spacing-paragraph;
  border: 0;
  border-top: 1px solid gray;
}

// iOS "clickable elements" fix for role="button"
[role="button"] {
  cursor: pointer;
}

button {
  border: none;
  background: none;
  @extend .interactive;
}

.blockquote {
  @include blockQuote();
}