$header-z: 1;

.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;

    @include transition(top .6s ease, background-color .2s ease);

    &:not(.menu-active) {
        .scrolling-down & {
            top: -100%;
        }
    }

    .scrolling-up & {
        background-color: $color-brand-dark;
        @include box-shadow($shadow);
    }
}

.header-section-left {
    position: absolute;
    max-width: calc(48% - #{$spacing*2});
    padding: 0 ($spacing * 2);
    top: 0;
    left: 0;
    z-index: $header-z + 3;
    margin-top: $spacing * 4;
    @include transition(top .4s ease .1s);

    .brand-secondary & {
        margin-top: $spacing * 3;

        @include min-width-md {
            margin-top: $spacing * 2;
        }
    }

    @include min-width-md {
        padding: $spacing;
        left: $spacing * 4;
        margin-top: $spacing * 3.5;
    }

    .scrolling-down & {
        display: none;
    }

    .scrolling-up & {
        margin-top: $spacing * 2;
    }

    &-breadcrumb {
        color: $color-brand-secondary;
        text-align: right;
        padding-right: $spacing;
        padding-top: px-to-rem(20px);
        vertical-align: bottom;
        text-transform: uppercase;
        text-decoration: none;
        transition: $transition-interactive;
        display: none;

        &:hover {
            color: $color-brand-secondary-light;
        }

        .scrolling-up & {
            display: block;
        }

        @media screen and (max-width: $screen-md) {
            padding-top: px-to-rem(14px);
        }

        @media screen and (max-width: $screen-xs) {
            .scrolling-up & {
                display: none;
            }
        }
    }
}

.header-section-right {
    position: relative;
    float: right;
    right: -($spacing*2);
    z-index: $header-z + 2;
    background-color: $color-brand-dark;
    .scrolling-up & { 
        margin-top: $spacing * 2;
    }
}

.menu-active {
    .header-section-left {
        a {
            cursor: default;
        }

        @media (max-width: #{ $screen-sm-min - 1 }) {
            display: none;
        }
    }
}

.header-section-full {
    position: absolute;
    top: -$spacing;
    right: 0;
    z-index: $header-z + 1;
    width: 100%;
}

.admin-banner {
    font-size: 1.5rem;
    justify-content: center;
    background-color: #800000;
    color: white;
    width: 100%;
    height: 5rem;
    position: fixed;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    text-align: center;
    z-index: 100;
    font-weight: 400;
}

.admin-banner + .header {
    position: relative;
    top: 5rem;
}

.header-section-centre {
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    font-size: 10px !important;
    line-height: 1;

    .scrolling-up & {
        background-color: white;
        color: black;
        padding: 0 $spacing;
        margin-left: -2rem;
        position: absolute;
        width: 100%;
        height: 2rem;

        @media screen and (max-width: $screen-md) {
            justify-content: left;            
        }
    }

    @media screen and (max-width: $screen-md) {
        justify-content: left;
    }
}

@include min-width-md {
    .header {
        &.introanim {

            .header-link-blocks {
                @include transition(transform .8s ease .5s);
            }

            .brand {
                @include transition(opacity .8s ease .5s);
            }

            &--before {
                .header-link-blocks {
                    @include translate(100%, 0);
                }

                .brand {
                    opacity: 0;
                }
            }
        }
    }
}
