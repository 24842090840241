.onenorthsearchdata--opened {
    overflow: hidden;
}

.onenorthsearchdata__panel * {
    font-size: initial;
    font-family: sans-serif !important;
    letter-spacing: initial;
}

.onenorthsearchdata__panel {
    /* need to perform a reset on all * underneath this */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    padding-right: 0;
    margin: 0;
    line-height: initial;
    font-size: initial;
    box-sizing: border-box;
    background: #FFF;
    background: rgba(255,255,255,0.9);
    color: #000;
    z-index: 100000;
}

.onenorthsearchdata__panel--displayed {
    display: block !important;
}

.onenorthsearchdata__panel--hidden {
    display: none !important;
}

.onenorthsearchdata__content {
    color: #444;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: flex-start;
    height: 100%;
    overflow-y: auto;

    pre {
        word-wrap: break-word; 
        white-space: pre-wrap;
    }
}

.onenorthsearchdata__content--loading::before {
    content: 'Loading...';
    display: block;
    text-align: center;
    font-size: 5em;
    font-weight: bold;
}

.onenorthsearchdata__closebutton {
    position: fixed;
    top: 0;
    right: 0;
    margin: 10px 10px 0 0;
    background: none;
    border: none;
    display: block;
    font-size: 2em;
}

.onenorthsearchdata__content > .onenorthsearchdata__block {
    display: inline-block;
    flex-grow: 1;
    flex-basis: 25%;
    min-width: 300px;
}

.onenorthsearchdata__content > onenorthsearchdata__block--full{
    text-align: center;
}

.onenorthsearchdata__content h4 {
    font-size: 1.5em;
    font-weight: bold;
    text-decoration: underline;
    margin-top: 1.5em;
}

.onenorthsearchdata__content h5 {
    font-size: 1.125em;
    font-weight: bold;
}

.onenorthsearchdata__content ul {
    list-style: none;
    padding-left: 12px;
}

.onenorthsearchdata__content ul li {
    margin-top: 8px;
}

.onenorthsearchdata__content ul li li {
    margin-top: 0px;
}

.onenorthsearchdata__content item::before {
    content: '['
}

.onenorthsearchdata__content item::after {
    content: '] '
}

.onenorthsearchdata__content small {
    font-size: .85em;
    color: #666;
}

.onenorthsearchdata__contactprogress {
    height: 16px;
    width: 100px;
    display: inline-block;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
    margin-right: 10px;
    overflow: hidden;
}

.onenorthsearchdata__contactprogress__inset {
    background-color: #dadada;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    padding-left: 3px;
    overflow: visible;
}

.onenorthsearchdata__contactprogress__inset--max {
    background-color: #8def82;
}