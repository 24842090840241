.overlay-dark-bg {
  position: absolute;
  overflow: hidden;
  @extend .background-full;
  background-color: rgba($color-brand-dark, .5);
  z-index: $z-bg-overlay;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before {
    position: absolute;
    display: block;
    width: 100%;
    height: 75%;
    content: " ";
    left: 0;
    bottom: 0;
    opacity: 1;
    z-index: $z-bg-overlay;
    @include gradient-vertical(rgba($color-brand-dark, 0), rgba($color-brand-dark, 1), 0, 100%);
  }
}

.blackout-image-overlay {
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $z-content;
    content: " ";
    background-color: $color-brand-dark;
    opacity: .5;
  }
}

.blackout-image-overlay-light {
  @extend .blackout-image-overlay;
  &:before {
    opacity: .3;
  }
}

.body-overlay {
  display: block;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 0;
  width: 0;
  opacity: 0;
  background-color: rgba($color-brand-light, .5);
  @include transition(opacity .3s ease);

  &.active {
    z-index: $z-overlay;
    height: 100%;
    width: 100%;
    opacity: 1;
  }
}
