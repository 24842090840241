@use "sass:math";

.hero-columns {
    position: relative;
    width: 100%;
    display: block;
    justify-content: center;
    align-items: stretch;

    @include calcMinHeight(.3);

    &__column {
        position: relative;

        &--primary {
            width: 100%;
            z-index: $z-content;

            @include calcMinHeight(.3);
        }

        &--secondary {
            z-index: $z-reset;
            height: 400px;

            @include calcMinHeight(.3);
        }

        &--pushdown {
            margin-top: $spacing * 6;
            margin-bottom: math.div(-$spacing-hero-push-sm, 2);

            @include min-width-md {
                margin-top: $spacing;
            }
        }
    }

    @include min-width-md {
        display: flex;
        flex-flow: row nowrap;
        /*height: auto;*/
        padding-bottom: $spacing;

        &--pushdown {
            padding-bottom: $spacing * 5.3;
        }

        &__column {
            position: relative;
            width: 50%;

            &--full-width {
                width: 100%;
            }

            &--primary {

            }

            &--secondary {
                /*height: auto;*/
                margin-top: $spacing;
                margin-bottom: -($spacing);
            }

            &--pushdown {
                margin-top: $spacing * 8.3125;
                margin-bottom: -($spacing * 4.875);
            }
        }

        &--homepage {
            min-height: calc(100vh - #{$spacing*2});

            .hero-columns__column {
                min-height: 100%;

                &--primary {
                    height: inherit;

                    .hero-flex-content {
                        min-height: calc(100vh - 2rem);
                        height: inherit;
                    }
                }

                &--secondary {
                    height: auto;
                    margin-bottom: 2rem;
                    min-height: calc(100% - 2rem)
                }
            }

            .hero-image {
                margin-bottom: 2rem;
                min-height: calc(100% - 2rem);
                position: absolute;
                width: 200%;
                background-position-x: left;
                overflow: visible;
            }
        }
    }

    .introanim & {
        @include min-width-md {
            &__column {
                &--primary {
                    position: relative;
                    right: 0;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}

.primary-no-img {
    width: 100%;
    z-index: $z-reset;

    @media screen and (max-width: $screen-sm) {
        margin-bottom: math.div(-$spacing-hero-push-sm, 2);
    }
}

.secondary-no-img {
    width: 0;
    height: 0;
    z-index: $z-content;
}

.hero-section {
    &.introanim {
        .hero {
            .hero-image {
                background-color: #FDFDFD;
                @include transition(opacity .8s ease .5s);
                /*height: auto;*/
            }

            .hero-flex-content {
                @include transition(top .8s ease 2.5s, opacity .8s ease 2.5s);
                height: inherit;
                align-content: center;
                justify-content: center;
            }

            .hero-jumbo-title {
                @include transition(top .8s ease 2.5s, opacity .8s ease 2.5s);
            }

            .hero-nav {
                @include transition(transform .8s ease 2.5s, opacity .9s ease 2.5s);
            }
        }

        .link-block-scroll {
            @include transition(transform .8s ease 2.25s);
        }

        &--before {
            .hero {
                @include min-width-md {
                    .hero-flex-content {
                        top: -10%;
                        opacity: 0;
                    }

                    .hero-image {
                        opacity: 0;
                    }
                }

                .hero-jumbo-title {
                    top: -10%;
                    opacity: 0;
                }

                .hero-nav {
                    opacity: 0;
                    @include translate(15%, 0);
                }
            }

            .link-block-scroll {
                @include translate(-180%, 0);
            }
        }
    }
}
