.insight-card {
  margin-bottom: 25px;

  @include min-width-md {
    padding-right: 25px;

    &:nth-child(3n) {
      margin-right: -25px;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  &__image {
    padding-top: 66%;
    background-size: cover;
  }

  &__content {
    position: relative;
    padding-top: 20px;
    flex: 1;
    background-color: $color-brand-light;
  }

  &__tag,
  &__title,
  &__blurb {
    @include transition(color .6s ease);
  }

  &__tag {
    color: $color-brand-green;
    font-family: $font-family-semibold;
    font-size: $font-size-med;
    text-transform: uppercase;
    margin-bottom: 6px;
    font-weight: $weight-bold-standard;
    letter-spacing: 1px;
  }

  &__title {
    color: $color-brand-dark;
    font-size: $font-size-evenlarger;
    line-height: $font-size-xl;
    margin-bottom: 6px;

    @include min-width-sm {
      font-size: $font-size-xxl;
      line-height: $font-size-xxl-up;
    }

    &:not(.hover-static):hover {
      color: $color-brand-green;
    }
  }

  &__blurb {
    color: $color-brand-dark;
    font-size: $font-size-large;
  }

  .accent-arrow-solo {
    top: 30px;
    border-color: $color-brand-green;

    .icon {
      color: $color-brand-green;
      opacity: 0;
    }

    @include min-width-sm {
      top: 35px;
    }
  }

  &:not(.hover-static):hover {

    .accent-arrow-solo {
      width: 20%;
      border-color: $color-brand-light;

      .icon {
        color: $color-brand-light;
        opacity: 1;
      }
    }
  }
}