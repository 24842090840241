@use "sass:math";

.sidefixer {
    $sidefixer__width: 474px;

    position: absolute;
    right: 0;
    top: auto;
    z-index: $z-site-shell-top - 1;
    display: block;
    height: auto;

    &--placeholder {
        height: 0px;
        width: 0px;
        overflow: hidden;
        z-index: 0;
        visibility: hidden;
    }

    &--active {
        z-index: $z-site-shell-top - 1;
    }

    &__inner {
        position: relative;
        overflow: visible;
        margin-left: 100px;
    }

    &__trigger {
        position: absolute;
        top: 0%;
        display: inline-block;
        padding: math.div($spacing, 2) $spacing;
        @include transition(top .6s ease, margin-top .6s ease);
        background-color: $color-brand-secondary;
        color: $color-brand-primary;
        letter-spacing: .3em;
        transform: rotateZ(-90deg) translateX(-100%) translateY(-100%);
        transform-origin: 0% 0%;
        overflow-y: hidden;

        &--reachedlimit {
            top: 100%;
            transform: rotateZ(-90deg) translateX(0%) translateY(-100%);
        }
    }

    &__trigger-title {
        text-transform: uppercase;
        white-space: nowrap;
        font-size: $font-size-med;
        font-weight: $weight-bold-standard;
    }

    &__trigger-open-text {
        display: inline-block;

        .sidefixer--active & {
            display: none;
        }
    }

    &__trigger-close-text {
        display: none;

        .sidefixer--active & {
            display: inline-block;
        }
    }

    &__content {
        position: relative;
        overflow: hidden;
        width: 0;
        background-color: $color-brand-light;
        @include transition(width .6s ease);
        @include box-shadow(0 math.div($spacing, 4) $spacing rgba(0, 0, 0, .15));

        &--active {
            width: calc(100vw - 100px);
        }
    }

    &__content-inner {
        width: calc(100vw - 100px);
    }

    @include min-width-sm() {
        height: calc(100vh - #{$spacing-large});

        &__inner {
            height: 100%;
            margin-left: 116px;

            .sidefixer--active & {
                margin-left: 160px;
            }
        }

        &__trigger {
            padding: $spacing $spacing*2;
        }

        &__trigger-title {
            font-size: $font-size-h4;
        }

        &__content {
            height: 100%;
            width: 0;

            &--active {
                width: $sidefixer__width;
            }
        }

        &__content-inner {
            height: 100%;
            width: $sidefixer__width;
        }
    }
}
