.modal-mask {
    opacity: 0;
    z-index: -1;
    transition: opacity .3s ease;
    -webkit-transform: scale(1);
    transform: scale(1);

    &.active {
        opacity: 1;
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: table;
        transition: opacity .3s ease;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 75%;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .7s ease; // font-family: $font-family-open-sans;
    @include min-width-xs {
        width: 50%;
    }
}

.modal-header h4 {
    margin-top: 0;
}

.modal-body {
    margin: 20px 0;
}

.modal-footer {
    $spacing--disclaimer: $spacing * 1.5;
    padding-bottom: 20px;
    text-align: center;
    @include min-width-md {
        text-align: start;
    }
    .btn {
        @include min-width-xs {
            margin-right: 10px;
        }
        &:not(:first-child) {
            margin-top: $spacing--disclaimer;
            @include min-width-sm {
                margin-top: 0;
            }
        }
    }
}

.modal-default-button {
    float: right;
    &.btn-left {
        padding-right: 10px;
    }
}

.modal-lock {
    overflow: hidden;
}