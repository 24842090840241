.page-background {
  background-image: url(/-/media/images/background/sitebgtop.png), url(/-/media/images/background/sitebgbottom.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 0 0, top 1200px right;
  
  &--push-down {
      background-position: 0 #{$spacing * 4}, top 1200px right;
  }
}

.bg-color-primary {
  background-color: $color-brand-dark;
}
.bg-article {
  background-color: $color-brand-ecru;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3); 
    z-index: -1;
    // background: url("/assets/Main/site/images/article-hero.jpg");
    // background-size: cover;
    // background-repeat: no-repeat;
  }
}
.bg-gradient {
  background: $green-gradient;

  .hero-flex-content,
  .hero-flex-content--short {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.3); 
    background: url("/assets/Main/site/images/hero-swoosh.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
}