.link-columns {
  position: relative;
  min-height: 325px;

  @include min-width-md {
    height: 725px;
  }
}

.link-column {
  position: relative;
  z-index: $z-content;
  height: 100%;
  border-top: 1px solid $color-gray-9;
  @include transition(opacity .6s ease);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &:first-child {
    border-top: 0;
  }

  .buttons {
    @include transition($transition-interactive);
    padding-bottom: 0;
  }

  @include min-width-md {
    position: static;
    padding: $spacing-large*2 $spacing-med;
    border-top: 0;
    border-left: 1px solid $color-gray-9;
    background-image: none !important;

    &:before {
      content: none;
    }

    &:first-child {
      border-left: 0;
    }
    &,
    .link-columns:hover & {
      opacity: 0.5;
      .buttons {
        opacity: 0;
      }
    }

    &:first-child,
    .link-columns:hover &:hover {
      opacity: 1;
      + .link-column-image {
        opacity: 1;
      }

      .link-column-title {
        color: $color-brand-secondary;
      }

      .buttons {
        opacity: 1;
      }
    }
  }

  .column-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    height: 100%;
    padding: $spacing-large 2em;
    z-index: 1;

    > * {
      display: block;
      flex: 1 1 100%;
    }

    @include min-width-md {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      background: 0;
      padding-top: 0;
      padding-bottom: 0;

      > * {
        display: block;
        flex: 0 1 auto;
        width: 100%;
      }

      .buttons {
        align-self: flex-end;
      }
    }
  }
}

.link-column-title {
  width: 100%;
  margin-bottom: $spacing-large;
  color: $color-brand-secondary;
  @extend .title-style;
  letter-spacing: .15em;

  font-size: $font-size-evenlarger;

  @include min-width-md {
    width: 60%;
    margin-bottom: 0;
    color: $color-brand-light;
    font-size: $font-size-xxl;
  }

  @include min-width-lg {
    font-size: $font-size-h3;
  }


}

.link-column-image {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  min-width: 100%;
  height: auto;
  z-index: $z-bg-overlay;
  opacity: 0;
  text-align: center;
  flex-wrap: nowrap;
  @include transition(opacity .6s ease);

  @extend .blackout-image-overlay;

  > img {
    max-width: none;
    min-width: 100%;
    min-height: 100%;   
    @include transition(opacity .6s ease);
  }

  @include min-width-md {
    height: 100%;
    width: 100%;
    opacity: 0;
    
    > img {
      min-width: 100%;
      min-height: 0;
    }
  }


}
